import { Component } from 'react';
import { Provider } from 'react-redux';
import { Redirect, Route, BrowserRouter as Router, Switch, useHistory } from 'react-router-dom';
import {
  BankNoteTypes,
  PaymentTypesTreasury,
  PrioritizeBillsToPay,
  TypesCostsTc,
} from '../AdministrationModule/';
import CompanyParams from '../AdministrationModule/Receivable/CompanyParams/CompanyParams';
import CreateCompanyParams from '../AdministrationModule/Receivable/CompanyParams/Create/CreateCompanyParams';
import CompanyParamDetail from '../AdministrationModule/Receivable/CompanyParams/Detail/Detail';
import InterestPayment from '../AdministrationModule/Receivable/InterestPayment/InterestPayment';
import PaymentTypes from '../AdministrationModule/Receivable/PaymentTypes/PaymentTypes';
import PortfolioDeterioration from '../AdministrationModule/Receivable/PortfolioDeterioration/PortfolioDeterioration';
import PortfolioProvision from '../AdministrationModule/Receivable/PortfolioProvision/PortfolioProvision';
import CreateRips from '../AdministrationModule/Receivable/Rips/Create/CreateRips';
import RipsDetail from '../AdministrationModule/Receivable/Rips/Details/RipsDetail';
import Rips from '../AdministrationModule/Receivable/Rips/Rips';
import RipsData from '../AdministrationModule/Receivable/RipsData/RipsData';
import Franchises from '../AdmissionModule/Admissions/Franchises';

// import { RedirectionPage } from '../RedirectionPage';
import DoctorsDiaries from '../TelemedicineModule/Schedule/DoctorsDiaries';
import ScheduleDetail from '../TelemedicineModule/Schedule/ScheduleDetail';
import TelemedicineSetup from '../TelemedicineModule/Setting/TelemedicineSetup';
import ConsultAppointments from '../TelemedicineModule/VirtualDating/ConsultAppointments';
import DetailAppointment from '../TelemedicineModule/VirtualDating/DetailAppointment';
import PayHistory from '../TelemedicineModule/VirtualDating/PayHistory';
import CitationReport from '../TelemedicineModule/report/CitationReport';
import { ConfigMovesNew } from '../TreasuryModule/ConfiMovement/ConfigMovesNew';
import { Layout404 } from '../components/404';
import AccountSelect from '../components/AccountSelect/AccountSelect';
import { AccountingSetup } from '../components/AccountingSetup/AccountingSetup';
import { NewProvision } from '../components/AccountingSetup/NewProvision';
import { NewReceivAge } from '../components/AccountingSetup/NewReceivAge';
import { ReceivableAges } from '../components/AccountingSetup/ReceivableAges';
import CategorySelect from '../components/CategorySelect/CategorySelect';
import CollectDetails from '../components/CollectLetter/CollectDetails';
import CollectionCreate from '../components/CollectLetter/CollectionCreate';
import CollectionLetter from '../components/CollectLetter/CollectionLetter';
import GestionPermisos from '../components/GestionUsuarios/GestionPermisos';
import GestionRoles from '../components/GestionUsuarios/GestionRoles';
import Holidays from '../components/Holidays/Holidays';
import Home from '../components/Home/Home';
import { ArticlesInStorage } from '../components/Inventory/ArticlesInStorage';
import { Donation } from '../components/Inventory/Donation';
import { EntryAdjusment } from '../components/Inventory/EntryAdjusment';
import { EntryByConsign } from '../components/Inventory/EntryByConsign';
import { EntryByDonation } from '../components/Inventory/EntryByDonation';
import { EntryByPurchase } from '../components/Inventory/EntryByPurchase';
import { InventoryInStorage } from '../components/Inventory/InventoryInStorage';
import { InventoryTable } from '../components/Inventory/InventoryTable';
import { OutputAdjusment } from '../components/Inventory/OutputAdjusment';
import { OutputByConsum } from '../components/Inventory/OutputByConsum';
import Legalization from '../components/Inventory/Reports/Legalization';
import { ReportEntryByConsig } from '../components/Inventory/Reports/ReportEntryByConsig';
import { ReportEntryByDonation } from '../components/Inventory/Reports/ReportEntryByDonation';
import { ReportEntryByPurchase } from '../components/Inventory/Reports/ReportEntryByPurchase';
import { ReportInventoryMovement } from '../components/Inventory/Reports/ReportInventoryMovement';
import { TransferWarehouse } from '../components/Inventory/TransferWarehouse';
import Login from '../components/Login/Login';
import ForgotPassword from '../components/Login/forgotPassword';
import { Logout } from '../components/Logout/Logout';
import { Accounting } from '../components/ManagementModule/Accounting';
import { Billing } from '../components/ManagementModule/Billing';
import { Dashboard } from '../components/ManagementModule/Dashboard';
import { PayrollReport } from '../components/ManagementModule/PayrollReport';
import { Purchases } from '../components/ManagementModule/Purchases';
import { TreasuryReport } from '../components/ManagementModule/TreasuryReport';
import { Wallet } from '../components/ManagementModule/Wallet';
import NewRol from '../components/Payroll/Settings/NewRol';
import { PermissionManagement } from '../components/Payroll/Settings/PermissionManagement';
import RolDetail from '../components/Payroll/Settings/RolDetail';
import RolSettings from '../components/Payroll/Settings/RolSettings';
import { NoteQuery } from '../components/ProvidersTable/NoteQuery';
import { NoteRecords } from '../components/ProvidersTable/NoteRecords';
import PurchaseOrder from '../components/PurchaseOrder/purchaseOrder';
import RequisitionForm from '../components/Requisitions/RequisitionForm';
import RequisitionsPending from '../components/Requisitions/RequisitionsPending';
import SalidaConsumo from '../components/SalidaConsumo/SalidaConsumo';
import TableAccAccounts from '../components/TableAccAccounts/TableAccAccounts';
import TableAccountingPeriods from '../components/TableAccountigPeriods/TableAccountingPeriods';
import AuthAgreement from '../components/TableAgreement/AuthAgreement';
import TableAgreement from '../components/TableAgreement/TableAgreement';
import ApprovePurchaseOrder from '../components/TableApproveAuthorizations/ApprovePurchaseOrder';
import DetailPurchaseOrder from '../components/TableApproveAuthorizations/DetailPurchaseOrder';
import TableArticles from '../components/TableArticles/TableArticles';
import TableAuthorizeRequisitions from '../components/TableAuthorizeRequisitions/TableAuthorizeRequisitions';
import TableCieten from '../components/TableCieTen/TableCieten';
import TableClass from '../components/TableClassification/TableClass';
import TableCollections from '../components/TableCollections/TableCollections';
import TableConsumptionCenter from '../components/TableConsumptionCenter/TableConsumptionCenter';
import TableCorporateClients from '../components/TableCorporateClients/TableCorporateClients';
import TableCostCenter from '../components/TableCostCenter/TableCostCenter';
import TableCuentas from '../components/TableCuentas/TableCuentas';
import TableCups from '../components/TableCups/TableCups';
import TableDeductionsAccrualsNew from '../components/TableDeductionsAccruals/TableDeductionsAccrualsNew';
import TableFunctional from '../components/TableFunctionalUnit/TableFunctional';
import CurrentAuction from '../components/TableIndividualAuction/CurrentAuction';
import HistorialSubastas from '../components/TableIndividualAuction/HistorialSubastas';
import TableIndividualAuction from '../components/TableIndividualAuction/TableIndividualAuction';
import TablePendingAuctions from '../components/TableIndividualAuction/TablePendingAuctions';
import TableInventoryFamily from '../components/TableInventoryFamily/TableInventoryFamily';
import TableMeasures from '../components/TableMeasures/TableMeasures';
import TablePopulation from '../components/TablePopulation/TablePopulation';
import FormLegalPerson from '../components/TableProviders/FormLegalPerson';
import FormNaturalPerson from '../components/TableProviders/FormNaturalPerson';
import ProvidersSelectorForm from '../components/TableProviders/ProvidersSelectorForm';
import TableProviders from '../components/TableProviders/TableProviders';
import TablePuc from '../components/TablePuc/TablePuc';
import TableServices from '../components/TableServices/TableServices';
import TableSites from '../components/TableSites/TableSites';
import TableSpecialities from '../components/TableSpecialities/TableSpecialities';
import StatusPurchaseOrder from '../components/TableStatusPurchaseOrder/StatusPurchaseOrder';
import TableStorage from '../components/TableStorage/TableStorage';
import TableUsers from '../components/TableUsers/TableUsers';
import UsersCreation from '../components/TableUsers/UsersCreation';
import AuthEntryWarehouse from '../components/TableWarehouseEntry/AuthEntryWarehouse';
import TableWarehouseEntry from '../components/TableWarehouseEntry/TableWarehouseEntry';
import DetailPurchaseOrderinWarehouse from '../components/Warehouse/DetailPurchaseOrder';
import RegisterEntryByWarehouse from '../components/Warehouse/RegisterEntryByWarehouse';
import PatientReceived from '../components/patientReceived/patientReceived';
import { ContracTypes } from '../components/payrollTm/ContracTypes';
import UserProfile from '../components/userProfile/UserProfile';
import store from '../store';
import FullLayout from './FullLayout';
import PrivateRoute from './PrivateRoute';
import Validate from './Validate';

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Router>
        <Provider store={store}>
          <Switch>
            <Route exact path='/'>
              <Redirect to='/login' />
            </Route>

            <Route exact path='/login/olvidarcontrasena' history={useHistory}>
              <ForgotPassword />
            </Route>

            {/* /* ------------------------------- BLANK PAGE ------------------------------- */}
            {/* <Route exact path='/redirect/:id/:token/:clhId/:appId'>
              <RedirectionPage />
            </Route> */}
            {/* /* ------------------------------------ - ----------------------------------- */}
            <Route exact path='/login' component={Login} history={useHistory} />
            <Route exact path='/receivedAction/' component={PatientReceived} history={useHistory} />

            <Route exact path='/validate' history={useHistory}>
              <Validate />
            </Route>
            <Route exact path='/accountselect' history={useHistory}>
              <AccountSelect />
            </Route>
            <PrivateRoute>
              <Switch>
                <Route exact path='/categoryselect' history={useHistory}>
                  <CategorySelect />
                </Route>

                <Route exact path='/:category/inicio' history={useHistory}>
                  <FullLayout>
                    <Home />
                  </FullLayout>
                </Route>

                <Route exact path='/administracion/servicios'>
                  <FullLayout>
                    <TableServices />
                  </FullLayout>
                </Route>

                <Route exact path='/administracion/franquicias'>
                  <FullLayout>
                    <Franchises />
                  </FullLayout>
                </Route>

                <Route path='/administracion/diasFestivos'>
                  <FullLayout>
                    <Holidays />
                  </FullLayout>
                </Route>

                <Route path='/administracion/sedes'>
                  <FullLayout>
                    <TableSites />
                  </FullLayout>
                </Route>
                <Route path='/administracion/DevengadosDeducciones'>
                  <FullLayout>
                    <TableDeductionsAccrualsNew />
                  </FullLayout>
                </Route>

                <Route path='/administracion/puc'>
                  <FullLayout>
                    <TablePuc />
                  </FullLayout>
                </Route>

                <Route path='/administracion/functional'>
                  <FullLayout>
                    <TableFunctional />
                  </FullLayout>
                </Route>

                <Route path='/administracion/storage'>
                  <FullLayout>
                    <TableStorage />
                  </FullLayout>
                </Route>

                <Route path='/administracion/costcenter'>
                  <FullLayout>
                    <TableCostCenter />
                  </FullLayout>
                </Route>

                <Route path='/administracion/collections'>
                  <FullLayout>
                    <TableCollections />
                  </FullLayout>
                </Route>

                <Route path='/administracion/impresiones-diagnosticas'>
                  <FullLayout>
                    <TableCieten />
                  </FullLayout>
                </Route>

                <Route path='/administracion/unidades-medida'>
                  <FullLayout>
                    <TableMeasures />
                  </FullLayout>
                </Route>

                <Route path='/administracion/articulos-activos'>
                  <FullLayout>
                    <TableArticles />
                  </FullLayout>
                </Route>

                <Route path='/administracion/familia-inventario'>
                  <FullLayout>
                    <TableInventoryFamily />
                  </FullLayout>
                </Route>
                <Route path='/administracion/centro-de-consumo'>
                  <FullLayout>
                    <TableConsumptionCenter />
                  </FullLayout>
                </Route>

                <Route path='/administracion/cups'>
                  <FullLayout>
                    <TableCups />
                  </FullLayout>
                </Route>

                <Route exact path='/administracion/proveedores/selector'>
                  <FullLayout>
                    <ProvidersSelectorForm />
                  </FullLayout>
                </Route>

                <Route exact path='/administracion/proveedores'>
                  <FullLayout>
                    <TableProviders />
                  </FullLayout>
                </Route>

                <Route exact path='/administracion/proveedores/selector/persona-juridica'>
                  <FullLayout>
                    <FormLegalPerson store={store} />
                  </FullLayout>
                </Route>

                <Route exact path='/administracion/proveedores/selector/persona-natural'>
                  <FullLayout>
                    <FormNaturalPerson />
                  </FullLayout>
                </Route>

                <Route path='/administracion/corporateClients'>
                  <FullLayout>
                    <TableCorporateClients />
                  </FullLayout>
                </Route>

                <Route path='/administracion/accountingPeriods'>
                  <FullLayout>
                    <TableAccountingPeriods />
                  </FullLayout>
                </Route>

                <Route
                  path='/compras/subastaindividual'
                  render={props => (
                    <FullLayout>
                      <TableIndividualAuction history={props.history} location={props.location} />
                    </FullLayout>
                  )}
                />

                <Route path='/compras/subastapendiente'>
                  <FullLayout>
                    <TablePendingAuctions />
                  </FullLayout>
                </Route>

                <Route path='/compras/subastasvigentes'>
                  <FullLayout>
                    <CurrentAuction />
                  </FullLayout>
                </Route>

                <Route path='/compras/subastashistorial'>
                  <FullLayout>
                    <HistorialSubastas />
                  </FullLayout>
                </Route>

                <Route path='/compras/requisicionnueva'>
                  <FullLayout>
                    <RequisitionForm />
                  </FullLayout>
                </Route>

                <Route path='/compras/requisicionpendiente'>
                  <FullLayout>
                    <RequisitionsPending />
                  </FullLayout>
                </Route>

                <Route path='/compras/salidaconsumo'>
                  <FullLayout>
                    <SalidaConsumo />
                  </FullLayout>
                </Route>

                <Route path='/compras/cuentascontables'>
                  <FullLayout>
                    <TableAccAccounts />
                  </FullLayout>
                </Route>
                <Route path='/compras/authorizeRequisitions'>
                  <FullLayout>
                    <TableAuthorizeRequisitions />
                  </FullLayout>
                </Route>
                <Route path='/compras/convenios'>
                  <FullLayout>
                    <TableAgreement />
                  </FullLayout>
                </Route>

                <Route path='/compras/authConv'>
                  <FullLayout>
                    <AuthAgreement />
                  </FullLayout>
                </Route>

                <Route path='/configuracion/miperfil'>
                  <FullLayout>
                    <UserProfile />
                  </FullLayout>
                </Route>

                <Route path='/administracion/cuentas'>
                  <FullLayout>
                    <TableCuentas />
                  </FullLayout>
                </Route>
                <Route path='/administracion/usuarios'>
                  <FullLayout>
                    <TableUsers />
                  </FullLayout>
                </Route>

                <Route path='/administracion/gestionpermisos'>
                  <FullLayout>
                    <GestionPermisos />
                  </FullLayout>
                </Route>
                <Route path='/administracion/gestionroles'>
                  <FullLayout>
                    <GestionRoles />
                  </FullLayout>
                </Route>
                <Route path='/administracion/configuracionContable'>
                  <FullLayout>
                    <AccountingSetup />
                  </FullLayout>
                </Route>

                <Route path='/DetalleAOrdenCompra'>
                  <FullLayout>
                    <DetailPurchaseOrder />
                  </FullLayout>
                </Route>

                <Route path='/especialidades'>
                  <FullLayout>
                    <TableSpecialities />
                  </FullLayout>
                </Route>

                <Route path='/clasificacion'>
                  <FullLayout>
                    <TableClass />
                  </FullLayout>
                </Route>
                <Route path='/compras/registrarEntradaAlmacen'>
                  <FullLayout>
                    <RegisterEntryByWarehouse />
                  </FullLayout>
                </Route>
                <Route path='/compras/orden-de-compra'>
                  <FullLayout>
                    <PurchaseOrder />
                  </FullLayout>
                </Route>
                <Route path='/detalleOrdenDeCompra'>
                  <FullLayout>
                    <DetailPurchaseOrderinWarehouse />
                  </FullLayout>
                </Route>

                <Route path='/compras/AOrdenCompra'>
                  <FullLayout>
                    <ApprovePurchaseOrder />
                  </FullLayout>
                </Route>
                <Route exact path='/compras/autorizar-entradas-almacen'>
                  <FullLayout>
                    <AuthEntryWarehouse />
                  </FullLayout>
                </Route>
                {/* /* ---------------------------------------------------------------------- */
                /*                           INVENTORY ROUTES --KMR                           */
                /* -------------------------------------------------------------------------- */}
                <Route path='/compras/registrarMovimiento'>
                  <FullLayout>
                    <InventoryTable />
                  </FullLayout>
                </Route>
                <Route path='/compras/entradaPorCompra'>
                  <FullLayout>
                    <EntryByPurchase />
                  </FullLayout>
                </Route>
                <Route path='/compras/entradaPorConsignacion'>
                  <FullLayout>
                    <EntryByConsign />
                  </FullLayout>
                </Route>
                <Route path='/compras/entradaPorDonacion'>
                  <FullLayout>
                    <EntryByDonation />
                  </FullLayout>
                </Route>
                <Route path='/compras/salidaPorConsumo'>
                  <FullLayout>
                    <OutputByConsum />
                  </FullLayout>
                </Route>
                <Route path='/compras/informeEntradaPorCompra'>
                  <FullLayout>
                    <ReportEntryByPurchase />
                  </FullLayout>
                </Route>
                <Route exact path='/compras/informeEntradaPorConsignacion'>
                  <FullLayout>
                    <ReportEntryByConsig />
                  </FullLayout>
                </Route>
                <Route path='/compras/informeEntradaPorDonacion'>
                  <FullLayout>
                    <ReportEntryByDonation />
                  </FullLayout>
                </Route>
                <Route path='/compras/ajusteDeEntrada'>
                  <FullLayout>
                    <EntryAdjusment />
                  </FullLayout>
                </Route>
                <Route path='/compras/ajusteDeSalida'>
                  <FullLayout>
                    <OutputAdjusment />
                  </FullLayout>
                </Route>
                <Route path='/compras/articulosEnAlmacen'>
                  <FullLayout>
                    <ArticlesInStorage />
                  </FullLayout>
                </Route>
                <Route path='/compras/trasladoDeAlmacen'>
                  <FullLayout>
                    <TransferWarehouse />
                  </FullLayout>
                </Route>
                <Route path='/compras/Donacion'>
                  <FullLayout>
                    <Donation />
                  </FullLayout>
                </Route>
                <Route path='/compras/informeMovimientosInventario'>
                  <FullLayout>
                    <ReportInventoryMovement />
                  </FullLayout>
                </Route>
                <Route path='/compras/inventarioEnAlmacen'>
                  <FullLayout>
                    <InventoryInStorage />
                  </FullLayout>
                </Route>
                {/* /* ------------------------- PROVIDERS ROUTES -- KMR ------------------------ */}

                <Route path='/compras/registrosDeNotas'>
                  <FullLayout>
                    <NoteRecords />
                  </FullLayout>
                </Route>
                <Route path='/compras/consultaDeNotas'>
                  <FullLayout>
                    <NoteQuery />
                  </FullLayout>
                </Route>

                <Route
                  exact
                  path='/compras/informeEntradaPorConsignacion/:id'
                  render={props => (
                    <FullLayout>
                      <Legalization {...props} />
                    </FullLayout>
                  )}
                />

                {/* /* ------------------------------------ x ----------------------------------- */}

                <Route path='/compras/estado-orden-de-compra/'>
                  <FullLayout>
                    <StatusPurchaseOrder />
                  </FullLayout>
                </Route>

                <Route exact path='/compras/historial-entradas-almacen'>
                  <FullLayout>
                    <TableWarehouseEntry />
                  </FullLayout>
                </Route>

                <Route path='/crear_usuarios'>
                  <FullLayout>
                    <UsersCreation />
                  </FullLayout>
                </Route>

                <Route exact path='/administracion/tiposDeContratos'>
                  <FullLayout>
                    <ContracTypes />
                  </FullLayout>
                </Route>
                {/* administration receivable */}
                <Route exact path='/administracion/TMDeCartera/CartasCobro'>
                  <FullLayout>
                    <CollectionLetter />
                  </FullLayout>
                </Route>
                <Route exact path='/administracion/TMDeCartera/Parametrizacion'>
                  <FullLayout>
                    <CompanyParams />
                  </FullLayout>
                </Route>
                <Route exact path='/administracion/TMDeCartera/interes-mora'>
                  <FullLayout>
                    <InterestPayment />
                  </FullLayout>
                </Route>
                <Route exact path='/administracion/TMDeCartera/deterioro-cartera'>
                  <FullLayout>
                    <PortfolioDeterioration />
                  </FullLayout>
                </Route>
                <Route exact path='/administracion/TMDeCartera/provision-cartera'>
                  <FullLayout>
                    <PortfolioProvision />
                  </FullLayout>
                </Route>
                <Route exact path='/administracion/TMDeCartera/rips'>
                  <FullLayout>
                    <Rips />
                  </FullLayout>
                </Route>
                <Route exact path='/administracion/TMDeCartera/rips/detalle/:id'>
                  <FullLayout>
                    <RipsDetail />
                  </FullLayout>
                </Route>
                <Route exact path='/administracion/TMDeCartera/tipos-cobro'>
                  <FullLayout>
                    <PaymentTypes />
                  </FullLayout>
                </Route>
                <Route exact path='/administracion/TMDeCartera/rips/crear'>
                  <FullLayout>
                    <CreateRips />
                  </FullLayout>
                </Route>
                <Route exact path='/administracion/TMDeCartera/datos-rips'>
                  <FullLayout>
                    <RipsData />
                  </FullLayout>
                </Route>
                <Route exact path='/administracion/TMDeCartera/Parametrizacion/Detalle/:id'>
                  <FullLayout>
                    <CompanyParamDetail />
                  </FullLayout>
                </Route>
                <Route exact path='/administracion/TMDeCartera/Parametrizacion/Crear'>
                  <FullLayout>
                    <CreateCompanyParams />
                  </FullLayout>
                </Route>
                <Route exact path='/administracion/TMDeCartera/CrearCarta'>
                  <FullLayout>
                    <CollectionCreate />
                  </FullLayout>
                </Route>
                <Route exact path='/administracion/TMDeCartera/DetalleCarta/:id'>
                  <FullLayout>
                    <CollectDetails />
                  </FullLayout>
                </Route>

                {/* ---------------------------CONFIGURACIÓN------------------------------ */}

                <Route exact path='/administracion/GestionDePermisos'>
                  <FullLayout>
                    <PermissionManagement />
                  </FullLayout>
                </Route>
                <Route exact path='/administracion/GestionDePermisos/NuevoRol'>
                  <FullLayout>
                    <NewRol />
                  </FullLayout>
                </Route>
                <Route exact path='/administracion/GestionDePermisos/NuevoRol/configuracion'>
                  <FullLayout>
                    <RolSettings />
                  </FullLayout>
                </Route>
                <Route exact path='/administracion/GestionDePermisos/Detalle/'>
                  <FullLayout>
                    <RolDetail />
                  </FullLayout>
                </Route>
                {/* /* -------------------------------------------------------------------------- */
                /*                              MANAGEMENT ROUTES                             */
                /* -------------------------------------------------------------------------- */}
                <Route exact path='/gerencia/dashboard/indicadoresGlobales'>
                  <FullLayout>
                    <Dashboard />
                  </FullLayout>
                </Route>
                <Route exact path='/gerencia/indicadores/compras'>
                  <FullLayout>
                    <Purchases />
                  </FullLayout>
                </Route>
                <Route exact path='/gerencia/indicadores/cartera'>
                  <FullLayout>
                    <Wallet />
                  </FullLayout>
                </Route>
                <Route exact path='/gerencia/indicadores/contabilidad'>
                  <FullLayout>
                    <Accounting />
                  </FullLayout>
                </Route>
                <Route exact path='/gerencia/indicadores/facturacion'>
                  <FullLayout>
                    <Billing />
                  </FullLayout>
                </Route>
                <Route exact path='/gerencia/indicadores/nomina'>
                  <FullLayout>
                    <PayrollReport />
                  </FullLayout>
                </Route>
                <Route exact path='/gerencia/indicadores/tesoreria'>
                  <FullLayout>
                    <TreasuryReport />
                  </FullLayout>
                </Route>
                <Route exact path='/administracion/poblacion'>
                  <FullLayout>
                    <TablePopulation />
                  </FullLayout>
                </Route>

                <Route exact path='/administracion/definicionDeMovimientos' history={useHistory}>
                  <FullLayout>
                    <ConfigMovesNew />
                  </FullLayout>
                </Route>

                <Route exact path='/administracion/configuracionContable-nueva'>
                  <FullLayout>
                    <NewProvision />
                  </FullLayout>
                </Route>
                <Route exact path='/administracion/TMDeCartera/EdadesPorCobrar'>
                  <FullLayout>
                    <ReceivableAges />
                  </FullLayout>
                </Route>
                <Route exact path='/administracion/TMDeCartera/EdadesPorCobrar/nueva'>
                  <FullLayout>
                    <NewReceivAge />
                  </FullLayout>
                </Route>

                {/* /* -------------------------------------------------------------------------- */
                /*                                           Telemedicine                        */
                /* -------------------------------------------------------------------------- */}

                <Route exact path='/telemedicina/ConsultarCitas/'>
                  <FullLayout>
                    <ConsultAppointments />
                  </FullLayout>
                </Route>
                <Route exact path='/telemedicina/historialPagos/'>
                  <FullLayout>
                    <PayHistory />
                  </FullLayout>
                </Route>
                <Route exact path='/telemedicina/configuracion/'>
                  <FullLayout>
                    <TelemedicineSetup />
                  </FullLayout>
                </Route>
                <Route exact path='/telemedicina/medicosAgendas/'>
                  <FullLayout>
                    <DoctorsDiaries />
                  </FullLayout>
                </Route>
                <Route exact path='/telemedicina/detalleHorario/'>
                  <FullLayout>
                    <ScheduleDetail />
                  </FullLayout>
                </Route>
                <Route exact path='/telemedicina/informeCitas/'>
                  <FullLayout>
                    <CitationReport />
                  </FullLayout>
                </Route>
                <Route exact path='/telemedicina/detalleCita/'>
                  <FullLayout>
                    <DetailAppointment />
                  </FullLayout>
                </Route>
                {/* /* -------------------------------------------------------------------------- */
                /*                                           Appointment                       */
                /* -------------------------------------------------------------------------- */}

                <Route exact path='/administracion/tm-treasury/payment-types'>
                  <FullLayout>
                    <PaymentTypesTreasury />
                  </FullLayout>
                </Route>

                <Route exact path='/administracion/tm-treasury/types-costs-tc'>
                  <FullLayout>
                    <TypesCostsTc />
                  </FullLayout>
                </Route>

                <Route exact path='/administracion/tm-treasury/bank-note-types'>
                  <FullLayout>
                    <BankNoteTypes />
                  </FullLayout>
                </Route>

                <Route exact path='/administracion/tm-treasury/prioritize-bills-to-pay'>
                  <FullLayout>
                    <PrioritizeBillsToPay />
                  </FullLayout>
                </Route>

                <Route exact path='/logout/:id'>
                  <Logout />
                </Route>

                <Route path='/https://' />

                <Route exact path='/*'>
                  <Layout404 />
                </Route>
              </Switch>
            </PrivateRoute>
          </Switch>
        </Provider>
      </Router>
    );
  }
}

export default Routes;
