import deepcopy from 'deepcopy';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getAgreements, getOneAgreement } from '../../actions/agreementActions';
import { getProviders } from '../../actions/providersActions';
import { createPurchaseOrderGeneric, getPayConditions } from '../../actions/purchaseOrderActions';
import { convertDateToLatinFormat } from '../../helpers/convertDateFormat';
import { convertMoneyFormat, getPaymentCondition } from '../../helpers/helpers';
//-------------------------------- COMPONENTS --------------------------------//
import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
import { storage_get } from '../../actions/storageActions';
import { PAGE, PERPAGE } from '../../helpers/constants';
import { responseSwal } from '../../helpers/responseSwal';
import GenericTableNew from '../Layouts/GenericTableNew';
import ModalNew from '../Layouts/ModalNew';
import TableScrollSubtotal from '../Layouts/TableScrollNew/TableScrollSubtotal';
//--------------------------------------------------------------------------//

// -----------------styles 🔡 ------------------------------//
import paginationStyles from '../Layouts/pagination.module.scss';
import tableStyles from '../Layouts/tableStyle.module.scss';
import IndividualStyles from '../TableIndividualAuction/IndividualAuction_CCS.module.scss';
import style from './purchaseOrder.module.scss';
// --------------------------------------------------------//

// -----------------Images 🤪      ------------------------------//
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import FiltrarClear from '../../assets/img/icons/FiltrarClear.svg';
import Reloj from '../../assets/img/icons/Reloj.svg';
import agregarEspecialidad from '../../assets/img/icons/agregarEspecialidad.svg';
import canecaAzul from '../../assets/img/icons/canecaAzul.svg';
import cloneIcon from '../../assets/img/icons/cloneIcon.svg';
import Add from '../../assets/img/icons/crearCuenta.svg';
import lupaClear from '../../assets/img/icons/lupaClear.svg';
import { customSelectNewDark } from '../Layouts/react-select-custom';
import SelectComponent from '../SelectComponent/SelectComponent';

// --------------------------------------------------------//

class PurchaseOrder extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  get initialState() {
    return {
      modalProgramming: false,
      partial_deliveries: false,
      modalAddArticle: false,
      listArticlesAgreement: [],
      addProgramming: {},
      addPurchaseOrder: {
        provider: '',
        agreement_name: '',
        nit: '',
        verDigit: '',
        agreement: '',
        agreement_number: '',
        terms: '',
        pay_conditions: '',
        id_warehouse: '',
        delivery_date: '',
        observations: '',
      },
      addAgreement: {
        name: '',
        agreement_number: '',
      },
      addArticle: {},
      modalAgreementDetail: false,
      paginationModal: {
        page: 1,
        perpage: 10,
        totalIndex: 0,
      },
      filtersArticles: {
        page: 1,
        perpage: 5,
        totalIndex: 0,
        search: '',
      },
      filters: {
        search: '',
        status: '',
        due_date: '',
        // current_date:CURDATE,
        eaccount: this.props.currentAccount.id,
        perpage: PERPAGE,
        page: PAGE,
      },
      filtersAgreement: {
        search: '',
        status: 'authorized',
        valid_until: '',
        // current_date:CURDATE,
        eaccount: this.props.currentAccount.id,
        perpage: PERPAGE,
        page: PAGE,
      },
      modalAgreements: false,
      redirect: null,
      checkTable: false,
      search_article: '',
    };
  }

  componentDidMount() {
    // this.props.getAgreements({
    //   eaccount: this.props.currentAccount.id,
    //   slim: true,
    //   status: "authorized",
    // });
    this.props.getProviders({
      id_account: this.props.currentAccount.id,
      slim: true,
      status: 'enabled',
      checked: 1,
    });
    this.props.getPayConditions();
    this.props.storage_get({
      id_account: this.props.currentAccount.id,
      supplier: 1,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      let partial = false;
      if (this.state.addPurchaseOrder?.articles) {
        this.state.addPurchaseOrder?.articles?.forEach(item => {
          if (item?.deliveries?.length > 0) {
            partial = true;
          }
        });
      }
      if (partial !== this.state.partial_deliveries) {
        this.setState({ ...this.state, partial_deliveries: partial });
      }
    }
  }

  handlePageChange(pageNumber) {
    let { filters } = this.state;
    filters.page = pageNumber;
    this.setState({ filters });
  }

  handlePageChangeAgreements(pageNumber) {
    let { filtersAgreement } = this.state;
    filtersAgreement.page = pageNumber;
    this.setState({ filtersAgreement });
    this.props.getAgreements(filtersAgreement);
  }

  handleChangePageArticles(pageNumber) {
    let { filtersArticles } = this.state;
    filtersArticles.page = pageNumber;
    this.setState({ filtersArticles });
  }

  showAgreementDetail = agreement => {
    let { addAgreement, modalAgreementDetail } = this.state;
    modalAgreementDetail = true;
    addAgreement = deepcopy(agreement);
    this.props.getOneAgreement(agreement.id, () => {
      addAgreement = this.props.agreementDetails[0];
      this.setState({ addAgreement });
    });
    this.setState({ modalAgreementDetail });
  };

  myPermission = () =>
    this.props.currentAccount?.profile?.permission?.find(x => x.functionality?.prefix === 'OC');

  onChangeForm = e => {
    const { name, value } = e.target ?? e;
    let { addPurchaseOrder } = this.state;
    addPurchaseOrder[name] = value;
    this.setState({ addPurchaseOrder });
  };

  onChangeProvider = e => {
    const { value } = e;
    let { addPurchaseOrder, addAgreement } = this.state;
    addPurchaseOrder.provider = value;
    let prov = this.props.providers.filter(x => x.id === parseInt(value));
    let item = prov[0];
    addPurchaseOrder.nit = item.nit.slice(0, item.nit.length - 1);
    addPurchaseOrder.verDigit = item.nit.slice(item.nit.length - 1, item.nit.length);
    this.props.getAgreements({
      ...this.state.filtersAgreement,
      provider: value,
    });
    addPurchaseOrder.agreement = null;
    addPurchaseOrder.agreement_number = null;
    addPurchaseOrder.agreement_name = null;
    addPurchaseOrder.articles = null;
    addPurchaseOrder.subtotal = null;
    addPurchaseOrder.total_discount = null;
    addPurchaseOrder.total_iva = null;
    addPurchaseOrder.total = null;
    addAgreement = {};
    this.setState({ addPurchaseOrder, addAgreement });
  };

  handlePrograming = article => {
    let { modalProgramming, addArticle } = this.state;
    addArticle = article;
    if (!article.deliveries) addArticle.deliveries = [];
    modalProgramming = true;
    this.setState({ addArticle, modalProgramming });
  };

  renderArticles = (article, index) => {
    let realIndex = index + 1;
    let count = `${
      this.state.paginationModal.page - 1 > 0 ? this.state.paginationModal.page - 1 : ''
    }${realIndex}`;
    if (realIndex > 1 && realIndex % 10 === 0) count = this.state.paginationModal.page * 10;
    return (
      <tr key={article.id} style={{ overflow: 'scroll' }}>
        <td className='text-center w-10'>{count}</td>
        <td className='text-start'>
          {article?.article_ref?.description ? article.article_ref.description : '-'}
        </td>
        <td className='text-center w-10'>{article.brand}</td>
        <td className='text-center'>
          {article?.unit_price ? `${convertMoneyFormat(article.unit_price)}` : '-'}
        </td>
        <td className='text-center'>{article.qty_article ? article.qty_article : '-'}</td>
        <td className='text-center'>{article.discount ? `${article.discount}%` : '-'}</td>
        <td className='text-center'>{article.iva ? `${article.iva}%` : '-'}</td>
        <td className='text-center'>
          {article.qty_article && article.unit_price
            ? convertMoneyFormat(
                article.qty_article * article.unit_price +
                  article.iva_value -
                  article.discount_value,
              )
            : '-'}
        </td>
        {/* <td className="text-center">
          <img src={Clock} alt="entregas" width="18" className={article?.deliveries?.length > 0 ? "" : ownStyles.clock } onClick={()=>this.setState({...this.state, modalOC:true, modal:false, article:article})}/>
        </td> */}
      </tr>
    );
  };

  onChangeCheckArticle = (e, index) => {
    const { checked } = e.target;
    let { addPurchaseOrder, checkTable } = this.state;
    addPurchaseOrder.articles[index].check = checked;
    if (checked) checkTable = false;
    this.setState({ addPurchaseOrder, checkTable });
  };

  createData = () => {
    let {
      addPurchaseOrder,
      addAgreement,
      modalAgreementDetail,
      modalAgreements,
      listArticlesAgreement,
    } = this.state;
    addPurchaseOrder.agreement = addAgreement.id;
    addPurchaseOrder.quotation = addAgreement.quotation_ref.id;
    addPurchaseOrder.agreement_number = addAgreement.agreement_number;
    addPurchaseOrder.agreement_name = addAgreement.name;
    addPurchaseOrder.articles = deepcopy(addAgreement.quotation_ref.quotation_article_ref);
    addPurchaseOrder.subtotal = addAgreement.quotation_ref.subtotal;
    addPurchaseOrder.total_discount = addAgreement.quotation_ref.total_discount;
    addPurchaseOrder.total_iva = addAgreement.quotation_ref.total_iva;
    addPurchaseOrder.total = addAgreement.quotation_ref.total;
    if (!addPurchaseOrder.provider || addPurchaseOrder.provider !== addAgreement.provider) {
      addPurchaseOrder.provider = addAgreement.provider;
      addPurchaseOrder.nit = addAgreement.provider_ref.nit.slice(
        0,
        addAgreement.provider_ref.nit.length - 1,
      );
      addPurchaseOrder.verDigit = addAgreement.provider_ref.nit.slice(
        addAgreement.provider_ref.nit.length - 1,
        addAgreement.provider_ref.nit.length,
      );
    }
    modalAgreementDetail = false;
    modalAgreements = false;
    this.setState({
      addPurchaseOrder,
      modalAgreementDetail,
      modalAgreements,
      listArticlesAgreement,
    });
  };

  saveProgramming = () => {
    let { addPurchaseOrder, addArticle, modalProgramming, addProgramming } = this.state;
    modalProgramming = false;
    addProgramming = {};
    this.setState({
      addPurchaseOrder,
      addArticle,
      modalProgramming,
      addProgramming,
    });
  };

  onChangeCheckTable = () => {
    let { addPurchaseOrder, checkTable } = this.state;
    checkTable = !checkTable;
    if (addPurchaseOrder.articles.length > 0) {
      addPurchaseOrder.articles.forEach(article => {
        article.check = checkTable;
      });
      this.setState({ addPurchaseOrder, checkTable });
    }
  };

  handleDiscardArticle = () => {
    let { addPurchaseOrder } = this.state;
    let subtotal = 0,
      discount_value = 0,
      iva_value = 0,
      total = 0;
    addPurchaseOrder.articles = addPurchaseOrder.articles.filter(x => !x.check);
    addPurchaseOrder.articles.forEach(article => {
      subtotal += article.qty_article * article.unit_price;
      discount_value += article.qty_article * article.unit_price * (article.discount / 100);
      iva_value += (subtotal - discount_value) * (article.iva / 100);
    });

    total = subtotal - discount_value + iva_value;

    addPurchaseOrder.subtotal = subtotal;
    addPurchaseOrder.total = total;
    addPurchaseOrder.total_iva = iva_value;
    addPurchaseOrder.total_discount = discount_value;
    this.setState({ addPurchaseOrder });
  };

  loadArticles = () => {
    let { addPurchaseOrder, addAgreement, modalAddArticle, listArticlesAgreement } = this.state;
    if (addAgreement.quotation_ref) {
      listArticlesAgreement = deepcopy(addAgreement?.quotation_ref?.quotation_article_ref);
      for (let i = 0; i < listArticlesAgreement.length; i++) {
        let artAgreement = listArticlesAgreement[i];
        for (let j = 0; j < addPurchaseOrder?.articles?.length; j++) {
          let artPurchase = addPurchaseOrder.articles[j];
          if (artPurchase.id_article === artAgreement.id_article) {
            artAgreement.check = true;
          }
        }
      }
    }
    modalAddArticle = true;
    this.setState({
      addPurchaseOrder,
      addAgreement,
      modalAddArticle,
      listArticlesAgreement,
    });
  };

  addArticleToOrder = () => {
    let { addPurchaseOrder, listArticlesAgreement } = this.state;
    let subtotal = 0,
      discount_value = 0,
      iva_value = 0,
      total = 0;
    listArticlesAgreement.forEach(article => {
      if (article.check) {
        if (addPurchaseOrder.articles.filter(x => x.id_article === article.id_article).length < 1)
          addPurchaseOrder.articles.push(article);
      }
    });

    addPurchaseOrder.articles.forEach(article => {
      subtotal += article.qty_article * article.unit_price;
      discount_value += article.qty_article * article.unit_price * (article.discount / 100);
      iva_value += (subtotal - discount_value) * (article.iva / 100);
    });

    total += subtotal - discount_value + iva_value;

    addPurchaseOrder.subtotal = subtotal;
    addPurchaseOrder.total = total;
    addPurchaseOrder.total_iva = iva_value;
    addPurchaseOrder.total_discount = discount_value;
    this.setState({ addPurchaseOrder });
  };

  providerOptions = () => {
    let arr = [{ value: '', label: 'Seleccionar...' }];
    this.props.providers?.forEach(prov => {
      arr.push({ value: prov.id, label: prov.name });
    });
    return arr;
  };
  payConditionOptions = () => {
    let arr = [{ value: '', label: 'Seleccionar...', name: 'pay_conditions' }];
    this.props.payConditions?.forEach(condition => {
      arr.push({ value: condition.id, label: condition.name, name: 'pay_conditions' });
    });
    return arr;
  };
  idWarehouseOptions = () => {
    let arr = [{ value: '', label: 'Seleccionar...', name: 'id_warehouse' }];
    this.props.listStorage?.forEach(storage => {
      arr.push({ value: storage.id, label: storage.address, name: 'id_warehouse' });
    });
    return arr;
  };

  providersOptions = () => {
    let arr = [{ value: '', label: 'Seleccionar...', name: 'id_warehouse' }];
    this.props.providers?.forEach(prov => {
      arr.push({ value: prov.id, label: prov.name, name: 'id_warehouse' });
    });
    return arr;
  };
  // ANCHOR render method
  render() {
    if (!this.props.isAuth) {
      return <Redirect to='/login' />;
    }

    // ANCHOR render columns
    const renderColumns = [
      {
        id: 'checked',
        label: (
          <input
            type='checkbox'
            name='checked'
            className='border border-dark-blue form-check-input p-2 check-dark-blue'
            checked={
              this.state.addPurchaseOrder?.articles?.filter(x => !!x.check).length ===
                this.state.addPurchaseOrder?.articles?.length &&
              this.state.addPurchaseOrder?.articles?.length > 0
            }
            onChange={() => this.onChangeCheckTable()}
          />
        ),
        minWidth: 80,
        align: 'center',
        className: style.headersDark,
      },
      {
        id: 'delivery',
        label: 'Entrega',
        minWidth: 80,
        align: 'center',
        className: style.headersDark,
      },
      {
        id: 'item',
        label: 'Item',
        minWidth: 80,
        align: 'center',
        className: style.headersDark,
      },
      {
        id: 'description',
        label: 'Descripción de Artículo',
        minWidth: 80,
        align: 'center',
        className: style.headersDark,
      },
      {
        id: 'qty_article',
        label: 'Cantidad',
        minWidth: 80,
        align: 'center',
        className: style.headersDark,
      },
      {
        id: 'unit_price',
        label: 'Valor Unidad',
        minWidth: 80,
        align: 'center',
        className: style.headersDark,
      },
      {
        id: 'discount',
        label: (
          <div className='d-flex flex-column'>
            <div className='mx-auto text-center px-3'>
              <span>Descuento</span>
            </div>
            <div className='d-flex justify-content-around'>
              <span>%</span>
              <span>$</span>
            </div>
          </div>
        ),
        minWidth: 80,
        align: 'center',
        className: style.headersDark,
      },
      {
        id: 'iva',
        label: (
          <div className='d-flex flex-column'>
            <div className='mx-auto text-center px-3'>
              <span>IVA</span>
            </div>
            <div className='d-flex justify-content-around'>
              <span>%</span>
              <span>$</span>
            </div>
          </div>
        ),
        minWidth: 170,
        align: 'center',
        className: style.headersDark,
      },
      {
        id: 'total',
        label: 'Total',
        minWidth: 80,
        align: 'center',
        className: style.headersDark,
      },
    ];

    var renderRows = [];
    this.state.addPurchaseOrder?.articles?.forEach((article, index) => {
      let item = {
        id: article.id_article,
        checked: (
          <input
            type='checkbox'
            className='border border-dark-blue form-check-input p-2 check-dark-blue'
            checked={article?.check}
            onChange={e => {
              this.onChangeCheckArticle(e, index);
            }}
          />
        ),
        delivery: (
          <img
            src={Reloj}
            alt='programaciones'
            width={18}
            className={`hoverPointer ${article.deliveries ? '' : style.grayClock}`}
            onClick={() => this.handlePrograming(article)}
          />
        ),
        item: index + 1,
        description: article.article_ref.description,
        brand: article.brand,
        unit_price: article.unit_price,
        qty_article: article.qty_article,
        discount: article.discount,
        iva: article.iva,
      };
      renderRows.push(item);
    });

    var renderArticlesModal = this.state.addAgreement?.quotation_ref?.quotation_article_ref.map(
      (article, index) => {
        return this.renderArticles(article, index);
      },
    );

    var headerModalAgreementDetails = [
      '#',
      'Descripción del artículo',
      'Marca',
      'Precio Unt.',
      'Cant.',
      'Descuento',
      'IVA',
      'Valor Total',
    ].map(header => {
      return (
        <th key={`hdModal-${header}`} className='text-center'>
          {header}
        </th>
      );
    });

    let sumProgramming = 0;
    this.state.addArticle?.deliveries?.forEach(item => {
      sumProgramming += parseInt(item.qty_article);
    });
    let renderRest = parseInt(this.state.addArticle.qty_article) - sumProgramming;

    return (
      <div className='w-90 mx-auto'>
        {this.props.loading ? (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        ) : null}
        {this.myPermission()?.create && (
          <>
            <div className='d-flex flex-column'>
              <div className='d-flex justify-content-between'>
                <h1>Orden de compra</h1>
                <div
                  className={`d-flex align-self-end mb-2 ${style.groupBounding}`}
                  onClick={() =>
                    this.props.getAgreements(this.state.filtersAgreement, () =>
                      this.setState({ ...this.state, modalAgreements: true }),
                    )
                  }
                >
                  <h6 className='darkGray mx-2 my-auto'>Traer convenio</h6>
                  <img src={cloneIcon} alt='traer convenio' width={18} className='hoverPointer' />
                </div>
              </div>

              {/*------------------------------ first row------------------- 🚣 */}
              <div className='d-flex align-items-end'>
                <div className='col flex-column mr-2'>
                  <label htmlFor='provider' className={`w-100 ${style.labelFont}`}>
                    Proveedor
                    <b style={{ color: '#FF8B00' }}>*</b>
                  </label>

                  {/* <select
                    name="provider"
                    className={`w-100 ${style.selectInput}`}
                    onChange={(e) => this.onChangeProvider(e)}
                    value={this.state.addPurchaseOrder.provider}
                  >
                    <option value=""></option>
                    {this.props.providers?.map((prov) => {
                      return <option value={prov.id}>{prov.name}</option>;
                    })}
                  </select> */}
                  <SelectComponent
                    value={this.providerOptions().find(
                      x => x.value === this.state.addPurchaseOrder.provider,
                    )}
                    styles={customSelectNewDark}
                    placeholder={'Seleccionar...'}
                    onChange={e => {
                      this.onChangeProvider(e);
                    }}
                    // noOptionsMessage={() => "No hay datos"}

                    options={this.providerOptions()}
                  ></SelectComponent>
                </div>

                <div className='col flex-column mr-2'>
                  <div className={`${style.selectGroupWithOutMargin} w-100 align-end`}>
                    <label htmlFor='nit' className={`${style.labelFont} w-100`}>
                      NIT<b style={{ color: '#FF8B00' }}>*</b>
                    </label>
                    <div className={`d-flex align-items-center`}>
                      <input
                        type='number'
                        min={0}
                        maxLength='15'
                        name='nit'
                        disabled
                        className={`${style.selectInput} ${style.eightyWith} ${style.notMarginRight} align-self-end`}
                        value={this.state.addPurchaseOrder?.nit}
                      />
                      <hr className={`${style.nitLine} m-0`} />
                      <input
                        type='number'
                        maxLength='1'
                        disabled
                        className={`${style.nitDigit} register-inputs align-self-end`}
                        value={this.state.addPurchaseOrder?.verDigit}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className='col flex-column mr-2 hoverPointer'
                  onClick={e => {
                    e.preventDefault();
                    this.setState({ ...this.state, modalAgreements: true });
                  }}
                >
                  <label htmlFor='agreement' className={`w-100 ${style.labelFont}`}>
                    Convenio
                    <b style={{ color: '#FF8B00' }}>*</b>
                  </label>

                  <input
                    type='text'
                    name='agreement'
                    className={`register-inputs ${style.inputTextWithIcon}`}
                    disabled
                    value={this.state.addPurchaseOrder?.agreement_name}
                  />
                </div>

                <div className='col flex-column'>
                  <label htmlFor='agreement_number' className={`w-100 ${style.labelFont}`}>
                    No. Convenio
                  </label>

                  <input
                    type='text'
                    name='agreement_number'
                    className={`register-inputs`}
                    disabled
                    value={this.state.addPurchaseOrder?.agreement_number}
                  />
                </div>
              </div>
              {/*------------------------------ end row------------------- 🚣 */}

              {/*------------------------------ second row------------------- 🚣 */}
              <div className='d-flex mt-1 mb-3 align-items-end'>
                <div className='col flex-column'>
                  <label htmlFor='provider' className={style.labelFont}>
                    Términos y condiciones
                    <b style={{ color: '#FF8B00' }}>*</b>
                  </label>
                  <input
                    type='text'
                    name='terms'
                    className={`register-inputs`}
                    placeholder='Escribir...'
                    onChange={e => this.onChangeForm(e)}
                    value={this.state.addPurchaseOrder.terms}
                  />
                </div>

                <div className='col flex-column mx-2 '>
                  <label htmlFor='pay_conditions' className={`w-100 ${style.labelFont}`}>
                    Condiciones de pago <b style={{ color: '#FF8B00' }}>*</b>
                  </label>
                  <SelectComponent
                    value={this.payConditionOptions().find(
                      x => x.value === this.state.addPurchaseOrder.pay_conditions,
                    )}
                    styles={customSelectNewDark}
                    placeholder={'Seleccionar...'}
                    onChange={e => {
                      this.onChangeForm(e);
                    }}
                    // noOptionsMessage={() => "No hay datos"}

                    options={this.payConditionOptions()}
                  ></SelectComponent>
                </div>

                <div className='col flex-column mr-2'>
                  <label className={`w-100 ${style.labelFont}`} htmlFor='address'>
                    Dirección de entrega <b style={{ color: '#FF8B00' }}>*</b>
                  </label>
                  {/* <select
                    name="id_warehouse"
                    className={`w-100 ${style.selectInput}`}
                    onChange={(e) => this.onChangeForm(e)}
                    value={this.state.addPurchaseOrder?.id_warehouse}
                  >
                    <option value=""></option>
                    {this.props.listStorage?.map((storage) => {
                      return (
                        <option
                          value={storage.id}
                          key={`address-${storage.id}`}
                        >
                          {storage.address}
                        </option>
                      );
                    })}
                  </select> */}
                  <SelectComponent
                    value={this.idWarehouseOptions().find(
                      x => x.value === this.state.addPurchaseOrder?.id_warehouse,
                    )}
                    styles={customSelectNewDark}
                    placeholder={'Seleccionar...'}
                    onChange={e => {
                      this.onChangeForm(e);
                    }}
                    // noOptionsMessage={() => "No hay datos"}

                    options={this.idWarehouseOptions()}
                  ></SelectComponent>
                </div>

                <div className='col flex-column'>
                  <label htmlFor='delivery_date' className={`w-100 ${style.labelFont}`}>
                    Fecha de entrega<b style={{ color: '#FF8B00' }}>*</b>
                  </label>

                  <div className={tableStyles.deleteicon}>
                    {this.state.addPurchaseOrder?.delivery_date ? (
                      <span
                        onClick={() =>
                          this.onChangeForm({
                            target: {
                              name: 'delivery_date',
                              value: '',
                            },
                          })
                        }
                      >
                        x
                      </span>
                    ) : (
                      ''
                    )}
                    <input
                      type='date'
                      name='delivery_date'
                      className={`register-inputs`}
                      onChange={e => this.onChangeForm(e)}
                      value={this.state.addPurchaseOrder?.delivery_date}
                      min={new Date().toISOString().slice(0, 10)}
                    />
                  </div>
                </div>
              </div>
              {/*------------------------------ end row------------------- 🚣 */}
              <div className='d-flex mb-3 justify-content-between'>
                <div>
                  <input
                    type='checkbox'
                    name='partial_deliveries'
                    checked={this.state.partial_deliveries}
                    className='form-check-input mr-2'
                    readOnly
                  />
                  <label className='form-check-label text-secondary' htmlFor='partial_deliveries'>
                    Contiene entregas parciales
                  </label>
                </div>
                <div>
                  <img
                    src={canecaAzul}
                    alt='trash'
                    width={22}
                    className={`mx-2 ${style.iconActions}`}
                    onClick={() => this.handleDiscardArticle()}
                  />
                  <img
                    src={agregarEspecialidad}
                    alt='trash'
                    width={18}
                    className={style.iconActions}
                    onClick={() => {
                      this.loadArticles();
                    }}
                  />
                </div>
              </div>

              {/*#️⃣ ------------------------------ ANCHOR DATA TABLE------------------- #️⃣  */}
              <TableScrollSubtotal
                makeDataWithCheck
                columns={renderColumns}
                rows={renderRows}
                subtotal={this.state.addPurchaseOrder?.subtotal}
                total_iva={this.state.addPurchaseOrder?.total_iva}
                total_discount={this.state.addPurchaseOrder?.total_discount}
                total={this.state.addPurchaseOrder?.total}
                valueTextarea={this.state.addPurchaseOrder?.observations}
                onChangeTextArea={e => {
                  this.setState({
                    ...this.state,
                    addPurchaseOrder: {
                      ...this.state.addPurchaseOrder,
                      observations: e.target.value,
                    },
                  });
                }}
              />

              {/* -------------------------------- ANCHOR MODAL AGREEMENTS 🤝  -------------------------------- */}

              <ModalNew
                title='Seleccionar convenio'
                show={this.state.modalAgreements}
                onHide={() =>
                  this.setState({
                    ...this.state,
                    modalAgreements: false,
                    addAgreement: {},
                  })
                }
                size='940'
              >
                {/* ------------------------------------filters 📽  ------------------------------------ */}
                <div className='d-flex'>
                  <div className='col flex-column'>
                    <label htmlFor='valid_until' className={`w-100 ${style.labelFont}`}>
                      Fecha
                    </label>
                    <input
                      type='date'
                      className={`register-inputs w-100`}
                      style={{ height: '28px' }}
                      onChange={e =>
                        this.setState({
                          ...this.state,
                          filtersAgreement: {
                            ...this.state.filtersAgreement,
                            created_at: e.target.value,
                          },
                        })
                      }
                    />
                  </div>

                  <div className='col flex-column mx-2'>
                    <label htmlFor='provider_agreement' className={`w-100 ${style.labelFont}`}>
                      Proveedor
                    </label>
                    <SelectComponent
                      name='provider_agreement'
                      id='provider_agreement'
                      value={this.providersOptions().find(
                        x => x.value === this.state.filtersAgreement?.provider,
                      )}
                      styles={customSelectNewDark}
                      placeholder={'Seleccionar...'}
                      onChange={e =>
                        this.setState({
                          ...this.state,
                          filtersAgreement: {
                            ...this.state.filtersAgreement,
                            provider: e.value,
                          },
                        })
                      }
                      // noOptionsMessage={() => "No hay datos"}

                      options={this.providersOptions()}
                    ></SelectComponent>
                  </div>

                  <div className='col-5 flex-column'>
                    <label
                      htmlFor='provider_agreement'
                      className={`w-100 ${style.labelFont}`}
                    ></label>
                    <div className='d-flex'>
                      <input
                        type='text'
                        className={`w-100 mr-2 register-inputs`}
                        placeholder='Número o nombre de convenio'
                        onChange={e =>
                          this.setState({
                            ...this.state,
                            filtersAgreement: {
                              ...this.state.filtersAgreement,
                              search: e.target.value,
                            },
                          })
                        }
                      />
                      <img
                        src={lupaClear}
                        alt='filtrar'
                        width={24}
                        className='hoverPointer'
                        onClick={() => this.props.getAgreements(this.state.filtersAgreement)}
                      />
                    </div>
                  </div>
                </div>
                {/* -------------------------------------------------------------------------------------*/}

                <GenericTableNew
                  headers={[
                    <th key={1} className='px-2'>
                      No.
                    </th>,
                    <th key={2}>Fecha</th>,
                    <th key={3}>Nombre del convenio</th>,
                    <th key={4}>Proveedor</th>,
                    <th key={5} className='px-2'>
                      &nbsp;
                    </th>,
                  ]}
                >
                  {this.props.agreements?.map(agree => (
                    <tr key={`agree-${agree.id}`}>
                      <td className='px-2'>{agree.agreement_number}</td>
                      <td>{convertDateToLatinFormat(agree.created_at)}</td>
                      <td className='text-start'>{agree.name}</td>
                      <td className='text-start'>{agree?.provider_ref?.name}</td>
                      <td>
                        <img
                          src={FiltrarClear}
                          alt='Ver detalle'
                          width={14}
                          className='hoverPointer'
                          onClick={() => this.showAgreementDetail(agree)}
                        />
                      </td>
                    </tr>
                  ))}
                </GenericTableNew>

                <div className={`${paginationStyles.wrapper} justify-content-between mt-2`}>
                  <p style={{ display: 'inline' }}>
                    Pag. {this.state.filtersAgreement.page} de{' '}
                    {Math.ceil(
                      this.props.row_total_agreements / this.state.filtersAgreement.perpage,
                    )
                      ? Math.ceil(
                          this.props.row_total_agreements / this.state.filtersAgreement.perpage,
                        )
                      : ''}{' '}
                    ({this.props.row_total_agreements} encontrados)
                  </p>
                  <Pagination
                    activePage={this.state.filtersAgreement.page}
                    itemsCountPerPage={this.state.filtersAgreement.perpage}
                    totalItemsCount={this.props.row_total_agreements}
                    pageRangeDisplayed={this.props.row_total}
                    activeClass={paginationStyles.activeClass}
                    onChange={this.handlePageChangeAgreements.bind(this)}
                    itemClassPrev={paginationStyles.itemClassPrev}
                    itemClassNext={paginationStyles.itemClassNext}
                    itemClassFirst={paginationStyles.itemClassFirst}
                    itemClassLast={paginationStyles.itemClassLast}
                    itemClass={paginationStyles.itemClass}
                  />
                </div>
              </ModalNew>

              {/* -------------------------------- END MODAL AGREEMENTS -------------------------------- */}

              {/* ---------------------------------ANCHOR MODAL AGREEMENT DETAILS ------------------------------ */}

              <ModalNew
                onHide={() =>
                  this.setState({
                    ...this.state,
                    modalAgreementDetail: false,
                    addAgreement: {},
                  })
                }
                show={this.state.modalAgreementDetail}
                size='xl'
                centered
                title={this.state.addAgreement?.name}
                subtitle={this.state.addAgreement?.provider_ref?.name}
                btnYesDisabled={this.state.addAgreement?.quotation_ref ? false : true}
                btnYesName='Clonar'
                btnYesEvent={() => this.createData()}
              >
                <span className='fw-bold text-primary list-style-circle'>
                  Información del convenio
                </span>
                <div className='row justify-content-between w-100'>
                  <div className='d-flex flex-column col-sm-3'>
                    <label htmlFor='create_at' className={style.labelFont}>
                      Fecha de creación
                    </label>
                    <input
                      type='date'
                      name='create_at'
                      className={`${style.selectInput} text-primary fw-bold`}
                      disabled
                      value={this.state.addAgreement?.created_at}
                    />
                  </div>

                  <div className='d-flex flex-column col-sm-5'>
                    <label htmlFor='name' className={style.labelFont}>
                      Nombre del convenio
                    </label>
                    <input
                      type='text'
                      name='name'
                      className={`${style.selectInput} ${
                        this.state.editRecord ? style.inputIconEdit : ''
                      }`}
                      value={this.state.addAgreement?.name}
                      disabled={this.state.editRecord ? false : true}
                      onChange={this.onChangeForm}
                    />
                  </div>

                  <div className='d-flex flex-column col-sm-4'>
                    <label htmlFor='pay_conditions' className={style.labelFont}>
                      Condiciones de pago
                    </label>
                    <input
                      type='text'
                      name='pay_conditions'
                      className={`${style.selectInput} ${
                        this.state.editRecord ? style.inputIconEdit : ''
                      } bg-disabled-input`}
                      disabled={this.state.editRecord ? false : true}
                      value={getPaymentCondition(
                        this.state.addAgreement?.quotation_ref?.pay_conditions,
                      )}
                      onChange={this.onChangeForm}
                    />
                  </div>
                </div>

                <div className={`${style.lineSection} row justify-content-start w-100 mt-3`}>
                  <div className='d-flex flex-column col-sm-3'>
                    <label htmlFor='valid_from' className={style.labelFont}>
                      Vigencia desde* :
                    </label>
                    <input
                      type='date'
                      name='valid_from'
                      className={`${style.selectInput} text-primary fw-bold`}
                      disabled
                      value={this.state.addAgreement?.valid_from}
                    />
                  </div>
                  <div className={`d-flex flex-column col-sm-3 ${style.lineBefore}`}>
                    <label htmlFor='valid_until' className={style.labelFont}>
                      Hasta*:
                    </label>
                    <input
                      type='date'
                      name='valid_until'
                      className={`${style.selectInput} ${
                        this.state.editRecord ? style.inputIconEditWithOriginal : ''
                      } fw-bold text-primary`}
                      value={this.state.addAgreement?.valid_until}
                      disabled={this.state.editRecord ? false : true}
                      onClick={e => this.handleUntilDate(e)}
                      // onChange={this.onChangeForm}
                    />
                  </div>
                  <div className='d-flex flex-column col-sm-9'></div>
                </div>
                <span className='fw-bold text-primary list-style-circle'>
                  Detalle de cotización base
                </span>
                <div className='row justify-content-between w-100 mt-2'>
                  <div className='d-flex flex-column col-sm-3'>
                    <label htmlFor='quotation_number' className={style.labelFont}>
                      No. de la cotización
                    </label>
                    <input
                      type='text'
                      name='quotation_number'
                      className={`${style.selectInput} text-primary fw-bold`}
                      disabled
                      value={this.state.addAgreement?.quotation_ref?.quotation_number}
                    />
                  </div>

                  <div className='d-flex flex-column col-sm-5'>
                    <label htmlFor='name' className={style.labelFont}>
                      Nombre de la cotización
                    </label>
                    <input
                      type='text'
                      name='name'
                      className={style.selectInput}
                      value={
                        this.state.addAgreement?.quotation_ref?.individual_auctions?.description
                      }
                      disabled
                    />
                  </div>

                  <div className='d-flex flex-column col'>
                    <label htmlFor='quote_validity' className={style.labelFont}>
                      Validez
                    </label>
                    <input
                      type='date'
                      name='quote_validity'
                      className={`${style.selectInput} bg-disabled-input`}
                      disabled
                      value={this.state.addAgreement?.quotation_ref?.quote_validity}
                    />
                  </div>
                  {/* <img
              src={iconDownload}
              style={{ width: "48px", alignSelf: "flex-end", marginRight:"-22px" }}
              className="hoverPointer"
            /> */}
                </div>
                <GenericTableNew headers={headerModalAgreementDetails}>
                  {renderArticlesModal}
                </GenericTableNew>
                {/* <div className="d-flex justify-content-end">
            <Pagination
              activePage={this.state.paginationModal.page}
              itemsCountPerPage={this.state.paginationModal.perpage}
              totalItemsCount={
                this.props?.totalArticles ? this.props.totalArticles : 10
              }
              pageRangeDisplayed={10}
              activeClass={paginationStyles.activeClass}
              onChange={this.handlePageChangeModalArticle.bind(this)}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div> */}
                <div className='d-flex justify-content-between'>
                  <h6 className='fw-bold text-primary paddingLeft1rem'>TOTALES</h6>
                  <h6 className='fw-bold text-primary px-3'>
                    ${this.state.addAgreement?.quotation_ref?.total.toLocaleString()}
                  </h6>
                </div>
                <div className='d-flex w-100 justify-content-between'>
                  <div className='w-100'>
                    <label htmlFor='observations' className={style.labelFont}>
                      Observaciones
                    </label>
                    <textarea
                      type='text'
                      name='observations'
                      value={this.state.addAgreement?.observations}
                      readOnly
                      className={`${style.inputText} ${style.textareaObservations} ml-2 w-100`}
                    />
                  </div>
                </div>
              </ModalNew>
              {/* ---------------------------------********************** ------------------------------ */}

              {/* -------------------------------- ANCHOR MODAL PROGRAMMING --------------------------------*/}

              <ModalNew
                show={this.state.modalProgramming}
                title='Programación de Entregas'
                size='550'
                onHide={() => this.setState({ ...this.state, modalProgramming: false })}
                btnYesName='Grabar programación'
                btnYesEvent={() => this.saveProgramming()}
              >
                <div
                  id='arriba'
                  className={`${IndividualStyles.backgroundModal} ${IndividualStyles.paddingButton}`}
                >
                  {/* ---------------------------- ROW 1 ---------------------------------- */}
                  <label className={`${IndividualStyles.subtitle} `}>
                    Información del producto
                  </label>
                  <div className='d-flex'>
                    <div className={` ${IndividualStyles.ItemSmall}`}>
                      <p className={IndividualStyles.crudModalLabelGris}>Producto</p>
                      <input
                        className={` ${style.inputText} w-100 darkGray fw-normal`}
                        name='article_name'
                        type='text'
                        readOnly
                        value={this.state.addArticle?.article_ref?.description}
                      />
                    </div>

                    <div
                      className={`${IndividualStyles.inputMarginleft} ${IndividualStyles.ItemTiny2}`}
                    >
                      <p className={` ${IndividualStyles.crudModalLabelGris}`}>Cantidad</p>
                      <input
                        className={` ${tableStyles.crudModalInputDate} ${IndividualStyles.registerInputsBlue} darkGray`}
                        name='amount'
                        type='text'
                        readOnly
                        value={this.state.addArticle?.qty_article}
                      />
                    </div>

                    <div className={` ${IndividualStyles.ItemTiny2}`}>
                      <p className={` ${IndividualStyles.crudModalLabelGris}`}>Código </p>
                      <input
                        className={`${IndividualStyles.styleSelect} ${IndividualStyles.registerInputsBlue} fw-normal`}
                        name='code'
                        type='text'
                        readOnly
                        value={this.state.addArticle?.id_article}
                      />
                    </div>
                  </div>
                </div>
                {/* ============================= ROW 2 =================================== */}
                <div
                  id='bajo'
                  className={`${IndividualStyles.backgroundModal_new} ${IndividualStyles.paddingButton}`}
                >
                  <label className={`${IndividualStyles.subtitle}`}>Información de entrega</label>
                  <div className='d-flex'>
                    <div className={`${IndividualStyles.ItemSmall1}`}>
                      <p className={tableStyles.crudModalLabel}>Fecha de entrega</p>
                      <input
                        className={`${IndividualStyles.registerInputsBlue}`}
                        name='delivery_date'
                        type='date'
                        min={new Date().toISOString().slice(0, 10)}
                        value={this.state.addProgramming.delivery_date}
                        onChange={e => {
                          let { addProgramming } = this.state;
                          addProgramming.delivery_date = e.target.value;
                          this.setState({ addProgramming });
                        }}
                      />
                    </div>
                    <div
                      className={` ${IndividualStyles.ItemTiny_cantidad} ${IndividualStyles.inputMarginleft}`}
                    >
                      <p className={` ${IndividualStyles.crudModalLabel}`}>Cantidad</p>
                      <input
                        className={`${IndividualStyles.registerInputsGris}`}
                        name='qty'
                        type='number'
                        min={1}
                        value={this.state.addProgramming?.qty_article}
                        onChange={e => {
                          const value = e.target.value;
                          let { addProgramming } = this.state;
                          addProgramming.qty_article = e.target.value;
                          if (value < 1) {
                            responseSwal({
                              icon: 'error',
                              title: 'Error',
                              text: 'Cantidad inválida',
                            });
                            addProgramming.qty_article = '';
                          }
                          this.setState({ addProgramming });
                        }}
                      />
                    </div>
                    <div className={`${IndividualStyles.Aling} ${IndividualStyles.ItemTiny2}`}>
                      <p></p>
                      <img
                        src={Add}
                        alt='añadir'
                        className={`mt-2 ${IndividualStyles.size} ${IndividualStyles.btn} ${IndividualStyles.imagePadding2}`}
                        onClick={() => {
                          let { addArticle } = this.state;
                          if (!this.state.addProgramming.delivery_date) {
                            return responseSwal({
                              icon: 'error',
                              title: 'Error',
                              text: 'Fecha inválida',
                            });
                          }
                          if (!this.state.addProgramming.qty_article) {
                            return responseSwal({
                              icon: 'error',
                              title: 'Error',
                              text: 'Cantidad inválida',
                            });
                          }
                          if (
                            this.state.addProgramming.qty_article >
                            this.state.addArticle.qty_article
                          ) {
                            return responseSwal({
                              icon: 'error',
                              title: 'Error',
                              text: 'Cantidad inválida',
                            });
                          }
                          if (
                            parseInt(this.state.addProgramming.qty_article) > parseInt(renderRest)
                          ) {
                            return responseSwal({
                              icon: 'error',
                              title: 'Error',
                              text: 'Cantidad inválida',
                            });
                          } else {
                            addArticle.deliveries.push(
                              deepcopy({
                                ...this.state.addProgramming,
                                created_by: this.props.user_data.id,
                              }),
                            );
                            this.setState({ addArticle });
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`${IndividualStyles.container3} ${IndividualStyles.inputMarginleft_new}`}
                >
                  <GenericTableNew
                    dark={true}
                    headers={[
                      <th key={`reqTH1`}>#</th>,
                      <th key={`reqTH2`}>Fecha</th>,
                      <th key={`reqTH3`}>Cantidad</th>,
                    ]}
                  >
                    {this.state.addArticle?.deliveries?.map((prog, index) => {
                      return (
                        <tr key={`prog-${index}`}>
                          <td>{index + 1}</td>
                          <td>{convertDateToLatinFormat(prog.delivery_date)}</td>
                          <td>{prog.qty_article}</td>
                        </tr>
                      );
                    })}
                  </GenericTableNew>

                  <div className={IndividualStyles.bottom}></div>
                </div>
                <div className='d-flex col-sm-2'>
                  <div
                    className={`${IndividualStyles.inputMarginleft} ${IndividualStyles.inputAlling}`}
                  >
                    <p className={tableStyles.crudModalLabel}>Restante</p>
                    <input
                      className={`${IndividualStyles.registerInputsBlue}`}
                      name='auction_title'
                      type='text'
                      disabled={true}
                      value={renderRest ? renderRest : '0'}
                    />
                  </div>
                </div>
              </ModalNew>

              <ModalNew
                title='Artículos'
                subtitle={this.state.addAgreement?.name}
                size='550'
                show={this.state.modalAddArticle}
                onHide={() =>
                  this.setState({
                    ...this.state,
                    modalAddArticle: false,
                    listArticlesAgreement: [],
                  })
                }
                btnYesName='Agregar'
                btnYesDisabled={
                  this.state.listArticlesAgreement?.filter(x => x.check === true).length > 0
                    ? false
                    : true
                }
                btnYesEvent={() => this.addArticleToOrder()}
              >
                <div className='d-flex'>
                  <input
                    className={style.selectInput}
                    type='text'
                    name='search_article'
                    placeholder='   Filtrar...'
                    onChange={e => {
                      this.setState({
                        ...this.state,
                        search_article: e.target.value,
                      });
                    }}
                  />
                  <img
                    src={lupaClear}
                    alt='Buscar artículo'
                    width={24}
                    className='mx-2'
                    onClick={() => {
                      let {
                        listArticlesAgreement,
                        search_article,
                        addAgreement,
                        addPurchaseOrder,
                      } = this.state;
                      if (
                        Array.isArray(listArticlesAgreement) &&
                        listArticlesAgreement.length > 0
                      ) {
                        if (search_article !== '') {
                          listArticlesAgreement =
                            addAgreement?.quotation_ref?.quotation_article_ref.filter(
                              x =>
                                x.article_ref?.description
                                  .toLowerCase()
                                  .includes(search_article.toLowerCase()) ||
                                String(x.id_article).includes(search_article),
                            );
                        } else {
                          listArticlesAgreement = deepcopy(
                            addAgreement?.quotation_ref?.quotation_article_ref,
                          );
                        }
                        for (let i = 0; i < listArticlesAgreement.length; i++) {
                          let artAgreement = listArticlesAgreement[i];
                          for (let j = 0; j < addPurchaseOrder.articles.length; j++) {
                            let artPurchase = addPurchaseOrder.articles[j];
                            if (artPurchase.id_article === artAgreement.id_article) {
                              artAgreement.check = true;
                            }
                          }
                        }
                        this.setState({ listArticlesAgreement });
                      }
                    }}
                  />
                </div>
                <GenericTableNew
                  headers={[
                    <th key={1} className='text-center px-1'>
                      Código
                    </th>,
                    <th key={2} className='text-center px-1'>
                      Nombre
                    </th>,
                    <th key={3} className='text-center px-1'>
                      <input
                        type='checkbox'
                        className='form-check-input clearBlue'
                        style={{ width: '16px', height: '16px' }}
                        name='checkAll'
                        onChange={e => {
                          let { listArticlesAgreement } = this.state;
                          listArticlesAgreement.forEach(item => {
                            item.check = e.target.checked;
                          });
                          this.setState({ listArticlesAgreement });
                        }}
                        checked={
                          this.state.listArticlesAgreement.filter(x => !!x.check).length ===
                          this.state.listArticlesAgreement.length
                            ? true
                            : false
                        }
                      />
                    </th>,
                  ]}
                >
                  {this.state.listArticlesAgreement
                    .slice(
                      this.state.filtersArticles.page * this.state.filtersArticles.perpage -
                        this.state.filtersArticles.perpage,
                      this.state.filtersArticles.page * this.state.filtersArticles.perpage,
                    )
                    .map((article, ind) => {
                      return (
                        <tr key={ind}>
                          <td>{article?.id_article}</td>
                          <td
                            className='text-start text-wrap text-break'
                            style={{ width: '18.75rem' }}
                          >
                            {article?.article_ref.description}
                          </td>
                          <td>
                            <input
                              type='checkbox'
                              className='form-check-input border-clear-blue p-1'
                              style={{ width: '1rem', height: '1rem' }}
                              onChange={() => {
                                let { listArticlesAgreement } = this.state;
                                article.check = !article.check;
                                this.setState({ listArticlesAgreement });
                              }}
                              checked={article.check}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </GenericTableNew>
                <div className={`${paginationStyles.wrapper} justify-content-end mt-2`}>
                  <Pagination
                    activePage={this.state.filtersArticles.page}
                    itemsCountPerPage={this.state.filtersArticles.perpage}
                    totalItemsCount={this.state.listArticlesAgreement.length}
                    pageRangeDisplayed={4}
                    activeClass={paginationStyles.activeClass}
                    onChange={this.handleChangePageArticles.bind(this)}
                    itemClassPrev={`${paginationStyles.itemClassPrev} ${paginationStyles.iconClear}`}
                    itemClassNext={`${paginationStyles.itemClassNext} ${paginationStyles.iconClear}`}
                    itemClassFirst={`${paginationStyles.itemClassFirst} ${paginationStyles.iconClear}`}
                    itemClassLast={`${paginationStyles.itemClassLast} ${paginationStyles.iconClear}`}
                    itemClass={paginationStyles.itemClass}
                  />
                </div>
              </ModalNew>

              {/* -------------------------------- END MODAL PROGRAMMING --------------------------------*/}
            </div>
            <div className='d-flex justify-content-end my-4'>
              <button
                className='btnOutlineClearBlue mx-2'
                onClick={() => this.setState({ ...this.state, addPurchaseOrder: {} })}
              >
                Cancelar
              </button>
              <button
                className='btn btn-primary'
                onClick={() =>
                  this.props.createPurchaseOrderGeneric(
                    {
                      ...this.state.addPurchaseOrder,
                      account: this.props.currentAccount.id,
                      user_created: this.props.user_data.id,
                      partial_deliveries: this.state.partial_deliveries,
                    },
                    () => this.setState(this.initialState),
                  )
                }
                disabled={
                  this.state.addPurchaseOrder?.provider !== '' &&
                  this.state.addPurchaseOrder?.agreement !== '' &&
                  this.state.addPurchaseOrder?.pay_conditions !== '' &&
                  this.state.addPurchaseOrder?.id_warehouse !== '' &&
                  this.state.addPurchaseOrder?.delivery_date !== '' &&
                  this.state.addPurchaseOrder?.terms !== '' &&
                  this.state.addPurchaseOrder?.articles?.length > 0
                    ? false
                    : true
                }
              >
                Generar orden
              </button>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuth: state.loginReducer.Authenticated,
  currentAccount: state.loginReducer.currentAccount,
  user_data: state.loginReducer.user_data,
  providers: state.providerReducer.providers,
  agreements: state.agreementReducer.agreements,
  row_total_agreements: state.agreementReducer.row_total,
  agreementDetails: state.agreementReducer.agreementDetails,
  loading: state.purchaseOrderReducer.loading,
  payConditions: state.purchaseOrderReducer.payConditions,
  listStorage: state.storageReducer.list,
});

const mapDispatchToProps = dispatch => ({
  getProviders: params => {
    dispatch(getProviders(params));
  },
  getAgreements: params => {
    dispatch(getAgreements(params));
  },
  getOneAgreement: (params, doAfter) => {
    dispatch(getOneAgreement(params, doAfter));
  },
  getPayConditions: params => {
    dispatch(getPayConditions(params));
  },
  storage_get: params => {
    dispatch(storage_get(params));
  },
  createPurchaseOrderGeneric: (data, doAfter) => {
    dispatch(createPurchaseOrderGeneric(data, doAfter));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrder);
