import { URL_GATEWAY, API_VERSION } from '../helpers/constants';
import { convertFilterToString } from '../helpers/convertToFilter';

import {
  GET_PETTY_CASH,
  GET_LIST_USERS,
  GET_CONFIGURATION_PARAMS,
  GET_PAYROLL_PARAMS,
} from './actionTypes';
import { customSwaltAlert } from '../helpers/customSwaltAlert';

export const getListPettyCash = objFilters => (dispatch, getCreate) => {
  dispatch({
    type: GET_PETTY_CASH,
    loading: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/pettyCash/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, total } = res;
      if (results?.length === 0) {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'No hay registros para mostrar',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
      dispatch({
        type: GET_PETTY_CASH,
        payload: results,
        total: total,
        loading: false,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_PETTY_CASH,
        payload: [],
        total: 0,
        loading: false,
      });
      console.error(err.message);
    });
};

export const listUsers = objFilters => (dispatch, getCreate) => {
  dispatch({
    type: GET_LIST_USERS,
    loading: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/users/byAccount/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, total } = res;
      dispatch({
        type: GET_LIST_USERS,
        payload: results,
        total: total,
        loading: false,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_LIST_USERS,
        payload: [],
        total: 0,
        loading: false,
      });
      console.error(err.message);
    });
};

export const getAllconfigParams =
  (objFilters, withOutLoading = true) =>
  (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    const filters = convertFilterToString(objFilters);

    if (withOutLoading) {
      dispatch({
        type: GET_CONFIGURATION_PARAMS,
        loading: true,
      });
    }

    fetch(`${URL_GATEWAY}${API_VERSION}/accounting/accountParameter/?${filters}`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        Authorization: token,
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(res => {
        dispatch({
          type: GET_CONFIGURATION_PARAMS,
          payload: res,
          loading: false,
        });
      })
      .catch(error => {
        dispatch({
          type: GET_CONFIGURATION_PARAMS,
          payload: [],
          loading: false,
        });
        return console.error(error.message);
      });
  };

export const getPayrollParams = objFilters => (dispatch, getState) => {
  dispatch({
    type: GET_PAYROLL_PARAMS,
    loading: true,
    rowTotal: 0,
  });
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/params?${filters}`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: GET_PAYROLL_PARAMS,
        payload: res.results,
        loading: false,
        rowTotal: res.rowTotal,
      });
      if (!res.success) {
        dispatch({
          type: GET_PAYROLL_PARAMS,
          loading: false,
          rowTotal: 0,
        });
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: res.message,
          showCancelButton: false,
        });
      }
    })
    .catch(error => {
      dispatch({
        type: GET_PAYROLL_PARAMS,
        loading: false,
        rowTotal: 0,
      });
      console.error(error.message);
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: error.message,
        showCancelButton: false,
      });
    });
};

export const updatePayrrolParam = async (data, token) => {
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/payroll/params`, {
      method: 'PUT',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    return result;
  } catch (error) {
    console.error(error.message);
    return customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: error.message,
      showCancelButton: false,
    });
  }
};
export const createNewPayrollParam = async (data, token) => {
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/payroll/params`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    return result;
  } catch (error) {
    console.error(error.message);
    return customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: error.message,
      showCancelButton: false,
    });
  }
};
