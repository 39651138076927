import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
import { message } from "../helpers/helpers";

export const sendEachParam = async (data, token) => {
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/accounting/accountParameter/`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await query.json();
    return result;
  } catch (error) {
    //console.log("Ha ocurrido un error al cargar la información");
    return message("error", "Error", "Ha ocurrido un error");
  }
};
export const removeParam = async (data, token) => {
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/accounting/accountParameter/`,
      {
        method: "DELETE",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await query.json();
    return result;
  } catch (error) {
    //console.log("Ha ocurrido un error al cargar la información");
    return message("error", "Error", "Ha ocurrido un error");
  }
};
