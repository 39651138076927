import { useState, useEffect, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ReactSelect from 'react-select';
import NumberFormat from 'react-number-format';
import { KeyboardArrowDown } from '@material-ui/icons';
import { Accordion } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { createCollectionLetter } from '../../actions/collectionActions';
import { useGetMethod } from '../../Hooks';
import { customSwaltAlert } from '../../helpers';

import { Title } from '../../shared/Title';
import LetterEditor from '../LetterEditor/LetterEditor';
import LetterPreview from './LetterPreview';

import { customSelectNewDark } from '../Layouts/react-select-custom';
import tableStyles from '../Layouts/tableStyle.module.scss';
import { getLocaleSeparators } from '../../helpers/numberFormatting';

const LETTER_VARIABLES = [
  '[DD]',
  '[MM]',
  '[AAAA]',
  '[CLIENTE]',
  '[TABLA_FACTURAS]',
  '[NOMBRE_FIRMA]',
  '[CARGO_FIRMA]',
];

const LABELS = {
  '[DD]': 'Día actual',
  '[MM]': 'Mes actual',
  '[AAAA]': 'Año actual',
  '[CLIENTE]': 'Cliente',
  '[TABLA_FACTURAS]': 'Tabla facturas',
  '[NOMBRE_FIRMA]': 'Nombre',
  '[CARGO_FIRMA]': 'Cargo',
};

const localeSeparators = getLocaleSeparators();

function CollectionCreate() {
  const dispatch = useDispatch();
  const store = useSelector(store => store);
  const history = useHistory();

  const initialState = {
    id: undefined,
    createdBy: store.loginReducer.user_data.id,
    eaccount: store.loginReducer.currentAccount.id,
    collectionType: '',
    name: '',
    frecuency: undefined,
    sendTime: '',
    header: '',
    greetings: '',
    subject: '',
    body: '',
    goodBye: '',
    signature: '',
    role: '',
    status: '',
    responsibleName: '',
  };

  const [state, setState] = useState(initialState);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const { trigger: getPaymentTypes, results: paymentTypes } = useGetMethod();

  useEffect(() => {
    async function fetchAsyncData() {
      await getPaymentTypes({
        url: '/receivable/collectionTypes/',
        objFilters: {
          page: 1,
          perpage: 99,
          status: '1',
          eaccount: store.loginReducer.currentAccount.id,
          createdBy: store.loginReducer.user_data.id,
        },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
  }, [
    store.loginReducer.user_data.id,
    store.loginReducer.currentAccount.id,
    store.loginReducer.Authorization,
    getPaymentTypes,
  ]);

  const myPermission = () =>
    store.loginReducer.currentAccount?.profile?.permission?.find(
      x => x.functionality?.prefix === 'collectLet',
    );

  const validatePayload = () => {
    if (
      !state.collectionType ||
      !state.name ||
      (state.collectionType === 'persuasive' && !state.frecuency) ||
      !state.sendTime ||
      !state.header ||
      !state.greetings ||
      !state.subject ||
      !state.body ||
      !state.goodBye
    ) {
      return false;
    }
    return true;
  };

  const generateTimeOptions = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const formattedHour = hour.toString().padStart(2, '0');
        const formattedMinute = minute.toString().padStart(2, '0');
        const timeString = `${formattedHour}:${formattedMinute}`;
        options.push(
          <option key={timeString} value={timeString}>
            {formattedHour}:{formattedMinute}
          </option>,
        );
      }
    }
    return options;
  };

  const handleSubmit = () => {
    const isValid = validatePayload();

    if (!isValid) {
      customSwaltAlert({
        showCancelButton: false,
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Por favor, diligencie todos los campos marcados como obligatorios.',
        confirmButtonText: 'Aceptar',
      });
      return;
    }
    dispatch(
      createCollectionLetter(state, () => {
        setState(initialState);
        history.push('/administracion/TMDeCartera/CartasCobro');
      }),
    );
  };

  const formattedPaymentTypesList = useMemo(
    () =>
      paymentTypes.results?.map(
        item =>
          ({
            label: item.name,
            value: item.tag,
            role: item.role,
            name: item.responsibleName,
            body: item.letterBody,
          }) || [],
      ),
    [paymentTypes.results],
  );

  const joinedLetter = useMemo(
    () =>
      state.header + state.greetings + state.subject + state.body + state.goodBye + state.signature,
    [state.header, state.greetings, state.subject, state.body, state.goodBye, state.signature],
  );

  const onChangeTemplate = result => {
    setState({ ...state, ...result });
  };

  const renderHeader = () => {
    return (
      <div>
        <div
          className='card p-3 mt-4 border-0'
          style={{ borderRadius: 10, backgroundColor: '#F5F7FA' }}
        >
          <div className='row align-items-end'>
            <div className='col'>
              <small className='text-primary'>
                Tipo de cobro<span className='text-danger'>*</span>
              </small>
              <ReactSelect
                noOptionsMessage={() => 'No hay datos'}
                styles={customSelectNewDark}
                placeholder='Seleccionar...'
                value={formattedPaymentTypesList?.find(item => item.value === state.collectionType)}
                onChange={option => {
                  setState(state => ({
                    ...state,
                    collectionType: option.value,
                    role: option.role,
                    responsibleName: option.name,
                    body: option.body,
                  }));
                }}
                options={formattedPaymentTypesList}
              />
            </div>

            <div className='col-lg-4'>
              <small className='text-primary'>
                Nombre carta de cobro<span className='text-danger'>*</span>
              </small>
              <input
                type='text'
                className='register-inputs'
                placeholder='Escribir el título...'
                value={state.name}
                onChange={({ target }) => setState(state => ({ ...state, name: target.value }))}
              />
            </div>

            <div className='col'>
              <small className='text-primary'>
                Frecuencia (días)<span className='text-danger'>*</span>
              </small>
              <NumberFormat
                allowNegative={false}
                thousandSeparator={localeSeparators.groupSeparator}
                decimalSeparator={localeSeparators.decimalSeparator}
                isNumericString
                value={String(state.frecuency ?? '')}
                onValueChange={({ value }) =>
                  setState(state => ({
                    ...state,
                    frecuency: state.collectionType !== 'persuasive' ? '' : value,
                  }))
                }
                className='register-inputs'
                disabled={state.collectionType !== 'persuasive'}
                placeholder='Escribir No. de días...'
              />
            </div>

            <div className='col'>
              <small className='text-primary'>
                Hora de envío<span className='text-danger'>*</span>
              </small>
              <select
                className='register-inputs'
                value={state.sendTime}
                onChange={({ target }) => setState(state => ({ ...state, sendTime: target.value }))}
                disabled={state?.collectionType !== 'persuasive'}
              >
                {generateTimeOptions()}
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={tableStyles.tlnContainer}>
        <div className={`${tableStyles.tableArea}`} style={{ marginTop: 60 }}>
          {!myPermission()?.create && <Redirect to='/administracion/inicio' />}

          <Title
            title='Crear carta de cobro'
            className={'mb-4'}
            onClickIcon={() => {
              history.push('/administracion/TMDeCartera/CartasCobro');
            }}
          />

          {/* inputs ------- 🔥  */}
          {renderHeader()}

          <Accordion>
            <div className='card p-3 my-4 border-0 shadow' style={{ borderRadius: 10 }}>
              <Accordion.Toggle
                className='d-flex align-items-left fw-bold text-primary pointer'
                eventKey='0'
              >
                <KeyboardArrowDown />
                <span className='ms-1'>Encabezado de la carta</span>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey='0'>
                <LetterEditor
                  onGetResult={result => onChangeTemplate(result)}
                  name='header'
                  variables={LETTER_VARIABLES}
                  labels={LABELS}
                />
              </Accordion.Collapse>
            </div>
          </Accordion>

          <Accordion>
            <div className='card p-3 my-4 border-0 shadow' style={{ borderRadius: 10 }}>
              <Accordion.Toggle
                className='d-flex align-items-left fw-bold text-primary pointer'
                eventKey='1'
              >
                <KeyboardArrowDown />
                <span className='ms-1'>Saludo</span>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey='1'>
                <LetterEditor
                  onGetResult={result => onChangeTemplate(result)}
                  name='greetings'
                  variables={LETTER_VARIABLES}
                  labels={LABELS}
                />
              </Accordion.Collapse>
            </div>
          </Accordion>

          <Accordion>
            <div className='card p-3 my-4 border-0 shadow' style={{ borderRadius: 10 }}>
              <Accordion.Toggle
                className='d-flex align-items-left fw-bold text-primary pointer'
                eventKey='2'
              >
                <KeyboardArrowDown />
                <span className='ms-1'>Asunto</span>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey='2'>
                <LetterEditor
                  onGetResult={result => onChangeTemplate(result)}
                  name='subject'
                  variables={LETTER_VARIABLES}
                  labels={LABELS}
                />
              </Accordion.Collapse>
            </div>
          </Accordion>

          <Accordion>
            <div className='card p-3 my-4 border-0 shadow' style={{ borderRadius: 10 }}>
              <Accordion.Toggle
                className='d-flex align-items-left fw-bold text-primary pointer'
                eventKey='3'
              >
                <KeyboardArrowDown />
                <span className='ms-1'>Cuerpo de la carta</span>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey='3'>
                <LetterEditor
                  onGetResult={result => onChangeTemplate(result)}
                  name='body'
                  defaultValue={state.body}
                  variables={LETTER_VARIABLES}
                  labels={LABELS}
                />
              </Accordion.Collapse>
            </div>
          </Accordion>

          <Accordion>
            <div className='card p-3 my-4 border-0 shadow' style={{ borderRadius: 10 }}>
              <Accordion.Toggle
                className='d-flex align-items-left fw-bold text-primary pointer'
                eventKey='4'
              >
                <KeyboardArrowDown />
                <span className='ms-1'>Despedida</span>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey='4'>
                <LetterEditor
                  onGetResult={result => onChangeTemplate(result)}
                  name='goodBye'
                  variables={LETTER_VARIABLES}
                  labels={LABELS}
                />
              </Accordion.Collapse>
            </div>
          </Accordion>

          <Accordion>
            <div className='card p-3 my-4 border-0 shadow' style={{ borderRadius: 10 }}>
              <Accordion.Toggle
                className='d-flex align-items-left fw-bold text-primary pointer'
                eventKey='5'
              >
                <KeyboardArrowDown />
                <span className='ms-1'>Firma</span>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey='5'>
                <LetterEditor
                  onGetResult={result => onChangeTemplate(result)}
                  name='signature'
                  variables={LETTER_VARIABLES}
                  labels={LABELS}
                />
              </Accordion.Collapse>
            </div>
          </Accordion>

          <div className='d-flex justify-content-end mb-5'>
            <button
              onClick={() => history.goBack()}
              className={`${tableStyles.btnSecondary} py-2 me-3`}
            >
              Cancelar
            </button>
            <button
              className={`${tableStyles.btnPrimary} py-2 me-3`}
              onClick={() => handleSubmit()}
            >
              Guardar
            </button>
            <button
              className={`${tableStyles.btnPrimaryAlt} py-2`}
              onClick={() => setIsPreviewModalOpen(true)}
            >
              Previsualizar
            </button>
          </div>
        </div>
      </div>
      {joinedLetter && (
        <LetterPreview
          isOpen={isPreviewModalOpen}
          content={joinedLetter}
          onClose={() => setIsPreviewModalOpen(false)}
        />
      )}
    </>
  );
}

export default CollectionCreate;
