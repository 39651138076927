
import {
  GET_PETTY_CASH,
  GET_LIST_USERS,
  GET_CONFIGURATION_PARAMS,
  GET_PAYROLL_PARAMS,
} from "../actions/actionTypes";

const initialState = {
  listReteica: [],
  listTypesExpense: [],
  listReteicaRate: [],
  listReteica_total: 0,
  listPettyCash: [],
  listEmployees: [],
  listJournalType: [],
  listOnePettyCah: [],
  listRepayment: [],
  listRepayment_loading: false,
  detailExpense: [],
  listPettyCashExpenses: [],
  listExpenseForRepayment: [],
  detailRepayment: [],
  detailOneExpense: [],
  pettyCashPermission: [],
  onePettyCashPermission: [],
  listUsers: [],
  paramConfigList: [],
  listUser_total: 0,
  listExpenseForRepayment_loading: false,
  reteica_loading: false,
  pettyC_loading: false,
  detailExpense_loading: false,
  expense_loading: false,
  permission_loading: false,
  loading: false,
  listUsers_loading: false,
  repayment_loading: false,
  payrollParamList: [],
  ppLoading: false,
  rowTotal: 0,
};

export const accountingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PETTY_CASH:
      return {
        ...state,
        listPettyCash: action.payload,
        pettyC_loading: action.loading,
      };
    case GET_LIST_USERS:
      return {
        ...state,
        listUsers: action.payload,
        listUser_total: action.total,
        listUsers_loading: action.loading,
      };
    case GET_CONFIGURATION_PARAMS:
      return {
        ...state,
        paramConfigList: action.payload,
        loading: action.loading,
      };
    case GET_PAYROLL_PARAMS:
      return {
        ...state,
        payrollParamList: action.payload,
        ppLoading: action.loading,
        rowTotal: action.rowTotal,
      };
    default:
      return state;
  }
};
