import {CUPS_FILTER} from "../actions/actionTypes.js";

const initialState ={
    cups: [],
    total: 0
}


export const cupsReducer = (state = initialState, action) => {
    switch(action.type){
        case CUPS_FILTER:
            return {
            ...state,
                cups: action.results,
                total: action.row_search
            };

        default:
            return state;
    }

}
