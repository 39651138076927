
import {
    GET_PAY_CONDITIONS,

}   from "../actions/actionTypes";

const initialState = {
    companies:[],
    contracts:[],


    acountable:[],
    one_company:[],
    services:[],

    billing_history:[],
    billing_history_loading: false,
    billing_history_total: 0,

    receipt_record: [],
    receipt_record_loading: false,
    receipt_record_total: 0,

    billing_history_status: [],
    invoice_types:[],

    contracts_total:0,

    sites:[],
    one_contract:[],
    invoices_types:[],
    invoices_to_send:[],
    invoices_to_send_total:0,
    objection_types:[],
    info_notes:[],
    note_types:[],
    objected_by_client:[],
    status_in_history:[],
    gloss_history:[],
    gloss_history_total:0,
    gloss_history_detail:[],
    get_types_services:[],
    status:[],
    services_ADM:[],
    services_ADM_total:0,
    iva:[],
    one_service:[],
    types_contracts:[],
    regions:[],
    ciuu:[],
    pay_conditions:[],
    pay_methods:[],
    divisa:[],
    one_contract_adm:[],
    cups:[],
    causes:[],
    loading_generate_invoice:false,
    loading_generate_caption:false,
    loading_send_invoices: false,
    loading_gloss_history: false,
    loading_gloss_history_det: false,
    loading_create_invoice: false,
    invoices_to_send_loading: false

};
export const billingReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PAY_CONDITIONS:
            return {
                ...state,
                pay_conditions: action.payload,
            };
        default:
            return state;
    }
};

