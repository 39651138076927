import { combineReducers } from "redux";
import { loginReducer } from "./loginReducer";
import { classificationReducer } from "./classificationReducer";
import { profileReducer } from "./profileReducer";
import { userReducer } from "./userReducer";
import { headerReducer } from "./headerReducer";
import { siteReducer } from "./siteReducer";
import { cietenReducer } from "./cietenReducer";
import { cuentasReducer } from "./cuentasReducer";
import { locationReducer } from "./locationReducer";
import { functionalReducer } from "./functionalReducer";
import { costcenterReducer } from "./costcenterReducer";
import { measurementUnitsReducer } from "./measurementUnitsReducer";
import { storageReducer } from "./storageReducer";
import { pucReducer } from "./pucReducer";
import { consumptionCenterReducer } from "./consumptionCenterReducer";
import { articleReducer } from "./articleReducer";
import { requisitionReducer } from "./requisitionReducer";
import { individualAuctionReducer } from "./individualAuctionReducer";
import { providerReducer } from "./providerReducer";
import { specialitiesReducer } from "./specialityReducer";
import { subspecialitiesReducer } from "./subspecialityReducer";
import { inventoryFamilyReducer } from "./inventoryFamilyReducer";
import { cupsReducer } from "./cupsReducer";
import { corporateclientsReducer } from "./corporateclientsReducer";
import { listNiifReducer } from "./listNiifReducer";
import { invoiceReducer } from "./invoiceReducer";
import { salidaConsumoReducer } from "./salidaConsumoReducer";
import { statusPOReducer } from "./statusPOReducer";
import { entryWarehouseReducer } from "./entryWarehouseReducer";
import { agreementReducer } from "./agreementReducer";
import { purchaseOrderReducer } from "./purchaseOrderReducer";
import { inventoryReducer } from "./inventoryReducer";
import { deductionsAccrualsReducer } from "./deductionsAccrualsReducer";
import { notesReducer } from "./notesReducer";
import { billingReducer } from "./billingReducer";
import { accountingReducer } from "./accountingReducer";
import { tmPayRollReducer } from "./tmPayRollReducer";
import { workEnvReducer } from "./workEnvReducer";
import { movementsReducer } from "./movementsReducer";
import { managementReducer } from "./managementReducer";
import { populationReducer } from "./populationReducer";
import { telemedicineReducer } from "./telemedicineReducer";
import { collectionReducer } from './collectionReducer';
import { collectionGetOneReducer } from './collectionGetOneReducer';
import { companyTypeReducer } from './companyTypeReducer';
import { payHistoryReducer } from "./payHistoryReducer";


export default combineReducers({
  companyTypeReducer,
  collectionGetOneReducer,
  salidaConsumoReducer,
  purchaseOrderReducer,
  collectionReducer,
  storageReducer,
  loginReducer,
  headerReducer,
  listNiifReducer,
  userReducer,
  profileReducer,
  siteReducer,
  cuentasReducer,
  cietenReducer,
  functionalReducer,
  costcenterReducer,
  locationReducer,
  measurementUnitsReducer,
  pucReducer,
  inventoryFamilyReducer,
  consumptionCenterReducer,
  articleReducer,
  requisitionReducer,
  cupsReducer,
  individualAuctionReducer,
  providerReducer,
  corporateclientsReducer,
  billingReducer,
  statusPOReducer,
  agreementReducer,
  entryWarehouseReducer,
  specialitiesReducer,
  subspecialitiesReducer,
  classificationReducer,
  invoiceReducer,
  movementsReducer,
  workEnvReducer,
  inventoryReducer,
  notesReducer,
  accountingReducer,
  deductionsAccrualsReducer,
  tmPayRollReducer,
  managementReducer,
  populationReducer,
  telemedicineReducer,
  payHistoryReducer

});
