import { URL_GATEWAY, API_VERSION } from '../helpers/constants';
import { customSwaltAlert } from '../helpers/customSwaltAlert';

export const newParemAgesByWallet = async (data, token) => {
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/receivable/expirationPeriods/`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    return result;
  } catch (error) {
    console.error(error.message);
    return customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: error.message,
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
    });
  }
};
export const updateParemAgesByWallet = async (data, token) => {
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/receivable/expirationPeriods/`, {
      method: 'PUT',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    return result;
  } catch (error) {
    console.error(error.message);
    return customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: error.message,
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
    });
  }
};
