import { Component } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Back from '../../assets/img/icons/iconBack.svg';
import style from './providers.module.scss';
import CheckMark from '../../assets/img/icons/CheckMark.svg';
import DownArrow from '../../assets/img/icons/DownArrow.svg';
import Bill from '../../assets/img/icons/Bill.svg';
import searchFileIcon from '../../assets/img/icons/searchFileIcon.svg';
import pdfIcon from '../../assets/img/icons/pdfIcon.svg';
import littleClose from '../../assets/img/icons/littleClose.svg';
import blueExcelIcon from '../../assets/img/icons/blueExcelIcon.svg';
import wordIconBlue from '../../assets/img/icons/wordIconBlue.svg';
import grayAdd from '../../assets/img/icons/grayAdd.svg';
import Imagen from '../../assets/img/icons/Imagen.svg';
import Menos from '../../assets/img/icons/Menos.svg';
import ModalCreateRecord from '../Layouts/ModalNew';
import NumberFormat from 'react-number-format';
import {
  FILE_ACCEPT_PROVIDER,
  MAX_SIZE_FILE,
  MULTIPLE_REGEX_VALID_FILE,
  REGEX_VALID_EXCEL_TYPE,
  REGEX_VALID_PDF_TYPE,
  REGEX_VALID_WORD_TYPE,
  CompantyTypes,
  SocietyTypes,
  ProviderType,
  REGEX_IMAGES,
  ACCEPT_IMAGES,
} from '../../helpers/constants';
import { responseSwal } from '../../helpers/responseSwal';
import {
  createDraft,
  getCountries,
  getCIIU,
  getProviderClassifications,
  getBankingEntities,
  getTaxPayerType,
  getLisLaboratories,
  getListTypeServices,
  getServiceByIdProvider,
  getLaboratoryByIdProvider,
} from '../../actions/providersActions';
import {
  generalInformationCheckArray,
  financialInformationCheckArray,
  supportFilesArray,
  legalRepresentArray,
  naturalPersonOnLglReprsent,
  legalPersonOnLglReprsent,
  requiredFieldsBankInfo,
  requiredFieldExtraForeignCurrency,
  requiredFieldsExtraCommercialReferences,
} from './constansProvider';
import {
  city_get,
  province_get,
  province_get_all,
  city_get_all,
} from '../../actions/locationActions';
import Loader from 'react-loader-spinner';
import { nitCalculate } from '../../helpers/nitCalculate';
import { validateEmail, isEmptyOrUndefined } from '../../helpers/helpers';
import Switch from 'react-switch';
import Dropzone from 'react-dropzone';
import { MultiSelect } from '../../shared';
import { customSwaltAlert, formatteDocument, getEnvs } from '../../helpers';
import { getLocaleSeparators } from '../../helpers/numberFormatting';

const { CURRENCY } = getEnvs();

class FormLegalPerson extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.localeSeparators = getLocaleSeparators();
  }
  get initialState() {
    var curr = new Date();
    var date = curr.toISOString().substr(0, 10);
    return {
      trigger: false,
      initialServicesTrigger: false,
      initialLaboratoriesTrigger: false,
      collapsedItem: true,
      collapsedItemGeneralInformation: true,
      collapsedItemFinancialInformation: true,
      collapsedItemLegRepresentInformation: true,
      collapsedItemBankInformation: true,
      collapsedItemDeclarations: true,
      collapsedItemSignature: true,
      showObservationModal: false,
      modalExposePeople: false,
      modalConsultBackground: false,
      modalVerifyInfoDoc: false,
      modalVisitRecord: false,
      modalProcessPersonalData: false,
      checkRequiredDocs: false,
      checkGeneralInformation: false,
      checkLegalRepresent: false,
      checkFinancialInformation: false,
      checkBankInformation: false,
      checkDeclarations: false,
      checkSignature: false,
      allowDraft: false,
      allowSent: false,
      redirectBack: null,
      addActivity: 0,
      addAccount: 0,
      addBankProduct: 0,
      addShareholder: 0,
      addComercialReference: 0,
      showOtherSociety: false,
      allProvinces: this.props.allProvinces,
      allCities: this.props.allCities,
      addSupports: {
        // general information fields
        dilig_date: date,
        name: '',
        nit: '',
        verDigit: '',
        company_type: '',
        society_type: '',
        other_society: '',
        provider_type: 'legal_person',
        email: '',
        ciiu: '',
        classification: '',

        // general information / principal office
        country: '',
        province: '',
        city: '',
        address: '',
        phone: '',
        cellphone: '',

        // general information / alternative office
        al_country: '',
        al_province_lgl_rpst: '',
        al_city_lgl_rpst: '',
        al_address_lgl_rpst: '',
        al_phone_lgl_rpst: '',
        al_cellphone: '',
        autoRetainer: false,
        taxManager: false,

        //info service provider
        checkServiceProvider: false,
        optionSelectedTypeServices: [],
        optionSelectedLaboratories: [],
        listTypeServices: undefined,
        listLaboratories: undefined,

        // general information / alternative office
        id_ciiu: '',

        //Anothers activities 🕴
        extra_bussiness_activites: [
          // {ciiu: occupation:}
        ],

        extra_legal_representatives: [],

        // Legal represent
        hasAlternativeAddress: false,
        firstNameLglRepresent: '',
        secondNameLglRepresent: '',
        firstSurnameLglRepresent: '',
        secondSurnameLglRepresent: '',
        docTypeLglRepresent: '',
        docNumLglRepresent: '',
        expLocationLglRepresent: '',
        emailLglRepresent: '',
        phoneLglRepresent: '',
        cellphoneLglRepresent: '',
        personType_shareholder_rpst: '',
        fisrtNameShareholder_lgl_rpst: '',
        fisrtSurnameShareholder_lgl_rpst: '',
        docTypeShareholder_lgl_rpst: '',
        docNumShareholder_lgl_rpst: '',
        expLocationShareholder: '',
        // ... not all fields are declared because they will be added in the onchange event 👁 ⛳

        // Financial information
        totalAssets: null,
        totalPassives: null,
        patrimony: null,
        monthlyIncome: null,
        monthlyExpenses: null,
        otherIncome: null,
        otherIncomeConcept: '',
        cortDate: '',

        // Declarations sections
        publicRecognition: false,
        bondWithPEP: false,
        moneyLaundering: false,
        publicDegree: false,
        publicResources: false,
        inhibitoryList: false,

        authConsultBackground: true,
        authVerifyInfoDoc: true,
        authVisitRecord: true,
        authProcessPersonalData: true,

        // bank information
        bankAccountType: '',
        bankingEntity: '',
        accountNumber: '',
        accountHolder: '',
        countryBankInfo: '',
        provinceBankInfo: '',
        cityBankInfo: '',
        bankBranch: '',
        extra_bank_account: [],
        hasForeignCurrency: false,
        foreignBankingEntity: '',
        foreignBankingType: '',
        foreignNumberBankAccount: '',
        foreignBankAmount: '',
        foreignBankCountry: '',
        foreignBankCity: '',
        foreignCoin: '',
        extraForeignAccount: [],

        // referencias comerciales
        hasCommercialReferences: false,
        nameCommercialReference: '',
        phoneCommercialReference: '',
        cellphoneCommercialReference: '',
        emailCommercialReference: '',
        countryCommercialReference: '',
        provinceCommercialReference: '',
        cityCommercialReference: '',
        addressCommercialReference: '',
        supportFileCommercialReference: '',
        filenamesupportFileCommercialReference: '',
        fileTypesupportFileCommercialReference: pdfIcon,
        extraCommercialReferences: [],

        // signature
        docTypeSignature: '',
        docNumSignature: '',
        expLocationSignature: '',
        checkConstancySignature: '',
        signature: null,

        includeFile: false, // flag if include a one file at least 🏴‍☠️

        filenamechamberCommerce: '',
        chamberCommerce: null,
        fileTypechamberCommerce: pdfIcon,

        filenamerutPhotocopy: '',
        rutPhotocopy: null,
        fileTyperutPhotocopy: pdfIcon,

        filenamedocPhotocopy: '',
        docPhotocopy: null,
        fileTypedocPhotocopy: pdfIcon,

        filenamesocialSecurity: '',
        socialSecurity: null,
        fileTypesocialSecurity: pdfIcon,

        filenamerentDeclaration: '',
        rentDeclaration: null,
        fileTyperentDeclaration: pdfIcon,

        filenamebankAccount: '',
        bankAccount: null,
        fileTypebankAccount: pdfIcon,

        filenamedisciplinaryRecords: '',
        disciplinaryRecords: null,
        fileTypedisciplinaryRecords: pdfIcon,

        filenamefiscalResponsability: '',
        fiscalResponsability: null,
        fileTypefiscalResponsability: pdfIcon,
      },
    };
  }

  myPermission = () =>
    this.props.currentAccount?.profile?.permission?.find(x => x.functionality?.prefix === 'prov');

  // ANCHOR didmount
  componentDidMount() {
    this.props.city_get_all();
    this.props.province_get_all();
    this.props.getCIIU();
    this.props.getProviderClassifications({
      id_account: this.props.idAccount,
    });
    this.props.getBankingEntities();
    this.props.getCountries({ page: 1 });
    this.props.province_get({});
    this.props.city_get({});
    this.props.getTaxPayerType();
    this.props.getListTypeServices();
    this.props.getLaboratories();

    this.setState({
      ...this.state,
      trigger: true,
    });

    if (this.props.providerEdit) {
      let {
        addSupports,
        addShareholder,
        addActivity,
        addComercialReference,
        addBankProduct,
        addAccount,
      } = this.state;
      for (const key in this.props.providerEdit) {
        if (Object.hasOwnProperty.call(this.props.providerEdit, key)) {
          const element = this.props.providerEdit[key];
          for (const k in addSupports) {
            if (Object.hasOwnProperty.call(addSupports, k)) {
              if (key === k) {
                addSupports[k] = element;
              } else {
                addSupports[key] = element;
              }
              switch (key) {
                case 'al_country_lgl_rpst':
                  if (!!element && element !== 0) addSupports.hasAlternativeAddress = true;
                  break;
                case 'extra_bussiness_activites':
                  addActivity = element.length;
                  break;
                case 'extra_legal_representatives':
                  addShareholder = element.length;
                  break;
                default:
                  break;
              }
            }
          }
        }
        addAccount = this.props?.providerEdit?.extra_bank_account?.length;
        addBankProduct = this.props?.providerEdit?.extraForeignAccount?.length;
        addComercialReference = this.props?.providerEdit?.extraCommercialReferences?.length;
      }
      if (!!addSupports.support_provider && addSupports.support_provider.length > 0) {
        addSupports.chamberCommerce = addSupports.support_provider[0].commerce_certificate;
        addSupports.bankAccount = addSupports.support_provider[0].count_ctr;
        addSupports.disciplinaryRecords = addSupports.support_provider[0].ctr_discipline_background;
        addSupports.fiscalResponsability = addSupports.support_provider[0].ctr_fiscal_background;
        addSupports.docPhotocopy = addSupports.support_provider[0].doc_copy;
        addSupports.rentDeclaration = addSupports.support_provider[0].rent_declaration;
        addSupports.rutPhotocopy = addSupports.support_provider[0].rut;
        addSupports.socialSecurity = addSupports.support_provider[0].social_security;
        // file icons
        addSupports.fileTypechamberCommerce = this.getFileIcon(addSupports.chamberCommerce);
        addSupports.fileTypebankAccount = this.getFileIcon(addSupports.bankAccount);
        addSupports.fileTypedisciplinaryRecords = this.getFileIcon(addSupports.disciplinaryRecords);
        addSupports.fileTypefiscalResponsability = this.getFileIcon(
          addSupports.fiscalResponsability,
        );
        addSupports.fileTypedocPhotocopy = this.getFileIcon(addSupports.docPhotocopy);
        addSupports.fileTyperentDeclaration = this.getFileIcon(addSupports.rentDeclaration);
        addSupports.fileTyperutPhotocopy = this.getFileIcon(addSupports.rutPhotocopy);
        addSupports.fileTypesocialSecurity = this.getFileIcon(addSupports.socialSecurity);
        // filename
        addSupports.filenamechamberCommerce = addSupports.chamberCommerce;
        addSupports.filenamebankAccount = addSupports.bankAccount;
        addSupports.filenamedisciplinaryRecords = addSupports.disciplinaryRecords;
        addSupports.filenamefiscalResponsability = addSupports.fiscalResponsability;
        addSupports.filenamedocPhotocopy = addSupports.docPhotocopy;
        addSupports.filenamerentDeclaration = addSupports.rentDeclaration;
        addSupports.filenamerutPhotocopy = addSupports.rutPhotocopy;
        addSupports.filenamesocialSecurity = addSupports.socialSecurity;
      }
      let { showOtherSociety } = this.state;
      if (addSupports.society_type === 'other') {
        showOtherSociety = true;
      }
      if (addSupports.nit) {
        addSupports.verDigit = addSupports.nit.slice(-1);
        addSupports.nit = addSupports.nit.substring(0, addSupports.nit.length - 1);
      }
      this.setState({
        addSupports,
        addShareholder,
        addActivity,
        showOtherSociety,
        addComercialReference,
        addAccount,
        addBankProduct,
      });
    }
    if (!!this.props.providerEdit && this.props.providerEdit.al_country) {
      this.props.province_get({
        alternative_provinces: true,
        country: this.props.providerEdit.al_country,
      });
      this.props.city_get({ alternative_cities: true });
    }
  }

  // ANCHOR DIDUPDATE
  componentDidUpdate(prevProps, prevState) {
    const idProvider = this.state.addSupports.id;

    if (!isEmptyOrUndefined(idProvider) && !prevState.trigger) {
      this.props.getServiceByIdProvider(idProvider);
      this.props.getLaboratoryByIdProvider(idProvider);
    }

    if (this.props.initialListLaboratories?.length && !prevState.initialLaboratoriesTrigger) {
      this.setState({
        ...this.state,
        initialLaboratoriesTrigger: true,
        addSupports: {
          ...this.state.addSupports,
          optionSelectedLaboratories: this.props.initialListLaboratories?.map(lab => ({
            label: lab?.name,
            value: lab?.id,
          })),
        },
      });
    }

    if (this.props.initialListTypeServices?.length && !prevState.initialServicesTrigger) {
      this.setState({
        ...this.state,
        initialServicesTrigger: true,
        addSupports: {
          ...this.state.addSupports,
          optionSelectedTypeServices: this.props.initialListTypeServices?.map(service => ({
            label: service?.name,
            value: service?.id,
            tag: service?.tag,
          })),
        },
      });
    }

    if (prevProps !== this.props) {
      if (prevProps.allCities !== this.props.allCities) {
        this.setState({ ...this.state, allCities: this.props.allCities });
      }
      if (prevProps.allProvinces !== this.props.allProvinces) {
        this.setState({ ...this.state, allProvinces: this.props.allProvinces });
      }
    }
    let { allowDraft } = this.state;
    if (prevState !== this.state) {
      let count = 0;
      // In this method valid for checkitem on top left side in accordion.
      let checkSupports = true,
        checkGeneralInformation = true,
        checkFinancialInformation = true,
        checkLegalRepresent = true,
        checkDeclarations = true,
        checkBankInformation = true,
        checkSignature = true;

      for (const key in this.state.addSupports) {
        if (Object.hasOwnProperty.call(this.state.addSupports, key)) {
          const element = this.state.addSupports[key];
          if (!String(key).includes('fileType') && !String(key).includes('extraBusinessAcvities')) {
            if (element) count++;
            for (const f in generalInformationCheckArray) {
              if (String(f) === String(key) && !element) checkGeneralInformation = false;
            }
            for (const p in financialInformationCheckArray) {
              if (String(p) === String(key) && !element) {
                checkFinancialInformation = false;
              }
            }
            for (const y in legalRepresentArray) {
              if (String(y) === String(key) && !element) {
                checkLegalRepresent = false;
              }
            }
            if (this.state.addSupports?.personType_shareholder_rpst === 'legal_person') {
              for (const y in legalPersonOnLglReprsent) {
                if (String(y) === String(key) && !element) {
                  checkLegalRepresent = false;
                }
              }
            } else if (this.state.addSupports?.personType_shareholder_rpst === 'natural') {
              for (const y in naturalPersonOnLglReprsent) {
                if (String(y) === String(key) && !element) {
                  checkLegalRepresent = false;
                }
              }
            }
            for (const x in supportFilesArray) {
              if (String(x) === String(key) && !element) {
                checkSupports = false;
              } else {
                count++;
              }
            }
            // valid banking info
            for (let i = 0; i < requiredFieldsBankInfo.length; i++) {
              const elem = requiredFieldsBankInfo[i];
              if (elem === key && !element) {
                checkBankInformation = false;
              }
            }
            // extra banking account
            if (key === 'extraBankAccount') {
              for (let i = 0; i < element.length; i++) {
                for (let j = 0; j < requiredFieldsBankInfo.length; j++) {
                  const elem = requiredFieldsBankInfo[j];
                  if (elem === key && !element) {
                    checkBankInformation = false;
                  }
                }
              }
            }
            // valid moneda extranjera
            if (this.state.addSupports.hasForeignCurrency) {
              for (let i = 0; i < requiredFieldExtraForeignCurrency.length; i++) {
                const elem = requiredFieldExtraForeignCurrency[i];
                if (elem === key && !element) checkBankInformation = false;
              }
            }
            // valid moneda extranjera
            if (this.state.addSupports.extraForeignAccount.length > 0) {
              for (let i = 0; i < requiredFieldExtraForeignCurrency.length; i++) {
                const elem = requiredFieldExtraForeignCurrency[i];
                if (elem === key && !element) checkBankInformation = false;
              }
            }
            // extra moneda extranjera
            if (key === 'extraForeignAccount') {
              if (this.state.addSupports.extraCommercialReferences.length > 0) {
                for (let i = 0; i < element.length; i++) {
                  for (let j = 0; j < requiredFieldsExtraCommercialReferences.length; j++) {
                    const elem = requiredFieldsExtraCommercialReferences[j];
                    if (elem === key && !element) checkBankInformation = false;
                  }
                }
              }
            }
            // valid moneda extranjera
            if (this.state.addSupports.hasCommercialReferences) {
              for (let i = 0; i < requiredFieldsExtraCommercialReferences.length; i++) {
                const elem = requiredFieldsExtraCommercialReferences[i];
                if (elem === key && !element) checkBankInformation = false;
              }
            }
            // valid referencias comerciales
            for (let i = 0; i < requiredFieldsExtraCommercialReferences.length; i++) {
              const elem = requiredFieldsBankInfo[i];
              if (elem === key && !element) checkBankInformation = false;
            }
            // extra referencias comerciales
            if (key === 'extraCommercialReferences') {
              for (const k in element) {
                if (Object.hasOwnProperty.call(element, k)) {
                  const object = element[k];
                  for (const j in object) {
                    for (let i = 0; i < requiredFieldsExtraCommercialReferences.length; i++) {
                      const elem = requiredFieldsExtraCommercialReferences[i];
                      if (elem === j && !element[k][elem]) checkBankInformation = false;
                      if (elem === 'phoneCommercialReference' && element[k][elem].length < 7)
                        checkBankInformation = false;
                      if (elem === 'cellphoneCommercialReference' && element[k][elem].length !== 10)
                        checkBankInformation = false;
                    }
                  }
                }
              }
            }
          }
        }
      }
      if (String(this.state.addSupports.phone).length < 7) {
        checkGeneralInformation = false;
      }
      if (String(this.state.addSupports.cellphone).length !== 10) {
        checkGeneralInformation = false;
      }
      if (
        String(this.state.addSupports.phoneCommercialReference).length < 7 &&
        this.state.addSupports.hasCommercialReferences
      ) {
        checkBankInformation = false;
      }
      if (
        String(this.state.addSupports.cellphoneCommercialReference).length !== 10 &&
        this.state.addSupports.hasCommercialReferences
      ) {
        checkBankInformation = false;
      }
      if (
        this.state.addSupports.name === '' ||
        this.state.addSupports.firstNameLglRepresent === '' ||
        this.state.addSupports.firstSurnameLglRepresent === '' ||
        this.state.addSupports.docNumLglRepresent === '' ||
        this.state.addSupports.expLocationLglRepresent === '' ||
        !this.state.addSupports.authConsultBackground ||
        !this.state.addSupports.authProcessPersonalData ||
        !this.state.addSupports.authVerifyInfoDoc ||
        !this.state.addSupports.authVisitRecord
      ) {
        checkDeclarations = false;
      }

      if (!this.state.addSupports.checkConstancySignature || !this.state.addSupports.signature)
        checkSignature = false;
      // Check for general information
      count > 907 ? (allowDraft = true) : (allowDraft = false);
      let allowSent = false;
      if (
        this.state.checkRequiredDocs &&
        this.state.checkGeneralInformation &&
        this.state.checkFinancialInformation &&
        this.state.checkLegalRepresent &&
        this.state.checkDeclarations &&
        this.state.checkBankInformation &&
        this.state.checkSignature
      ) {
        allowSent = true;
      }
      if (allowSent !== prevState.allowSent) this.setState({ allowSent: allowSent });
      if (
        checkSupports !== this.state.checkRequiredDocs ||
        checkGeneralInformation !== this.state.checkGeneralInformation ||
        checkFinancialInformation !== this.state.checkFinancialInformation ||
        checkLegalRepresent !== this.state.checkLegalRepresent ||
        checkDeclarations !== this.state.checkDeclarations ||
        checkBankInformation !== this.state.checkBankInformation ||
        checkSignature !== this.state.checkSignature
      ) {
        this.setState({
          ...this.state,
          checkRequiredDocs: checkSupports,
          checkGeneralInformation: checkGeneralInformation,
          checkFinancialInformation: checkFinancialInformation,
          checkLegalRepresent: checkLegalRepresent,
          checkDeclarations: checkDeclarations,
          checkBankInformation: checkBankInformation,
          checkSignature: checkSignature,
        });
      }
      if (count > 907 && prevState.allowDraft !== allowDraft) this.setState({ allowDraft });
    }
  }

  // toggleAccordionItem = (e) => {
  //   const name = e.target.name;
  //   let state = this.state;
  //   state[name] = !state[name];
  //   this.setState( state );
  // };
  toggleModalObservations = () => {
    this.setState({ showObservationModal: !this.state.showObservationModal });
  };
  toggleModalExposePeople = () => {
    this.setState({ modalExposePeople: !this.state.modalExposePeople });
  };

  handleSubmitDraft = e => {
    e.preventDefault();

    if (this.state.allowDraft) {
      const { name } = e.target;

      const { addSupports } = this.state;

      const objProvier = {
        ...addSupports,

        optionSelectedTypeServices: undefined,
        optionSelectedLaboratories: undefined,

        offersServices: addSupports?.checkServiceProvider || undefined,

        serviceTypes: addSupports?.optionSelectedTypeServices?.length
          ? this?.state?.addSupports?.optionSelectedTypeServices?.map(el => el?.value)
          : undefined,

        laboratories: addSupports?.optionSelectedLaboratories?.length
          ? this?.state?.addSupports?.optionSelectedLaboratories?.map(el => el?.value)
          : undefined,
      };

      objProvier.nit = objProvier.nit.concat(objProvier.verDigit);
      if (this.props.providerEdit) {
        objProvier.id = this.props.providerEdit.id;
      }

      if (
        this.state.addSupports?.checkServiceProvider &&
        !this.state.addSupports?.optionSelectedTypeServices?.length
      ) {
        return customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'El campo tipo de servicio es obligatorio',
        });
      }

      if (this.state.allowSent && name === 'send') {
        objProvier.create = true;
        objProvier.currentAccount = this.props.currentAccountName;
        responseSwal({
          icon: 'warning',
          title: `<h3 style="font-size:26px; color:#01A0F6;font-weight: bold;">¿Está seguro?<h3>`,
          text: `Verifique la información`,
          showConfirmButton: true,
          showCancelButton: true,
          textCancelButton: 'Cancelar',
          confirmButtonColor: '#fff',
          focusConfirm: false,
          confirmButtonText: 'Si, Enviar',
          cancelButtonText: 'Cancelar',
        }).then(result => {
          if (result.isConfirmed) {
            this.props.createDraft(objProvier, 'send');
          } else {
            return null;
          }
        });
      } else {
        this.props.createDraft(objProvier, 'draft');
      }
    }
  };

  onChangeTypeSociety = e => {
    const value = e.target.value;
    let { addSupports } = this.state;
    addSupports.society_type = value;
    if (value === 'other') {
      this.setState({ showOtherSociety: true, addSupports });
    } else this.setState({ showOtherSociety: false });
    this.setState({ addSupports });
  };

  onChangeForm = e => {
    const name = e.target.name;
    const value = e.target.value;
    let { addSupports } = this.state;
    if (e.target.type === 'number') {
      if (String(value).length > 0) {
        if (value < 0 || this.filterNumbers(value) !== value) {
          return responseSwal({
            icon: 'error',
            title: 'Error, debe ingresar un valor válido',
          });
        }
      }
    }
    if (value === 'true' || value === true) addSupports[name] = true;
    if (value === 'false' || value === false) addSupports[name] = false;
    else {
      addSupports[name] = value;
    }
    this.setState({ addSupports });
  };

  onChangeCheck = () => {
    let { addSupports } = this.state;
    addSupports.hasAlternativeAddress = !this.state.addSupports.hasAlternativeAddress;
    this.setState({ addSupports });
  };

  handleCheck = (e, dinamicChange) => {
    const { name, checked } = e.target;
    let { addSupports } = this.state;
    if (dinamicChange === 'offers_services') {
      addSupports[name] = checked;
      addSupports['offers_services'] = checked;
      this.setState({ addSupports });
    } else {
      addSupports[name] = checked;
      this.setState({ addSupports });
    }
  };

  filterNumbers = value => {
    var out = '';
    var filtro = '1234567890'; //Caracteres validos

    //Recorrer el texto y verificar si el caracter se encuentra en la lista de validos
    for (var i = 0; i < value.length; i++)
      if (filtro.indexOf(value.charAt(i)) !== -1)
        //Se añaden a la salida los caracteres validos
        out += value.charAt(i);

    //Retornar valor filtrado
    return out;
  };

  cleanStateShareholder = key => {
    let { addSupports } = this.state;
    switch (key) {
      case 1:
        addSupports.fisrtNameShareholder_lgl_rpst = '';
        addSupports.fisrtSurnameShareholder_lgl_rpst = '';
        addSupports.docTypeShareholder_lgl_rpst = '';
        addSupports.docNumShareholder_lgl_rpst = '';
        addSupports.expLocationShareholder = '';
        addSupports.businessName_lgl_rprst = '';
        addSupports.nitShareholder_lgl_rpst = '';
        addSupports.expLocationShareholder = '';
        break;
      case 2:
        addSupports.extra_legal_representatives[0].fisrtNameShareholder_lgl_rpst = '';
        addSupports.extra_legal_representatives[0].fisrtSurnameShareholder_lgl_rpst = '';
        addSupports.extra_legal_representatives[0].docTypeShareholder_lgl_rpst = '';
        addSupports.extra_legal_representatives[0].docNumShareholder_lgl_rpst = '';
        addSupports.extra_legal_representatives[0].expLocationShareholder = '';
        addSupports.extra_legal_representatives[0].businessName_lgl_rprst = '';
        addSupports.extra_legal_representatives[0].nitShareholder_lgl_rpst = '';
        addSupports.extra_legal_representatives[0].expLocationShareholder = '';
        break;
      case 3:
        addSupports.extra_legal_representatives[1].fisrtNameShareholder_lgl_rpst = '';
        addSupports.extra_legal_representatives[1].fisrtSurnameShareholder_lgl_rpst = '';
        addSupports.extra_legal_representatives[1].docTypeShareholder_lgl_rpst = '';
        addSupports.extra_legal_representatives[1].docNumShareholder_lgl_rpst = '';
        addSupports.extra_legal_representatives[1].expLocationShareholder = '';
        addSupports.extra_legal_representatives[1].businessName_lgl_rprst = '';
        addSupports.extra_legal_representatives[1].nitShareholder_lgl_rpst = '';
        addSupports.extra_legal_representatives[1].expLocationShareholder = '';
        break;
      case 4:
        addSupports.extra_legal_representatives[2].fisrtNameShareholder_lgl_rpst = '';
        addSupports.extra_legal_representatives[2].fisrtSurnameShareholder_lgl_rpst = '';
        addSupports.extra_legal_representatives[2].docTypeShareholder_lgl_rpst = '';
        addSupports.extra_legal_representatives[2].docNumShareholder_lgl_rpst = '';
        addSupports.extra_legal_representatives[2].expLocationShareholder = '';
        addSupports.extra_legal_representatives[2].businessName_lgl_rprst = '';
        addSupports.extra_legal_representatives[2].nitShareholder_lgl_rpst = '';
        addSupports.extra_legal_representatives[2].expLocationShareholder = '';
        break;

      default:
        break;
    }
    this.setState({ addSupports });
  };

  onChangeSelect = e => {
    const name = e.target.name;
    const value = e.target.value;
    let { addSupports } = this.state;
    addSupports[name] = value;

    const al_province_lgl_rpst = () => {
      let { allCities } = this.state;
      allCities = this.props.allCities.filter(x => x.province === parseInt(value));
      this.setState({ allCities });
    };

    switch (name) {
      case 'country':
        this.props.province_get({ country: value });
        break;
      case 'province':
        this.props.city_get({ province: value });
        break;
      case 'al_country':
        this.props.province_get({
          country: value,
          alternative_provinces: true,
        });
        break;
      case 'al_province':
        this.props.city_get({ province: value, alternative_cities: true });
        break;
      case 'al_province_lgl_rpst':
        al_province_lgl_rpst();
        break;
      default:
        break;
    }
    this.setState({ addSupports });
  };

  onChangeSelectExtraShareholder = (event, iterator) => {
    const { name, value } = event.target;
    let { addSupports } = this.state;
    let element = addSupports.extra_legal_representatives[iterator];
    element[name] = value;
    let nameCase = `${name}_${iterator}`;
    switch (nameCase) {
      case 'personType_shareholder_rpst':
        this.cleanStateShareholder(1);
        break;
      case 'personType_shareholder_rpst_0':
        this.cleanStateShareholder(2);
        break;
      case 'personType_shareholder_rpst_1':
        this.cleanStateShareholder(3);
        break;
      case 'personType_shareholder_rpst_2':
        this.cleanStateShareholder(4);
        break;
      default:
        break;
    }
    this.setState({ addSupports });
  };
  //ANCHOR onChange for extra shareholder
  onChangeExtraShareholders = (event, iterator) => {
    const { name, value } = event.target;
    let { addSupports } = this.state;
    let objectShareholder = addSupports.extra_legal_representatives[iterator];
    objectShareholder[name] = value;
    this.setState({ addSupports });
  };
  // onChange for extra business activities
  onChangeFormExtraBusiness = (event, i) => {
    const { name, value } = event.target;
    let { addSupports } = this.state;
    addSupports.extra_bussiness_activites[i][name] = value;
    this.setState({ addSupports });
  };
  // onChange for extra bank accounts
  onChangeFormExtraBankAccount = (event, i) => {
    const { name, value } = event.target;
    let { addSupports } = this.state;
    addSupports.extra_bank_account[i][name] = value;
    this.setState({ addSupports });
  };
  // onChange for extra bank products
  onChangeFormExtraBankProducts = (event, i) => {
    const { name, value } = event.target;
    let { addSupports } = this.state;
    addSupports.extraForeignAccount[i][name] = value;
    this.setState({ addSupports });
  };
  // onChange for extra commercial references
  onChangeExtraCommercialReferences = (event, i) => {
    const { name, value } = event.target;
    let { addSupports } = this.state;
    addSupports.extraCommercialReferences[i][name] = value;
    this.setState({ addSupports });
  };

  // Verify nit verify digit #️⃣
  onChageDigitVerify = e => {
    const value = e.target.value;
    let { addSupports } = this.state;
    if (String(value).length === 0) {
      addSupports.verDigit = '';
      this.setState({ addSupports });
    } else if (String(value).length < 2) {
      const verifyNumber = nitCalculate(this.state.addSupports.nit);
      if (parseInt(verifyNumber) === parseInt(value)) {
        addSupports.verDigit = value;
        this.setState({ addSupports });
      } else {
        responseSwal({
          icon: 'error',
          title: 'Error',
          text: 'El dígito de verificación no es correcto',
        });
      }
    }
  };

  // Event to get doc on input file
  onChangeDoc = async event => {
    if (event.target.files.length > 0) {
      let file = event.target.files[0];

      const value = event.target.value;

      // Verify file size
      if (file.size > MAX_SIZE_FILE) {
        return responseSwal({
          icon: 'error',
          title: 'Error',
          text: 'El archivo excede el tamaño máximo permitido (5MB)',
        });
      }
      let filename = event.target.files[0].name;
      let { name, id } = event.target;
      let { addSupports, allowDraft } = this.state;
      // Convert file to base64 ⬇
      let encode = await this.convertBase64(event.target.files[0]);
      // let valueSave = encode.replace(/^data:image\/[a-z]+;base64,/, "");
      let valueSave = encode.split(',')[1]; // Delete base64 prefix (data:application...)
      let propToVerify = 'fileType' + id;
      // Verify if file type is valid with regex type
      if (new RegExp(MULTIPLE_REGEX_VALID_FILE).test(value)) {
        addSupports[name] = filename;
        addSupports[id] = valueSave;
        addSupports[propToVerify] = this.getFileIcon(value);

        let count = 0;
        // This for uses to active a save draft button (Activar el botón de guardar borrador) 🖱
        for (const key in addSupports) {
          if (Object.hasOwnProperty.call(addSupports, key)) {
            const element = addSupports[key];
            if (!!element && key.includes('filename')) {
              count++;
            }
          }
        }
        if (count > 0) {
          allowDraft = true;
          addSupports.includeFile = true;
        } else {
          addSupports.includeFile = false;
        }
        this.setState({ addSupports, allowDraft });
      } else {
        return responseSwal({
          icon: 'error',
          title: 'Error',
          text: 'El tipo de archivo no es válido',
          footer: 'Los formatos permitidos son pdf, word y excel',
        });
      }
    }
  };
  //ANCHOR Change doc extra commercial
  onChangeDocExtraCommercialReferences = async (event, i) => {
    if (event.target.files.length > 0) {
      let file = event.target.files[0];

      const value = event.target.value;

      // Verify file size
      if (file.size > MAX_SIZE_FILE) {
        return responseSwal({
          icon: 'error',
          title: 'Error',
          text: 'El archivo excede el tamaño máximo permitido (5MB)',
        });
      }
      let filename = event.target.files[0].name;
      let { name, id } = event.target;
      let { addSupports, allowDraft } = this.state;
      // Convert file to base64 ⬇
      let encode = await this.convertBase64(event.target.files[0]);
      // let valueSave = encode.replace(/^data:image\/[a-z]+;base64,/, "");
      let valueSave = encode.split(',')[1]; // Delete base64 prefix (data:application...)
      let propToVerify = 'fileType' + id;
      // Verify if file type is valid with regex type
      if (new RegExp(MULTIPLE_REGEX_VALID_FILE).test(value)) {
        addSupports.extraCommercialReferences[i][name] = filename;
        addSupports.extraCommercialReferences[i][id] = valueSave;
        addSupports.extraCommercialReferences[i][propToVerify] = this.getFileIcon(value);

        let count = 0;
        // This for uses to active a save draft button (Activar el botón de guardar borrador) 🖱
        for (let i = 0; i < addSupports.extraCommercialReferences.length; i++) {
          const element = addSupports.extraCommercialReferences[i];
          for (const key in element) {
            if (Object.hasOwnProperty.call(element, key)) {
              const item = element[key];
              if (!!item && key.includes('filename')) {
                count++;
              }
            }
          }
        }

        if (count > 0) {
          allowDraft = true;
          addSupports.includeFile = true;
        } else {
          addSupports.includeFile = false;
        }
        this.setState({ addSupports, allowDraft });
      } else {
        return responseSwal({
          icon: 'error',
          title: 'Error',
          text: 'El tipo de archivo no es válido',
          footer: 'Los formatos permitidos son pdf, word y excel',
        });
      }
    }
  };

  getFileIcon = fileType => {
    if (new RegExp(REGEX_VALID_PDF_TYPE).test(fileType)) {
      return pdfIcon;
    }
    if (new RegExp(REGEX_VALID_EXCEL_TYPE).test(fileType)) {
      return blueExcelIcon;
    }
    if (new RegExp(REGEX_VALID_WORD_TYPE).test(fileType)) {
      return wordIconBlue;
    }
  };

  convertBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = error => {
        reject(error);
      };
    });
  };

  //Method to discard files in form
  discardFile = event => {
    let { name } = event.target;
    let { addSupports, allowDraft } = this.state;
    addSupports[name] = '';
    addSupports['filename' + name] = '';
    let count = 0;
    for (const key in addSupports) {
      if (Object.hasOwnProperty.call(addSupports, key)) {
        const element = addSupports[key];
        if (!!element && key.includes('filename')) {
          count++;
        }
      }
    }
    if (count > 0) allowDraft = true;

    this.setState({ addSupports, allowDraft });
  };

  //ANCHOR Method to discard files extra commercial
  discardFileExtraCommercialReference = (event, i) => {
    let { name } = event.target;
    let { addSupports, allowDraft } = this.state;
    addSupports.extraCommercialReferences[i][name] = '';
    addSupports.extraCommercialReferences[i]['filename' + name] = '';
    let count = 0;
    for (const key in addSupports) {
      if (Object.hasOwnProperty.call(addSupports, key)) {
        const element = addSupports[key];
        if (!!element && key.includes('filename')) {
          count++;
        }
      }
    }
    count > 0 ? (allowDraft = true) : (allowDraft = false);
    this.setState({ addSupports, allowDraft });
  };

  // Add shareholder in legal represet array
  addShareholoder = () => {
    let { addSupports, addShareholder } = this.state;
    addShareholder = addShareholder < 3 ? addShareholder + 1 : null;
    var newElement = {};
    newElement.personType_shareholder_rpst = '';
    newElement.fisrtNameShareholder_lgl_rpst = '';
    newElement.fisrtSurnameShareholder_lgl_rpst = '';
    newElement.docTypeShareholder_lgl_rpst = '';
    newElement.docNumShareholder_lgl_rpst = '';
    newElement.expLocationShareholder = '';
    newElement.businessName_lgl_rprst = '';
    newElement.nitShareholder_lgl_rpst = '';
    if (addSupports.extra_legal_representatives.length < 3) {
      addSupports.extra_legal_representatives.push(newElement);
    }
    this.setState({ addSupports, addShareholder });
  };
  // Add shareholder in legal represet array
  addBusinessActivity = () => {
    let { addSupports, addActivity } = this.state;
    addActivity = addActivity < 3 ? addActivity + 1 : null;
    const newElement = {};
    newElement.id_ciiu = '';
    newElement.id_classification = '';
    if (addSupports.extra_bussiness_activites.length < 3) {
      addSupports.extra_bussiness_activites.push(newElement);
    }
    this.setState({ addSupports, addActivity });
  };

  // Add bank account in bank information 💰
  addAccount = () => {
    let { addSupports, addAccount } = this.state;
    addAccount = addAccount < 3 ? addAccount + 1 : null;
    var newElement = {};
    newElement.bankAccountType = '';
    newElement.bankingEntity = '';
    newElement.accountNumber = '';
    newElement.accountHolder = '';
    newElement.countryBankInfo = '';
    newElement.provinceBankInfo = '';
    newElement.cityBankInfo = '';
    newElement.bankBranch = '';
    if (addSupports.extra_bank_account.length < 3) {
      addSupports.extra_bank_account.push(newElement);
    }
    this.setState({ addSupports, addAccount });
  };
  // Add foreign bank product in bank information 💰
  addBankProduct = () => {
    let { addSupports, addBankProduct } = this.state;
    addBankProduct = addBankProduct < 3 ? addBankProduct + 1 : null;
    var newElement = {};
    newElement.foreignBankingEntity = '';
    newElement.foreignBankingType = '';
    newElement.foreignNumberBankAccount = '';
    newElement.foreignBankAmount = '';
    newElement.foreignBankCountry = '';
    newElement.foreignBankCity = '';
    newElement.foreignCoin = '';
    if (addSupports.extraForeignAccount.length < 3) {
      addSupports.extraForeignAccount.push(newElement);
    }
    this.setState({ addSupports, addBankProduct });
  };
  // Add foreign bank product in bank information 🙇
  addCommercialReferences = () => {
    let { addSupports, addComercialReference } = this.state;
    addComercialReference = addComercialReference < 3 ? addComercialReference + 1 : null;
    var newElement = {};
    newElement.nameCommercialReference = '';
    newElement.phoneCommercialReference = '';
    newElement.cellphoneCommercialReference = '';
    newElement.emailCommercialReference = '';
    newElement.countryCommercialReference = '';
    newElement.provinceCommercialReference = '';
    newElement.cityCommercialReference = '';
    newElement.addressCommercialReference = '';
    newElement.supportFileCommercialReference = '';
    newElement.filenamesupportFileCommercialReference = '';
    newElement.fileTypesupportFileCommercialReference = '';
    if (addSupports.extraCommercialReferences.length < 3) {
      addSupports.extraCommercialReferences.push(newElement);
    }
    this.setState({ addSupports, addComercialReference });
  };

  handleSwitchForeignCoin = e => {
    if (e === false) {
      this.setState({
        ...this.state,
        addBankProduct: 0,
        addSupports: {
          ...this.state.addSupports,
          foreignBankingEntity: '',
          foreignBankingType: '',
          foreignNumberBankAccount: '',
          foreignBankAmount: '',
          foreignBankCountry: '',
          foreignBankCity: '',
          foreignCoin: '',
          hasForeignCurrency: e,
          extraForeignAccount: [],
        },
      });
    } else {
      this.setState({
        ...this.state,
        addSupports: { ...this.state.addSupports, hasForeignCurrency: e },
      });
    }
  };
  handleSwitchCommercialReferences = e => {
    if (e === false) {
      this.setState({
        ...this.state,
        addBankProduct: 0,
        addSupports: {
          ...this.state.addSupports,
          nameCommercialReference: '',
          phoneCommercialReference: '',
          cellphoneCommercialReference: '',
          emailCommercialReference: '',
          countryCommercialReference: '',
          provinceCommercialReference: '',
          cityCommercialReference: '',
          addressCommercialReference: '',
          supportFileCommercialReference: '',
          hasCommercialReferences: e,
          extraCommercialReferences: [],
        },
      });
    } else {
      this.setState({
        ...this.state,
        addSupports: { ...this.state.addSupports, hasCommercialReferences: e },
      });
    }
  };

  validateFileSignature = acceptedFiles => {
    let { fileValid, fileUpload, addSupports } = this.state;
    fileUpload = acceptedFiles;
    let file = acceptedFiles[0];
    if (file) {
      if (file.size > MAX_SIZE_FILE) {
        fileValid = false;
        responseSwal({
          icon: 'error',
          title: 'Error',
          text: 'El archivo es demasiado pesado',
          footer: 'El tamaño máximo permitido es de 5MB',
        });
      } else if (new RegExp(REGEX_IMAGES).test(file.name)) {
        fileValid = true;
      } else {
        fileValid = false;
        responseSwal({
          icon: 'error',
          title: 'Error',
          text: 'El formato del archivo no es válido',
          footer: 'Debe ser una imagen y el tamaño máximo permitido es de 5MB',
        });
      }
      if (fileValid) {
        setTimeout(async () => {
          let encode = await this.convertBase64(file);
          addSupports.signature = encode;
          addSupports.filenamesignature = file.name;
          this.setState({ ...this.state, addSupports });
        }, 10);
      }
    } else if (!file) {
      addSupports.signature = '';
    }
    this.setState({ fileValid, fileUpload, addSupports });
  };

  // ANCHOR render method
  render() {
    // asterísco de color naranja / orange asterisk
    let asterisk = <b style={{ color: '#FF8B00' }}>*</b>;
    let asteriskBlue = <b style={{ color: '#005DBF' }}>*</b>;
    let asteriskDisabled = <b style={{ color: '#CECECE' }}>*</b>;

    // render name in declarations
    let renderNameLglRpst = '________________________________________';
    let renderDocNumLglRpst = '_________________________';
    let renderExpLocationLglRpst = '___________________________________';
    let renderBusinessName = '___________________________________';
    if (this.state.addSupports.firstNameLglRepresent) {
      renderNameLglRpst = (
        <span className='text-primary fw-bold text-center text-decoration-underline'>
          {this.state.addSupports.firstNameLglRepresent +
            ' ' +
            this.state.addSupports.secondNameLglRepresent +
            ' ' +
            this.state.addSupports.firstSurnameLglRepresent +
            ' ' +
            this.state.addSupports.secondSurnameLglRepresent}
        </span>
      );
    }
    if (this.state.addSupports.docNumLglRepresent) {
      renderDocNumLglRpst = (
        <span className='text-primary fw-bold text-center text-decoration-underline'>
          {this.state.addSupports.docNumLglRepresent}
        </span>
      );
    }
    if (this.state.addSupports.expLocationLglRepresent) {
      renderExpLocationLglRpst = (
        <span className='text-primary fw-bold text-center text-decoration-underline'>
          {this.state.addSupports.expLocationLglRepresent}
        </span>
      );
    }
    if (this.state.addSupports.name) {
      renderBusinessName = (
        <span className='text-primary fw-bold text-center text-decoration-underline'>
          {this.state.addSupports.name}
        </span>
      );
    }

    // render options for company types
    let optionsCompanyTypes = [];
    for (const key in CompantyTypes) {
      if (Object.hasOwnProperty.call(CompantyTypes, key)) {
        const element = CompantyTypes[key];
        optionsCompanyTypes.push(
          <option key={`cmpType-${key}`} value={key}>
            {element}
          </option>,
        );
      }
    }

    // render options for society types
    let optionsSocietyTypes = [];
    for (const key in SocietyTypes) {
      if (Object.hasOwnProperty.call(SocietyTypes, key)) {
        const element = SocietyTypes[key];
        optionsSocietyTypes.push(
          <option key={`socType-${key}`} value={key}>
            {element}
          </option>,
        );
      }
    }

    // render options for countries
    let optionsCountries = [];
    if (this.props.countries) {
      this.props.countries.forEach(item => {
        optionsCountries.push(
          <option key={item.id} value={item.id}>
            {item.name}
          </option>,
        );
      });
    }

    // render options for provinces
    let optionsProvinces = [];
    if (this.props.provinces) {
      this.props.provinces.forEach(item => {
        optionsProvinces.push(
          <option key={item.id} value={item.id}>
            {item.name}
          </option>,
        );
      });
    }
    // render options for provinces
    let optionsAlternativeProvinces = [];
    if (this.props.al_provinces) {
      this.props.al_provinces.forEach(item => {
        optionsAlternativeProvinces.push(
          <option key={`${item.id}-al`} value={item.id}>
            {item.name}
          </option>,
        );
      });
    }

    // render options for cities
    let optionsCities = [];
    if (this.props.cities) {
      this.props.cities.forEach(item => {
        optionsCities.push(
          <option key={item.id} value={item.id}>
            {item.name}
          </option>,
        );
      });
    }
    // render options for cities
    let optionsAlternativeCities = [];
    if (this.props.al_cities) {
      this.props.al_cities.forEach(item => {
        optionsAlternativeCities.push(
          <option key={`${item.id}-al`} value={item.id}>
            {item.name}
          </option>,
        );
      });
    }
    // render options for business activities
    let optionsBusinessActivities = [];
    if (this.props.ciiu) {
      this.props.ciiu.forEach(item => {
        optionsBusinessActivities.push(
          <option key={item.id} value={item.id}>
            {`${item.klass} - ${item.description}`}
          </option>,
        );
      });
    }
    // render options for payer types
    let optionsPayerTypes = [];
    if (this.props.taxPayerTypes) {
      this.props.taxPayerTypes.forEach(item => {
        optionsPayerTypes.push(
          <option key={item.id} value={item.id}>
            {`${item.tag}`}
          </option>,
        );
      });
    }
    // render options for ocuppations
    let optionsOccupation = [];
    if (this.props.providerClassification) {
      this.props.providerClassification.forEach(item => {
        optionsOccupation.push(
          <option key={item.id} value={item.id}>
            {item.description}
          </option>,
        );
      });
    }

    // render options for cities in legal represent
    let optionsAllCities = [];
    if (this.state.allCities) {
      let renderSort = this.state.allCities.sort((x, y) => {
        if (x.name < y.name) {
          return -1;
        }
        if (x.name > y.name) {
          return 1;
        } else {
          return 0;
        }
      });

      renderSort.forEach(item => {
        optionsAllCities.push(
          <option key={`${item.id}-city`} value={item.id}>
            {item.name}
          </option>,
        );
      });
    }

    let optionsAllProvinces = [];
    if (this.state.allProvinces) {
      let renderSort = this.state.allProvinces.sort((x, y) => {
        if (x.name < y.name) {
          return -1;
        }
        if (x.name > y.name) {
          return 1;
        } else {
          return 0;
        }
      });
      renderSort.forEach(item => {
        optionsAllProvinces.push(
          <option key={`${item.id}-prov`} value={item.id}>
            {item.name}
          </option>,
        );
      });
    }

    //ANCHOR Method to render inputs for save another economy activity 💵
    let renderEconomicActivity = [];
    for (let i = 0; i < this.state.addActivity; i++) {
      renderEconomicActivity.push(
        <div className={style.containerRow}>
          {/* economy actity */}
          <div className={`${style.selectGroup} ${style.colEighteen} mt-0`}>
            <label htmlFor='economic_activity' className={`${style.labelFontGray}`}>
              Código CIIU y actividad económica{asterisk}
            </label>
            <select
              key={`economic_activity-${i}`}
              name={`id_ciiu`}
              className={`${style.selectInput} w-100 px-2 `}
              onChange={e => this.onChangeFormExtraBusiness(e, i)}
              value={this.state.addSupports.extra_bussiness_activites[i].id_ciiu}
            >
              <option value=''>seleccionar...</option>
              {optionsBusinessActivities}
            </select>
          </div>
          {/* occupation */}
          <div className={`${style.selectGroup} ${style.colSix}`}>
            <label htmlFor='classification' className={`${style.labelFontGray}`}>
              Clasificación{asterisk}
            </label>
            <select
              key={`id_classification`}
              name={`id_classification`}
              className={`${style.selectInput} w-100 px-2 `}
              onChange={e => this.onChangeFormExtraBusiness(e, i)}
              value={this.state.addSupports.extra_bussiness_activites[i].id_classification}
            >
              <option value=''>seleccionar...</option>
              {optionsOccupation}
            </select>
          </div>
        </div>,
      );
    }
    //ANCHOR Method to render inputs for save another shareholder 🤝
    let renderShareHolders = [];
    for (let i = 0; i < this.state.addShareholder; i++) {
      renderShareHolders.push(
        <div key={`sharesholder-${i}`}>
          <div className={`${style.containerRow} mt-2`}>
            {/* Person type - shareholder */}
            <div className={`${style.selectGroup} ${style.selectPersonTypeLglRpst}`}>
              <label
                htmlFor={`personType_shareholder_rpst`}
                className={`${style.labelFontGray} my-1`}
              >
                Tipo de persona{asteriskBlue}
              </label>
              <select
                name={`personType_shareholder_rpst`}
                className={`${style.selectInput} w-100 px-2 `}
                onChange={e => this.onChangeSelectExtraShareholder(e, i)}
                value={
                  this.state.addSupports.extra_legal_representatives[i].personType_shareholder_rpst
                }
              >
                <option value=''>seleccionar...</option>
                <option value='legal_person'>{ProviderType.legal_person}</option>
                <option value='natural'>{ProviderType.natural}</option>
              </select>
            </div>
          </div>
          {this.state?.addSupports.extra_legal_representatives[i].personType_shareholder_rpst ===
            'natural' && (
            <div className={`${style.containerRow} mt-2`}>
              {/* first name shareholder */}
              <div className={`${style.selectGroup} ${style.colSix}`}>
                <label
                  htmlFor={`fisrtNameShareholder_lgl_rpst`}
                  className={`${style.labelFontGray}`}
                >
                  Primer nombre{asterisk}
                </label>
                <input
                  type='text'
                  placeholder='Escribir...'
                  name={`fisrtNameShareholder_lgl_rpst`}
                  className={`${style.inputText} w-100 px-2`}
                  onChange={e => this.onChangeExtraShareholders(e, i)}
                  value={
                    this.state.addSupports.extra_legal_representatives[i]
                      .fisrtNameShareholder_lgl_rpst || ''
                  }
                />
              </div>
              {/* first surname of shareholder in legal represent  */}
              <div className={`${style.textGroup} ${style.colSix}`}>
                <label
                  htmlFor={`fisrtSurnameShareholder_lgl_rpst`}
                  className={`${style.labelFontGray}`}
                >
                  Primer apellido{asterisk}
                </label>
                <input
                  type='text'
                  placeholder='Escribir...'
                  onChange={e => this.onChangeExtraShareholders(e, i)}
                  className={`${style.inputText} w-100 px-2`}
                  name={`fisrtSurnameShareholder_lgl_rpst`}
                  value={
                    this.state.addSupports.extra_legal_representatives[i]
                      .fisrtSurnameShareholder_lgl_rpst || ''
                  }
                />
              </div>
              {/* doc type of shareholder in legal represent */}
              <div className={`${style.selectGroup} ${style.colFour}`}>
                <label htmlFor={`docTypeShareholder_lgl_rpst`} className={`${style.labelFontGray}`}>
                  Tipo de doc{asterisk}
                </label>
                <select
                  name={`docTypeShareholder_lgl_rpst`}
                  className={`${style.selectInput} w-100 px-2 `}
                  onChange={e => this.onChangeSelectExtraShareholder(e, i)}
                  value={
                    this.state.addSupports.extra_legal_representatives[i]
                      .docTypeShareholder_lgl_rpst
                  }
                >
                  <option value=''>seleccionar...</option>
                  <option value='cc'>C.C</option>
                  <option value='ti'>T.I</option>
                  <option value='ce'>C.E</option>
                  <option value='rc'>R.C</option>
                  <option value='ms'>M.S</option>
                  <option value='as_'>A.S</option>
                  <option value='nu'>N.U</option>
                  <option value='pa'>PA</option>
                </select>
              </div>
              {/* number of document from shareholder in legal represent */}
              <div className={`${style.selectGroup} ${style.colFour}`}>
                <label htmlFor={`docNumShareholder_lgl_rpst`} className={`${style.labelFontGray}`}>
                  Número de doc.{asterisk}
                </label>
                <input
                  name={`docNumShareholder_lgl_rpst`}
                  placeholder='Escribir...'
                  className={`${style.inputText} w-100 px-2`}
                  value={
                    formatteDocument(this.state.addSupports.extra_legal_representatives[i]
                      .docNumShareholder_lgl_rpst || '').format
                  }
                  onChange={e => {
                    let { addSupports } = this.state;
                    addSupports.extra_legal_representatives[i].docNumShareholder_lgl_rpst = formatteDocument(e.target.value).value;
                    this.setState({ addSupports });
                  }}
                />
              </div>
              {/* expedition place*/}
              <div className={`${style.selectGroup} ${style.colFour}`}>
                <label htmlFor={`expLocationShareholder`} className={`${style.labelFontGray}`}>
                  Lugar de exp.{asterisk}
                </label>
                <input
                  type='text'
                  placeholder='Escribir...'
                  name={`expLocationShareholder`}
                  onChange={e => this.onChangeExtraShareholders(e, i)}
                  className={`${style.inputText} w-100 px-2`}
                  value={
                    this.state.addSupports.extra_legal_representatives[i].expLocationShareholder ||
                    ''
                  }
                />
              </div>
            </div>
          )}
          {this.state.addSupports.extra_legal_representatives[i].personType_shareholder_rpst ===
            'legal_person' && (
            <div className={`${style.containerRow} mt-2`}>
              {/* buisiness name shareholder */}
              <div className={`${style.selectGroup} ${style.colTwelve}`}>
                <label htmlFor={`businessName_lgl_rprst`} className={`${style.labelFontGray}`}>
                  Razón social{asterisk}
                </label>
                <input
                  type='text'
                  placeholder='Escribir...'
                  name={`businessName_lgl_rprst`}
                  className={`${style.inputText} w-100 px-2`}
                  onChange={e => this.onChangeExtraShareholders(e, i)}
                  value={
                    this.state.addSupports.extra_legal_representatives[i][
                      `businessName_lgl_rprst`
                    ] || ''
                  }
                />
              </div>
              {/* number of document from shareholder in legal represent */}
              <div className={`${style.selectGroup} ${style.colSix}`}>
                <label htmlFor={`nitShareholder_lgl_rpst`} className={`${style.labelFontGray}`}>
                  NIT{asterisk}
                </label>
                <NumberFormat
                  name='nitShareholder_lgl_rpst'
                  placeholder='Escribir...'
                  allowNegative={false}
                  thousandSeparator={this.localeSeparators.groupSeparator}
                  decimalSeparator={this.localeSeparators.decimalSeparator}
                  className={`${style.inputText} w-100 px-2`}
                  value={
                    this.state.addSupports.extra_legal_representatives[i].nitShareholder_lgl_rpst ||
                    ''
                  }
                  onValueChange={values => {
                    let { addSupports } = this.state;
                    addSupports.extra_legal_representatives[i].nitShareholder_lgl_rpst =
                      values.value;
                    this.setState({ addSupports });
                  }}
                />
              </div>
              {/* expedition place*/}
              <div className={`${style.selectGroup} ${style.colSix}`}>
                <label htmlFor={`expLocationShareholder`} className={`${style.labelFontGray}`}>
                  Lugar de expedición.{asterisk}
                </label>
                <input
                  type='text'
                  placeholder='Escribir...'
                  name={`expLocationShareholder`}
                  onChange={e => this.onChangeExtraShareholders(e, i)}
                  className={`${style.inputText} w-100 px-2`}
                  value={
                    this.state.addSupports.extra_legal_representatives[i].expLocationShareholder ||
                    ''
                  }
                />
              </div>
            </div>
          )}
        </div>,
      );
    }
    // RENDER ADITIONAL BANK ACCOUNTS 🏦
    let renderExtraBankAccounts = [];
    if (this.state.addAccount) {
      for (let i = 0; i < this.state.addAccount; i++) {
        renderExtraBankAccounts.push(
          <div>
            {/*ANCHOR first row */}
            <div className={`${style.containerRow} mx-3`}>
              <div className={`${style.selectGroup} ${style.colSix}`}>
                <label htmlFor='bankingEntity' className={`${style.labelFontGray} mb-1`}>
                  Entidad financiera{asterisk}
                </label>
                <select
                  className={`${style.selectInput} w-100 px-2 `}
                  name='bankingEntity'
                  value={this.state.addSupports.extra_bank_account[i].bankingEntity}
                  onChange={e => this.onChangeFormExtraBankAccount(e, i)}
                >
                  <option value=''>seleccionar...</option>
                  {this.props?.bankingEntities?.map(item => {
                    return (
                      <option key={`${item.id}-bank`} value={item.id} className='text-break'>
                        {item.description}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className={`${style.selectGroup} ${style.colTwelve} d-flex`}>
                <div className={`${style.selectGroup} ${style.colSix}`}>
                  <label htmlFor='bankAccountType' className={`${style.labelFontGray} mb-1`}>
                    Tipo de cuenta{asterisk}
                  </label>
                  <select
                    name='bankAccountType'
                    className={`${style.selectInput} w-100 px-2 `}
                    onChange={e => this.onChangeFormExtraBankAccount(e, i)}
                    value={this.state.addSupports.extra_bank_account[i].bankAccountType}
                    style={{ marginRight: '0' }}
                  >
                    <option value=''>seleccionar...</option>
                    <option value='current'>Corriente</option>
                    <option value='investmentFunds'>Fondos de inversión</option>
                    <option value='savings'>Ahorros</option>
                    <option value='fiduciaries'>Fiduciarias</option>
                  </select>
                </div>
                <div className={` ${style.colSix}`}>
                  <label htmlFor='accountNumber' className={`${style.labelFontGray} mb-1`}>
                    No. de la cuenta{asterisk}
                  </label>
                  <NumberFormat
                    allowNegative={false}
                    placeholder='Escribir...'
                    className={`${style.inputText} w-100 px-2`}
                    name='accountNumber'
                    thousandSeparator={this.localeSeparators.groupSeparator}
                    decimalSeparator={this.localeSeparators.decimalSeparator}
                    value={this.state.addSupports.extra_bank_account[i].accountNumber || ''}
                    onValueChange={values => {
                      const { value } = values;
                      let { addSupports } = this.state;
                      addSupports.extra_bank_account[i].accountNumber = value;
                      this.setState({ addSupports });
                    }}
                  />
                </div>
              </div>
              {/* titular */}
              <div className={`${style.selectGroup} ${style.colSix}`}>
                <label htmlFor='accountHolder' className={`${style.labelFontGray} mb-1`}>
                  Titular de la cuenta{asterisk}
                </label>
                <input
                  type='text'
                  placeholder='Escribir...'
                  name='accountHolder'
                  className={`${style.inputText} w-100 px-2`}
                  onChange={e => this.onChangeFormExtraBankAccount(e, i)}
                  value={this.state.addSupports.extra_bank_account[i].accountHolder || ''}
                />
              </div>
            </div>
            {/*ANCHOR second row */}
            <div className={`${style.containerRow} mt-2`}>
              {/* country */}
              <div className={`${style.selectGroup} ${style.colFour}`}>
                <label htmlFor='countryBankInfo' className={`${style.labelFontGray}`}>
                  País
                </label>
                <select
                  name='countryBankInfo'
                  className={`${style.selectInput} w-100 px-2 `}
                  onChange={e => this.onChangeFormExtraBankAccount(e, i)}
                  value={this.state.addSupports?.extra_bank_account[i].countryBankInfo}
                >
                  <option value=''>seleccionar...</option>
                  {optionsCountries}
                </select>
              </div>
              {/* region */}
              <div className={`${style.selectGroup} ${style.colFour}`}>
                <label htmlFor='provinceBankInfo' className={`${style.labelFontGray}`}>
                  Región{asterisk}
                </label>
                <select
                  name='provinceBankInfo'
                  className={`${style.selectInput} w-100 px-2 `}
                  onChange={e => this.onChangeFormExtraBankAccount(e, i)}
                  value={this.state.addSupports?.extra_bank_account[i].provinceBankInfo}
                >
                  <option value=''>seleccionar...</option>
                  {optionsAllProvinces}
                </select>
              </div>
              {/* city bank */}
              <div className={`${style.selectGroup} ${style.colFour}`}>
                <label htmlFor='cityBankInfo' className={`${style.labelFontGray}`}>
                  Ciudad
                </label>
                <select
                  name='cityBankInfo'
                  className={`${style.selectInput} w-100 px-2 `}
                  onChange={e => this.onChangeFormExtraBankAccount(e, i)}
                  value={this.state.addSupports?.extra_bank_account[i].cityBankInfo}
                >
                  <option value=''>seleccionar...</option>
                  {this.props.allCities.map(city => {
                    if (
                      city.province ===
                      parseInt(this.state.addSupports.extra_bank_account[i].provinceBankInfo)
                    ) {
                      return (
                        <option key={city.id} value={city.id}>
                          {city.name}
                        </option>
                      );
                    }
                    return <></>;
                  })}
                </select>
              </div>
              {/* sucursal bancaria */}
              <div className={`${style.colSix}`}>
                <label htmlFor='bankBranch' className={`${style.labelFontGray}`}>
                  Sucursal bancaria{asterisk}
                </label>
                <input
                  type='text'
                  placeholder='Escribir...'
                  name='bankBranch'
                  className={`${style.selectInput} w-100 px-2 `}
                  onChange={e => this.onChangeFormExtraBankAccount(e, i)}
                  value={this.state.addSupports?.extra_bank_account[i].bankBranch || ''}
                />
              </div>
              <div className={`${style.textGroup} ${style.colThree}`}></div>
              <div className={`${style.textGroup} ${style.colThree}`}></div>
            </div>
          </div>,
        );
      }
    }
    // RENDER ANOTHER PRODUCTS ADITIONAL BANK ACCOUNTS 🏦
    let renderExtraForeignBankAccounts = [];
    if (this.state.addBankProduct) {
      for (let i = 0; i < this.state.addBankProduct; i++) {
        renderExtraForeignBankAccounts.push(
          <div>
            <div
              className={`${style.containerRow} mx-3 ${this.state.addSupports.hasForeignCurrency ? '' : 'disabled'}`}
            >
              <div className={`${style.selectGroup} ${style.colSix}`}>
                <label htmlFor='foreignBankingEntity' className={`${style.labelFontGray} mb-1`}>
                  Entidad financiera
                  {this.state.addSupports.hasForeignCurrency ? asterisk : asteriskDisabled}
                </label>
                <input
                  className={`${style.selectInput} w-100 px-2 `}
                  name='foreignBankingEntity'
                  onChange={e => this.onChangeFormExtraBankProducts(e, i)}
                  value={this.state.addSupports.extraForeignAccount[i].foreignBankingEntity}
                  disabled={this.state.addSupports.hasForeignCurrency ? '' : 'disabled'}
                />
              </div>
              <div className={`${style.selectGroup} ${style.colTwelve} d-flex`}>
                <div className={`${style.selectGroup} ${style.colSix}`}>
                  <label htmlFor='foreignBankingType' className={`${style.labelFontGray} mb-1`}>
                    Tipo de cuenta
                    {this.state.addSupports.hasForeignCurrency ? asterisk : asteriskDisabled}
                  </label>
                  <select
                    name='foreignBankingType'
                    className={`${style.selectInput} w-100 px-2 `}
                    onChange={e => this.onChangeFormExtraBankProducts(e, i)}
                    value={this.state.addSupports.extraForeignAccount[i].foreignBankingType}
                    style={{ marginRight: '0' }}
                    disabled={this.state.addSupports.hasForeignCurrency ? '' : 'disabled'}
                  >
                    <option value=''>seleccionar...</option>
                    <option value='current'>Corriente</option>
                    <option value='investmentFunds'>Fondos de inversión</option>
                    <option value='savings'>Ahorros</option>
                    <option value='fiduciaries'>Fiduciarias</option>
                  </select>
                </div>
                <div className={` ${style.colSix}`}>
                  <label
                    htmlFor='foreignNumberBankAccount'
                    className={`${style.labelFontGray} mb-1`}
                  >
                    No. de producto
                    {this.state.addSupports.hasForeignCurrency ? asterisk : asteriskDisabled}
                  </label>
                  <NumberFormat
                    allowNegative={false}
                    placeholder='Escribir...'
                    className={`${style.inputText} w-100 px-2`}
                    name='foreignNumberBankAccount'
                    thousandSeparator={this.localeSeparators.groupSeparator}
                    decimalSeparator={this.localeSeparators.decimalSeparator}
                    value={
                      this.state.addSupports.extraForeignAccount[i].foreignNumberBankAccount || ''
                    }
                    disabled={this.state.addSupports.hasForeignCurrency ? '' : 'disabled'}
                    onValueChange={values => {
                      const { value } = values;
                      let { addSupports } = this.state;
                      addSupports.extraForeignAccount[i].foreignNumberBankAccount = value;
                      this.setState({ addSupports });
                    }}
                  />
                </div>
              </div>
              {/* monto */}
              <div className={`${style.selectGroup} ${style.colSix}`}>
                <label htmlFor='foreignBankAmount' className={`${style.labelFontGray} mb-1`}>
                  Monto{this.state.addSupports.hasForeignCurrency ? asterisk : asteriskDisabled}
                </label>
                <NumberFormat
                  disabled={this.state.addSupports.hasForeignCurrency ? '' : 'disabled'}
                  allowNegative={false}
                  name='foreignBankAmount'
                  placeholder='Escribir...'
                  className={`${style.inputText} w-100 px-2`}
                  suffix={` ${CURRENCY}`}
                  thousandSeparator={this.localeSeparators.groupSeparator}
                  decimalSeparator={this.localeSeparators.decimalSeparator}
                  decimalScale={2}
                  isNumericString
                  value={String(this.state.addSupports.extraForeignAccount[i].foreignBankAmount ?? '')}
                  onValueChange={values => {
                    const { value } = values;
                    let { addSupports } = this.state;
                    addSupports.extraForeignAccount[i].foreignBankAmount = value;
                    this.setState({ addSupports });
                  }}
                />
              </div>
            </div>
            {/*ANCHOR second row */}
            <div className={`${style.containerRow} mt-2`}>
              {/* country */}
              <div className={`${style.selectGroup} ${style.colFour}`}>
                <label htmlFor='foreignBankCountry' className={`${style.labelFontGray}`}>
                  País{this.state.addSupports.hasForeignCurrency ? asterisk : asteriskDisabled}
                </label>
                <select
                  name='foreignBankCountry'
                  className={`${style.selectInput} w-100 px-2 `}
                  onChange={e => this.onChangeFormExtraBankProducts(e, i)}
                  value={this.state.addSupports?.extraForeignAccount[i].foreignBankCountry}
                  disabled={this.state.addSupports.hasForeignCurrency ? '' : 'disabled'}
                >
                  <option value=''>seleccionar...</option>
                  {optionsCountries}
                </select>
              </div>
              {/* region */}
              <div className={`${style.selectGroup} ${style.colFour}`}>
                <label htmlFor='foreignBankCity' className={`${style.labelFontGray}`}>
                  Ciudad{this.state.addSupports.hasForeignCurrency ? asterisk : asteriskDisabled}
                </label>
                <select
                  name='foreignBankCity'
                  className={`${style.selectInput} w-100 px-2 `}
                  onChange={e => this.onChangeFormExtraBankProducts(e, i)}
                  value={this.state.addSupports?.extraForeignAccount[i].foreignBankCity}
                  disabled={this.state.addSupports.hasForeignCurrency ? '' : 'disabled'}
                >
                  <option value=''>seleccionar...</option>
                  {optionsAllCities}
                </select>
              </div>
              <div className={`${style.selectGroup} ${style.colFour}`}>
                <label htmlFor='foreignCoin' className={`${style.labelFontGray}`}>
                  Moneda{this.state.addSupports.hasForeignCurrency ? asterisk : asteriskDisabled}
                </label>
                <select
                  name='foreignCoin'
                  className={`${style.selectInput} w-100 px-2 `}
                  onChange={e => this.onChangeFormExtraBankProducts(e, i)}
                  value={this.state.addSupports?.extraForeignAccount[i].foreignCoin}
                  disabled={this.state.addSupports.hasForeignCurrency ? '' : 'disabled'}
                >
                  <option value=''>seleccionar...</option>
                  <option value='dolar'>Dólares</option>
                  <option value='peso'>Peso colombiano</option>
                </select>
              </div>
              <div className={`${style.colSix}`} />
              <div className={`${style.textGroup} ${style.colThree}`} />
              <div className={`${style.textGroup} ${style.colThree}`} />
            </div>
          </div>,
        );
      }
    }
    //ANCHOR RENDER EXTRA COMMERCIAL REFERENCES 🤑
    let renderExtraCommercialReferences = [];
    if (this.state.addComercialReference) {
      for (let i = 0; i < this.state.addComercialReference; i++) {
        renderExtraCommercialReferences.push(
          <div key={i}>
            <div className={`${style.containerRow} mx-3`}>
              <div className={`${style.selectGroup} ${style.colTwelve}`}>
                <label htmlFor='nameCommercialReference' className={`${style.labelFontGray} mb-1`}>
                  Nombre
                  {this.state.addSupports.hasCommercialReferences ? asterisk : asteriskDisabled}
                </label>
                <input
                  className={`${style.selectInput} w-100 px-2 `}
                  name='nameCommercialReference'
                  onChange={e => this.onChangeExtraCommercialReferences(e, i)}
                  value={
                    this.state.addSupports?.extraCommercialReferences[i]?.nameCommercialReference
                  }
                  disabled={this.state.addSupports.hasCommercialReferences ? '' : 'disabled'}
                />
              </div>
              <div className={`${style.selectGroup} ${style.colTwelve} d-flex`}>
                <div className={`${style.selectGroup} ${style.colThree}`}>
                  <label
                    htmlFor='phoneCommercialReference'
                    className={`${style.labelFontGray} mb-1`}
                  >
                    Teléfono
                    {this.state.addSupports.hasCommercialReferences ? asterisk : asteriskDisabled}
                  </label>
                  <NumberFormat
                    allowNegative={false}
                    placeholder='Escribir...'
                    className={`${style.inputText} w-100 px-2`}
                    name='phoneCommercialReference'
                    thousandSeparator={this.localeSeparators.groupSeparator}
                    decimalSeparator={this.localeSeparators.decimalSeparator}
                    value={
                      this.state.addSupports?.extraCommercialReferences[i]
                        ?.phoneCommercialReference || ''
                    }
                    disabled={this.state.addSupports.hasCommercialReferences ? '' : 'disabled'}
                    onValueChange={values => {
                      const { value } = values;
                      let { addSupports } = this.state;
                      addSupports.extraCommercialReferences[i].phoneCommercialReference = value;
                      this.setState({ addSupports });
                    }}
                  />
                </div>
                <div className={`${style.selectGroup} ${style.colThree}`}>
                  <label
                    htmlFor='cellphoneCommercialReference'
                    className={`${style.labelFontGray} mb-1`}
                  >
                    Celular
                    {this.state.addSupports.hasCommercialReferences ? asterisk : asteriskDisabled}
                  </label>
                  <NumberFormat
                    allowNegative={false}
                    placeholder='Escribir...'
                    className={`${style.inputText} w-100 px-2`}
                    name='cellphoneCommercialReference'
                    thousandSeparator={this.localeSeparators.groupSeparator}
                    decimalSeparator={this.localeSeparators.decimalSeparator}
                    value={
                      this.state.addSupports?.extraCommercialReferences[i]
                        ?.cellphoneCommercialReference || ''
                    }
                    disabled={this.state.addSupports.hasCommercialReferences ? '' : 'disabled'}
                    onValueChange={values => {
                      const { value } = values;
                      let { addSupports } = this.state;
                      addSupports.extraCommercialReferences[i].cellphoneCommercialReference = value;
                      this.setState({ addSupports });
                    }}
                  />
                </div>
                <div className={`${style.selectGroup} ${style.colSix}`}>
                  <label
                    htmlFor='emailCommercialReference'
                    className={`${style.labelFontGray} mb-1`}
                  >
                    Correo electrónico
                    {this.state.addSupports.hasCommercialReferences ? asterisk : asteriskDisabled}
                  </label>
                  <input
                    name='emailCommercialReference'
                    className={`${style.selectInput} w-100 px-2 `}
                    onChange={e => this.onChangeExtraCommercialReferences(e, i)}
                    value={
                      this.state.addSupports?.extraCommercialReferences[i]?.emailCommercialReference
                    }
                    disabled={this.state.addSupports.hasCommercialReferences ? '' : 'disabled'}
                    style={{ marginRight: '0' }}
                    onBlur={() => {
                      if (
                        this.state.addSupports?.extraCommercialReferences[i]
                          ?.emailCommercialReference
                      ) {
                        if (
                          !validateEmail(
                            this.state.addSupports?.extraCommercialReferences[i]
                              ?.emailCommercialReference,
                          )
                        ) {
                          let { addSupports } = this.state;
                          addSupports.extraCommercialReferences[i].emailCommercialReference = '';
                          this.setState({ addSupports });
                          return responseSwal({
                            icon: 'error',
                            title: 'Error',
                            text: 'Este correo no es válido',
                          });
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            {/*ANCHOR second row */}
            <div className={`${style.containerRow} mt-2`}>
              <div className={`${style.colTwelve} d-flex`}>
                {/* country */}
                <div className={`${style.selectGroup} ${style.colFour}`}>
                  <label htmlFor='countryCommercialReference' className={`${style.labelFontGray}`}>
                    País
                    {this.state.addSupports.hasCommercialReferences ? asterisk : asteriskDisabled}
                  </label>
                  <select
                    name='countryCommercialReference'
                    className={`${style.selectInput} w-100 px-2 `}
                    onChange={e => this.onChangeExtraCommercialReferences(e, i)}
                    value={
                      this.state.addSupports?.extraCommercialReferences[i]
                        ?.countryCommercialReference
                    }
                    disabled={this.state.addSupports.hasCommercialReferences ? '' : 'disabled'}
                  >
                    <option value=''>seleccionar...</option>
                    {optionsCountries}
                  </select>
                </div>
                {/* region */}
                <div className={`${style.selectGroup} ${style.colFour}`}>
                  <label htmlFor='provinceCommercialReference' className={`${style.labelFontGray}`}>
                    Región
                    {this.state.addSupports.hasCommercialReferences ? asterisk : asteriskDisabled}
                  </label>
                  <select
                    name='provinceCommercialReference'
                    className={`${style.selectInput} w-100 px-2 `}
                    onChange={e => this.onChangeExtraCommercialReferences(e, i)}
                    value={
                      this.state.addSupports?.extraCommercialReferences[i]
                        ?.provinceCommercialReference
                    }
                    disabled={this.state.addSupports.hasCommercialReferences ? '' : 'disabled'}
                  >
                    <option value=''>seleccionar...</option>
                    {optionsAllProvinces}
                  </select>
                </div>
                <div className={`${style.selectGroupWithOutMargin} ${style.colFour}`}>
                  <label htmlFor='cityCommercialReference' className={`${style.labelFontGray}`}>
                    Ciudad
                    {this.state.addSupports.hasCommercialReferences ? asterisk : asteriskDisabled}
                  </label>
                  <select
                    name='cityCommercialReference'
                    className={`${style.selectInput} w-100 px-2 `}
                    onChange={e => this.onChangeExtraCommercialReferences(e, i)}
                    value={
                      this.state.addSupports?.extraCommercialReferences[i]?.cityCommercialReference
                    }
                    disabled={this.state.addSupports.hasCommercialReferences ? '' : 'disabled'}
                  >
                    <option value=''>seleccionar...</option>
                    {this.props?.allCities?.map(city => {
                      return (
                        <option value={city.id} key={`${city.id}-cityCommercial`}>
                          {city.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className={`${style.colTwelve} d-flex`}>
                <div className={`${style.colSix} mr-3`}>
                  <label
                    htmlFor='addressCommercialReference'
                    className={`${style.labelFontGray} mb-1`}
                  >
                    Dirección
                    {this.state.addSupports.hasCommercialReferences ? asterisk : asteriskDisabled}
                  </label>
                  <input
                    name='addressCommercialReference'
                    className={`${style.selectInput} w-100 px-2 `}
                    onChange={e => this.onChangeExtraCommercialReferences(e, i)}
                    value={
                      this.state.addSupports?.extraCommercialReferences[i]
                        ?.addressCommercialReference
                    }
                    disabled={this.state.addSupports.hasCommercialReferences ? '' : 'disabled'}
                    style={{ marginRight: '0' }}
                  />
                </div>
                <div className={`${style.colSix}`}>
                  <label
                    htmlFor='supportFileCommercialReference'
                    className={`${style.labelFontGray} mb-1`}
                  >
                    Cargar soporte
                    {this.state.addSupports.hasCommercialReferences ? asterisk : asteriskDisabled}
                  </label>
                  <label
                    className={`${style.labelInputFileBank} ${
                      this.state.addSupports?.extraCommercialReferences[i]
                        ?.supportFileCommercialReference
                        ? style.alignBetween
                        : style.flexEnd
                    } ${this.state.addSupports.hasCommercialReferences ? '' : style.disabled}`}
                  >
                    {!!this.state.addSupports?.extraCommercialReferences[i]
                      ?.supportFileCommercialReference && (
                      <div className='algin-self-center my-auto bg-white rounded position-relative d-flex'>
                        <img
                          src={
                            this.state.addSupports?.extraCommercialReferences[i]
                              ?.fileTypesupportFileCommercialReference
                              ? this.state.addSupports?.extraCommercialReferences[i]
                                  ?.fileTypesupportFileCommercialReference
                              : this.getFileIcon(
                                  this.state.addSupports?.extraCommercialReferences[i]
                                    ?.supportFileCommercialReference,
                                )
                          }
                          alt='icon file'
                        />
                        <span
                          className={`${style.secondaryBlue} ${style.labelFilenames} text-truncate`}
                        >
                          {String(
                            this.state.addSupports?.extraCommercialReferences[i]
                              ?.supportFileCommercialReference,
                          ).includes('https://') ? (
                            <a
                              className='text-decoration-none text-primary text-truncate'
                              href={
                                this.state.addSupports?.extraCommercialReferences[i]
                                  ?.supportFileCommercialReference
                              }
                              target='_blank'
                              rel='noreferrer'
                            >
                              {
                                this.state.addSupports?.extraCommercialReferences[i]
                                  ?.supportFileCommercialReference
                              }
                            </a>
                          ) : (
                            this.state.addSupports?.extraCommercialReferences[i]
                              ?.filenamesupportFileCommercialReference
                          )}
                        </span>
                        <img
                          name='supportFileCommercialReference'
                          src={littleClose}
                          alt='descartar'
                          width='16px'
                          className={style.discardFile}
                          onClick={e => this.discardFileExtraCommercialReference(e, i)}
                        />
                      </div>
                    )}
                    <input
                      name='supportFileCommercialReference'
                      id='supportFileCommercialReference'
                      placeholder='Adjuntar...'
                      className={`${style.inputFile}`}
                      onChange={e => this.onChangeDocExtraCommercialReferences(e, i)}
                      disabled={this.state.addSupports.hasCommercialReferences ? '' : 'disabled'}
                      type='file'
                      multiple={false}
                      accept={FILE_ACCEPT_PROVIDER}
                    />
                    <img src={searchFileIcon} alt='agregar archivo' />
                  </label>
                </div>
              </div>
            </div>
          </div>,
        );
      }
    }
    // ANCHOR RENDER RETURN
    return (
      <div className={style.containerAllContent}>
        {this.props.loading ? (
          <div className='loading'>
            <Loader
              visible={this.props.loading}
              type='Oval'
              color='#005DBF'
              height={100}
              width={100}
            />
          </div>
        ) : null}
        <div className='d-flex my-5'>
          <Link
            to={
              !!this?.props?.providerEdit || !!this.props?.providerDraft
                ? '/administracion/proveedores'
                : '/administracion/proveedores/selector'
            }
            className='mt-2'
          >
            <img alt='regresar' src={Back} height={32} width={32} className='hoverPointer' />
          </Link>
          <div className='flex-column'>
            <h1 className='text-start my-0 mx-5'>
              {this.props.providerEdit ? this.state.addSupports.name : 'Nueva vinculación'}
            </h1>
            <p className={`${style.secondaryBlue} mx-5`}>Persona Jurídica</p>
          </div>
        </div>
        <div className='flex-column w-100'>
          <Accordion>
            <div className={style.containerAccordionCheck}>
              <Card className={style.containerCard}>
                <div
                  className={`${style.checkAccordion} ${
                    !!this.state.checkRequiredDocs && style.checkAccordionActive
                  } mx-3 align-top`}
                >
                  {!!this.state.checkRequiredDocs && (
                    <img src={CheckMark} alt='Indicador de completado' />
                  )}
                </div>

                <Accordion.Toggle
                  as={Button}
                  variant=''
                  eventKey='0'
                  className={`${style.itemTextAccordion} ${style.grayBackgroundCards} `}
                  name='collapsedItem'
                  onClick={() => this.setState({ collapsedItem: !this.state.collapsedItem })}
                >
                  <img
                    src={DownArrow}
                    alt='abrir submenu'
                    name='collapsedItem'
                    className={
                      this.state.collapsedItem ? style.downArrowIcon : style.downArrowIconHorizontal
                    }
                  />
                  <span className={style.textDarkGray} name='collapsedItem'>
                    Documentos obligatorios
                  </span>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='0'>
                  <Card.Body className={style.grayBackgroundCards}>
                    {/*ANCHOR input chamber commerce / Cámara de comercio */}
                    <div className='d-flex my-3'>
                      <div
                        className={`${style.checkAccordionItem} ${
                          !!this.state.addSupports.filenamechamberCommerce &&
                          style.checkAccordionItemActive
                        } mx-3 align-middle`}
                      >
                        {!!this.state.addSupports.filenamechamberCommerce && (
                          <div className={style.greenCircle}></div>
                        )}
                      </div>
                      <div className={style.paragraphRequiredDocs}>
                        <p className='my-auto'>
                          Certificado de Cámara de Comercio no mayor a 90 días
                        </p>
                      </div>
                      <div className='flex-column mx-5 align-self-center'>
                        <label className={style.labelInputFile}>
                          <input
                            id='chamberCommerce'
                            type='file'
                            placeholder='Adjuntar'
                            name='filenamechamberCommerce'
                            // value={!!valueChamberCommerce ? valueChamberCommerce : this.state.addSupports.chamberCommerce}
                            accept={FILE_ACCEPT_PROVIDER}
                            className={style.inputFile}
                            onChange={this.onChangeDoc}
                            multiple={false}
                          />
                          <img src={searchFileIcon} alt='agregar archivo' />
                        </label>
                      </div>
                      {!!this.state.addSupports.filenamechamberCommerce && (
                        <div className='algin-self-center my-auto bg-white rounded position-relative d-flex'>
                          <img
                            src={this.state.addSupports.fileTypechamberCommerce}
                            alt='pdf icon'
                          />
                          <span
                            className={`${style.secondaryBlue} ${style.labelFilenames} text-truncate`}
                          >
                            {String(this.state.addSupports.filenamechamberCommerce).includes(
                              'https://',
                            ) ? (
                              <a
                                className='text-decoration-none text-primary'
                                href={this.state.addSupports.filenamechamberCommerce}
                                target='_blank'
                                rel='noreferrer'
                              >
                                {this.state.addSupports.filenamechamberCommerce}
                              </a>
                            ) : (
                              this.state.addSupports.filenamechamberCommerce
                            )}
                            {/* {this.state.addSupports.filenamechamberCommerce} */}
                          </span>
                          <img
                            name='chamberCommerce'
                            src={littleClose}
                            alt='descartar'
                            width='16px'
                            className={style.discardFile}
                            onClick={this.discardFile}
                          />
                        </div>
                      )}
                    </div>

                    {/*ANCHOR ITEM ACCORDION / RUT Photocopy*/}

                    <div className='d-flex my-3'>
                      <div
                        className={`${style.checkAccordionItem} ${
                          !!this.state.addSupports.filenamerutPhotocopy &&
                          style.checkAccordionItemActive
                        } mx-3 align-middle`}
                      >
                        {!!this.state.addSupports.filenamerutPhotocopy && (
                          <div className={style.greenCircle}></div>
                        )}
                      </div>
                      <div className={style.paragraphRequiredDocs}>
                        <p className='my-auto'>Fotocopia de RUT</p>
                      </div>
                      <div className='flex-column mx-5 align-self-center'>
                        <label className={style.labelInputFile}>
                          <input
                            id='rutPhotocopy'
                            type='file'
                            placeholder='Adjuntar'
                            name='filenamerutPhotocopy'
                            //   value={this.state.addSupports.chamberCommerce}
                            accept={FILE_ACCEPT_PROVIDER}
                            className={style.inputFile}
                            onChange={this.onChangeDoc}
                            multiple={false}
                          />
                          <img src={searchFileIcon} alt='agregar archivo' />
                        </label>
                      </div>
                      {!!this.state.addSupports.filenamerutPhotocopy && (
                        <div className='algin-self-center my-auto bg-white rounded position-relative d-flex '>
                          <img src={this.state.addSupports.fileTyperutPhotocopy} alt='pdf icon' />
                          <span
                            className={`${style.secondaryBlue}  ${style.labelFilenames} text-truncate`}
                          >
                            {String(this.state.addSupports.filenamerutPhotocopy).includes(
                              'https://',
                            ) ? (
                              <a
                                className='text-decoration-none text-primary'
                                href={this.state.addSupports.filenamerutPhotocopy}
                                target='_blank'
                                rel='noreferrer'
                              >
                                {this.state.addSupports.filenamerutPhotocopy}
                              </a>
                            ) : (
                              this.state.addSupports.filenamerutPhotocopy
                            )}
                            {/* {this.state.addSupports.filenamerutPhotocopy} */}
                          </span>
                          <img
                            name='rutPhotocopy'
                            src={littleClose}
                            alt='descartar'
                            width='16px'
                            className={style.discardFile}
                            onClick={this.discardFile}
                          />
                        </div>
                      )}
                    </div>

                    {/*ANCHOR ITEM ACCORDION / ID or Doc photocopy of legal representative*/}

                    <div className='d-flex my-3'>
                      <div
                        className={`${style.checkAccordionItem} ${
                          !!this.state.addSupports.filenamedocPhotocopy &&
                          style.checkAccordionItemActive
                        } mx-3 align-middle`}
                      >
                        {!!this.state.addSupports.filenamedocPhotocopy && (
                          <div className={style.greenCircle}></div>
                        )}
                      </div>
                      <div className={style.paragraphRequiredDocs}>
                        <p className='my-auto'>Fotocopia de cédula o ID del Representante Legal</p>
                      </div>
                      <div className='flex-column mx-5 align-self-center'>
                        <label className={style.labelInputFile}>
                          <input
                            id='docPhotocopy'
                            type='file'
                            placeholder='Adjuntar'
                            name='filenamedocPhotocopy'
                            //   value={this.state.addSupports.docPhotocopy}
                            accept={FILE_ACCEPT_PROVIDER}
                            className={style.inputFile}
                            onChange={this.onChangeDoc}
                            multiple={false}
                          />
                          <img src={searchFileIcon} alt='agregar archivo' />
                        </label>
                      </div>
                      {!!this.state.addSupports.filenamedocPhotocopy && (
                        <div className='algin-self-center my-auto bg-white rounded position-relative d-flex '>
                          <img src={this.state.addSupports.fileTypedocPhotocopy} alt='pdf icon' />
                          <span
                            className={`${style.secondaryBlue}  ${style.labelFilenames} text-truncate`}
                          >
                            {String(this.state.addSupports.filenamedocPhotocopy).includes(
                              'https://',
                            ) ? (
                              <a
                                className='text-decoration-none text-primary'
                                href={this.state.addSupports.filenamedocPhotocopy}
                                target='_blank'
                                rel='noreferrer'
                              >
                                {this.state.addSupports.filenamedocPhotocopy}
                              </a>
                            ) : (
                              this.state.addSupports.filenamedocPhotocopy
                            )}
                            {/* {this.state.addSupports.filenamedocPhotocopy} */}
                          </span>
                          <img
                            name='docPhotocopy'
                            src={littleClose}
                            alt='descartar'
                            width='16px'
                            className={style.discardFile}
                            onClick={this.discardFile}
                          />
                        </div>
                      )}
                    </div>

                    {/*ANCHOR ITEM ACCORDION / Certificate of social security payments*/}

                    <div className='d-flex my-3'>
                      <div
                        className={`${style.checkAccordionItem} ${
                          !!this.state.addSupports.filenamesocialSecurity &&
                          style.checkAccordionItemActive
                        } mx-3 align-middle`}
                      >
                        {!!this.state.addSupports.filenamesocialSecurity && (
                          <div className={style.greenCircle}></div>
                        )}
                      </div>
                      <div className={style.paragraphRequiredDocs}>
                        <p className='my-auto'>
                          Certificado de pago de aportes en sistema de salud, pensión, ARL
                        </p>
                      </div>
                      <div className='flex-column mx-5 align-self-center'>
                        <label className={style.labelInputFile}>
                          <input
                            id='socialSecurity'
                            type='file'
                            placeholder='Adjuntar'
                            name='filenamesocialSecurity'
                            //   value={this.state.addSupports.socialSecurity}
                            accept={FILE_ACCEPT_PROVIDER}
                            className={style.inputFile}
                            onChange={this.onChangeDoc}
                            multiple={false}
                          />
                          <img src={searchFileIcon} alt='agregar archivo' />
                        </label>
                      </div>
                      {!!this.state.addSupports.filenamesocialSecurity && (
                        <div className='algin-self-center my-auto bg-white rounded position-relative d-flex '>
                          <img
                            src={this.state.addSupports.fileTypesocialSecurity}
                            alt='pdf icon'
                            className={style.blueIcon}
                          />
                          <span
                            className={`${style.secondaryBlue}  ${style.labelFilenames} text-truncate`}
                          >
                            {String(this.state.addSupports.filenamesocialSecurity).includes(
                              'https://',
                            ) ? (
                              <a
                                className='text-decoration-none text-primary'
                                href={this.state.addSupports.filenamesocialSecurity}
                                target='_blank'
                                rel='noreferrer'
                              >
                                {this.state.addSupports.filenamesocialSecurity}
                              </a>
                            ) : (
                              this.state.addSupports.filenamesocialSecurity
                            )}
                            {/* {this.state.addSupports.filenamesocialSecurity} */}
                          </span>
                          <img
                            name='socialSecurity'
                            src={littleClose}
                            alt='descartar'
                            width='16px'
                            className={style.discardFile}
                            onClick={this.discardFile}
                          />
                        </div>
                      )}
                    </div>

                    {/*ANCHOR ITEM ACCORDION / Rent declaration updated*/}

                    <div className='d-flex my-3'>
                      <div
                        className={`${style.checkAccordionItem} ${
                          !!this.state.addSupports.filenamerentDeclaration &&
                          style.checkAccordionItemActive
                        } mx-3 align-middle`}
                      >
                        {!!this.state.addSupports.filenamerentDeclaration && (
                          <div className={style.greenCircle}></div>
                        )}
                      </div>
                      <div className={style.paragraphRequiredDocs}>
                        <p className='my-auto'>Declaración de Renta actualizada</p>
                      </div>
                      <div className='flex-column mx-5 align-self-center'>
                        <label className={style.labelInputFile}>
                          <input
                            id='rentDeclaration'
                            type='file'
                            placeholder='Adjuntar'
                            name='filenamerentDeclaration'
                            //   value={this.state.addSupports.rentDeclaration}
                            accept={FILE_ACCEPT_PROVIDER}
                            className={style.inputFile}
                            onChange={this.onChangeDoc}
                            multiple={false}
                          />
                          <img src={searchFileIcon} alt='agregar archivo' />
                        </label>
                      </div>
                      {!!this.state.addSupports.filenamerentDeclaration && (
                        <div className='algin-self-center my-auto bg-white rounded position-relative d-flex '>
                          <img
                            src={this.state.addSupports.fileTyperentDeclaration}
                            alt='word icon'
                            className={style.blueIcon}
                          />
                          <span
                            className={`${style.secondaryBlue}  ${style.labelFilenames} text-truncate`}
                          >
                            {String(this.state.addSupports.filenamerentDeclaration).includes(
                              'https://',
                            ) ? (
                              <a
                                className='text-decoration-none text-primary'
                                href={this.state.addSupports.filenamerentDeclaration}
                                target='_blank'
                                rel='noreferrer'
                              >
                                {this.state.addSupports.filenamerentDeclaration}
                              </a>
                            ) : (
                              this.state.addSupports.filenamerentDeclaration
                            )}
                            {/* {this.state.addSupports.filenamerentDeclaration} */}
                          </span>
                          <img
                            name='rentDeclaration'
                            src={littleClose}
                            alt='descartar'
                            width='16px'
                            className={style.discardFile}
                            onClick={this.discardFile}
                          />
                        </div>
                      )}
                    </div>

                    {/*ANCHOR ITEM ACCORDION / Original bank account Certificate*/}

                    <div className='d-flex my-3'>
                      <div
                        className={`${style.checkAccordionItem} ${
                          !!this.state.addSupports.filenamebankAccount &&
                          style.checkAccordionItemActive
                        } mx-3 align-middle`}
                      >
                        {!!this.state.addSupports.filenamebankAccount && (
                          <div className={style.greenCircle}></div>
                        )}
                      </div>
                      <div className={style.paragraphRequiredDocs}>
                        <p className='my-auto'>Certificado de cuenta bancaria original</p>
                      </div>
                      <div className='flex-column mx-5 align-self-center'>
                        <label className={style.labelInputFile}>
                          <input
                            id='bankAccount'
                            type='file'
                            placeholder='Adjuntar'
                            name='filenamebankAccount'
                            //   value={this.state.addSupports.bankAccount}
                            accept={FILE_ACCEPT_PROVIDER}
                            className={style.inputFile}
                            onChange={this.onChangeDoc}
                            multiple={false}
                          />
                          <img src={searchFileIcon} alt='agregar archivo' />
                        </label>
                      </div>
                      {!!this.state.addSupports.filenamebankAccount && (
                        <div
                          className={`algin-self-center my-auto bg-white rounded position-relative d-flex `}
                        >
                          <img
                            src={this.state.addSupports.fileTypebankAccount}
                            alt='word icon'
                            className={style.blueIcon}
                          />
                          <span
                            className={`${style.secondaryBlue} ${style.labelFilenames} text-truncate`}
                          >
                            {String(this.state.addSupports.filenamebankAccount).includes(
                              'https://',
                            ) ? (
                              <a
                                className='text-decoration-none text-primary'
                                href={this.state.addSupports.filenamebankAccount}
                                target='_blank'
                                rel='noreferrer'
                              >
                                {this.state.addSupports.filenamebankAccount}
                              </a>
                            ) : (
                              this.state.addSupports.filenamebankAccount
                            )}
                            {/* {this.state.addSupports.filenamebankAccount} */}
                          </span>
                          <img
                            name='bankAccount'
                            src={littleClose}
                            alt='descartar'
                            width='16px'
                            className={style.discardFile}
                            onClick={this.discardFile}
                          />
                        </div>
                      )}
                    </div>

                    {/*ANCHOR ITEM ACCORDION / Disciplinary records certificate of person */}

                    <div className='d-flex my-3'>
                      <div
                        className={`${style.checkAccordionItem} ${
                          !!this.state.addSupports.filenamedisciplinaryRecords &&
                          style.checkAccordionItemActive
                        } mx-3 align-middle`}
                      >
                        {!!this.state.addSupports.filenamedisciplinaryRecords && (
                          <div className={style.greenCircle}></div>
                        )}
                      </div>
                      <div className={style.paragraphRequiredDocs}>
                        <p className='my-auto'>
                          Certificado de antecedentes diciplinarios de la persona jurídica y rep.
                          legal
                        </p>
                      </div>
                      <div className='flex-column mx-5 align-self-center'>
                        <label className={style.labelInputFile}>
                          <input
                            id='disciplinaryRecords'
                            type='file'
                            placeholder='Adjuntar'
                            name='filenamedisciplinaryRecords'
                            //   value={this.state.addSupports.disciplinaryRecords}
                            accept={FILE_ACCEPT_PROVIDER}
                            className={style.inputFile}
                            onChange={this.onChangeDoc}
                            multiple={false}
                          />
                          <img src={searchFileIcon} alt='agregar archivo' />
                        </label>
                      </div>
                      {!!this.state.addSupports.filenamedisciplinaryRecords && (
                        <div
                          className={`algin-self-center my-auto bg-white rounded position-relative d-flex `}
                        >
                          <img
                            src={this.state.addSupports.fileTypedisciplinaryRecords}
                            alt='word icon'
                            className={style.blueIcon}
                          />
                          <span
                            className={`${style.secondaryBlue} ${style.labelFilenames} text-truncate`}
                          >
                            {String(this.state.addSupports.filenamedisciplinaryRecords).includes(
                              'https://',
                            ) ? (
                              <a
                                className='text-decoration-none text-primary'
                                href={this.state.addSupports.filenamedisciplinaryRecords}
                                target='_blank'
                                rel='noreferrer'
                              >
                                {this.state.addSupports.filenamedisciplinaryRecords}
                              </a>
                            ) : (
                              this.state.addSupports.filenamedisciplinaryRecords
                            )}
                            {/* {this.state.addSupports.filenamedisciplinaryRecords} */}
                          </span>
                          <img
                            name='disciplinaryRecords'
                            src={littleClose}
                            alt='descartar'
                            width='16px'
                            className={style.discardFile}
                            onClick={this.discardFile}
                          />
                        </div>
                      )}
                    </div>

                    {/*ANCHOR ITEM ACCORDION / Fiscal records certificate of person */}

                    <div className='d-flex my-3'>
                      <div
                        className={`${style.checkAccordionItem} ${
                          !!this.state.addSupports.filenamefiscalResponsability &&
                          style.checkAccordionItemActive
                        } mx-3 align-middle`}
                      >
                        {!!this.state.addSupports.filenamefiscalResponsability && (
                          <div className={style.greenCircle}></div>
                        )}
                      </div>
                      <div className={style.paragraphRequiredDocs}>
                        <p className='my-auto'>
                          Certificado de antecedentes de responsabilidad fiscal de la persona
                          jurídica y rep. legal
                        </p>
                      </div>
                      <div className='flex-column mx-5 align-self-center'>
                        <label className={style.labelInputFile}>
                          <input
                            id='fiscalResponsability'
                            type='file'
                            placeholder='Adjuntar'
                            name='filenamefiscalResponsability'
                            //   value={this.state.addSupports.fiscalResponsability}
                            accept={FILE_ACCEPT_PROVIDER}
                            className={style.inputFile}
                            onChange={this.onChangeDoc}
                            multiple={false}
                          />
                          <img src={searchFileIcon} alt='agregar archivo' />
                        </label>
                      </div>
                      {!!this.state.addSupports.filenamefiscalResponsability && (
                        <div
                          className={`algin-self-center my-auto bg-white rounded position-relative d-flex `}
                        >
                          <img
                            src={this.state.addSupports.fileTypefiscalResponsability}
                            alt='word icon'
                            className={style.blueIcon}
                          />
                          <span
                            className={`${style.secondaryBlue} ${style.labelFilenames} text-truncate`}
                          >
                            {String(this.state.addSupports.filenamefiscalResponsability).includes(
                              'https://',
                            ) ? (
                              <a
                                className='text-decoration-none text-primary'
                                href={this.state.addSupports.filenamefiscalResponsability}
                                target='_blank'
                                rel='noreferrer'
                              >
                                {this.state.addSupports.filenamefiscalResponsability}
                              </a>
                            ) : (
                              this.state.addSupports.filenamefiscalResponsability
                            )}
                            {/* {
                              this.state.addSupports.filenamefiscalResponsability
                            } */}
                          </span>
                          <img
                            name='fiscalResponsability'
                            src={littleClose}
                            alt='descartar'
                            width='16px'
                            className={style.discardFile}
                            onClick={this.discardFile}
                          />
                        </div>
                      )}
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
                <div className='position-relative'>
                  <div
                    className={`${style.checkAccordion} ${
                      !!this.state.checkGeneralInformation && style.checkAccordionActive
                    } mx-3 align-top`}
                  >
                    {!!this.state.checkGeneralInformation && (
                      <img src={CheckMark} alt='Indicador de completado' />
                    )}
                  </div>
                </div>
                <Accordion.Toggle
                  as={Button}
                  variant=''
                  eventKey='1'
                  className={`${style.itemTextAccordion} ${style.blueBackgroundCard}`}
                  name='collapsedItemGeneralInformation'
                  onClick={() =>
                    this.setState({
                      collapsedItemGeneralInformation: !this.state.collapsedItemGeneralInformation,
                    })
                  }
                >
                  <img
                    src={DownArrow}
                    alt='abrir submenu'
                    className={
                      this.state.collapsedItemGeneralInformation
                        ? style.downArrowIcon
                        : style.downArrowIconHorizontal
                    }
                  />
                  <span className={style.textDarkBlue}>Información general</span>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='1'>
                  <Card.Body className={style.blueBackgroundCard}>
                    {/*ANCHOR first row */}
                    <div className={style.containerRow}>
                      <div className={`${style.selectGroup} ${style.colSix}`}>
                        <label
                          htmlFor='dilig_date'
                          className={`${style.labelFontGray} d-block my-1`}
                        >
                          Fecha de diligenciamiento
                        </label>
                        <input
                          type='date'
                          name='dilig_date'
                          // onChange={this.onChangeForm}
                          defaultValue={this.state.addSupports.dilig_date}
                          className={`${style.inputText} ${style.registerInputsBlue} w-100 text-primary px-2`}
                          readOnly
                        />
                      </div>
                      <div className={`${style.selectGroup} ${style.colTwelve}`}>
                        <label htmlFor='name' className={`${style.labelFontGray}`}>
                          Razón social<b style={{ color: '#FF8B00' }}>*</b>
                        </label>
                        <input
                          type='text'
                          placeholder='Escribir...'
                          name='name'
                          onChange={this.onChangeForm}
                          className={`${style.selectInput} w-100 px-2`}
                          value={this.state.addSupports?.name || ''}
                        />
                      </div>
                      <div
                        className={`${style.selectGroupWithOutMargin} align-middle ${style.colSix}`}
                      >
                        <label htmlFor='nit' className={`${style.labelFontGray} w-100 px-2`}>
                          NIT<b style={{ color: '#FF8B00' }}>*</b>
                        </label>
                        <div className={`d-flex align-items-center`}>
                          {/* <input
                            type="number"
                            min={0}
                            maxLength="15"
                            name="nit"
                            onChange={e=> {
                              //this.onChangeForm
                              const value = e.target.value;
                              let { addSupports } = this.state;
                              if (String(value).length > 0) {
                                if (value < 0 || this.filterNumbers(value) !== value) {
                                  return responseSwal({
                                    icon: "error",
                                    title: "Error, debe ingresar un valor válido",
                                  });
                                }
                              }
                              addSupports.nit = value;
                              addSupports.verDigit = nitCalculate(value);
                              this.setState({ addSupports });
                              }
                            }
                            value={this.state.addSupports.nit}
                            className={`${style.selectInput} ${style.eightyWith} ${style.notMarginRight}`}
                          /> */}
                          <NumberFormat
                            className={`${style.selectInput} ${style.eightyWith} ${style.notMarginRight} px-2`}
                            name='nit'
                            type='tel'
                            thousandSeparator={this.localeSeparators.groupSeparator}
                            decimalSeparator={this.localeSeparators.decimalSeparator}
                            placeholder='Escribir...'
                            value={this.state.addSupports.nit || ''}
                            isAllowed={values => values.value.length <= 10} //max 10 digitos
                            onValueChange={values => {
                              const { value } = values;
                              let { addSupports } = this.state;
                              if (String(value).length > 0) {
                                if (value < 0 || this.filterNumbers(value) !== value) {
                                  return responseSwal({
                                    icon: 'error',
                                    title: 'Error, debe ingresar un valor válido',
                                  });
                                }
                              }
                              addSupports.nit = value;
                              addSupports.verDigit = nitCalculate(value);
                              this.setState({ addSupports });
                            }}
                          />
                          <hr className={style.nitLine} />
                          <input
                            type='number'
                            maxLength='1'
                            className={`${style.nitDigit} px-2`}
                            //onChange={this.onChageDigitVerify}
                            value={this.state.addSupports.verDigit || ''}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                    {/* second row -> */}
                    <div className={`${style.containerRow} mx-3`}>
                      <div className={`${style.selectGroup} ${style.colSix}`}>
                        <label htmlFor='company_type' className={`${style.labelFontGray}`}>
                          Tipo de empresa{asterisk}
                        </label>
                        <select
                          name='company_type'
                          className={`${style.selectInput} w-100 px-2 `}
                          onChange={this.onChangeForm}
                          value={this.state.addSupports.company_type}
                        >
                          <option value=''>seleccionar...</option>
                          {optionsCompanyTypes}
                        </select>
                      </div>
                      <div className={`${style.selectGroup} ${style.colTwelve} d-flex`}>
                        <div className={`${style.selectGroup} ${style.colSix}`}>
                          <label htmlFor='society_type' className={`${style.labelFontGray}`}>
                            Tipo de sociedad{asterisk}
                          </label>
                          <select
                            name='society_type'
                            className={`${style.selectInput} w-100 px-2 `}
                            onChange={this.onChangeTypeSociety}
                            value={this.state.addSupports.society_type}
                          >
                            <option value=''>seleccionar...</option>
                            {optionsSocietyTypes}
                          </select>
                        </div>
                        <div className={` ${style.colSix}`}>
                          <label
                            htmlFor='personType'
                            className={`${style.labelFontGray} ${
                              this.state.showOtherSociety ? '' : 'disabled'
                            }`}
                          >
                            En caso de otra, ¿cual?*
                          </label>
                          <input
                            type='text'
                            placeholder='Escribir...'
                            disabled={!this.state.showOtherSociety}
                            name='other_society'
                            onChange={this.onChangeForm}
                            className={`${style.inputText} w-100 px-2`}
                            value={this.state.addSupports.other_society || ''}
                          />
                        </div>
                      </div>
                      {/* Email */}
                      <div className={`${style.selectGroup} ${style.colSix}`}>
                        <label htmlFor='email' className={`${style.labelFontGray}`}>
                          Correo comercial{asterisk}
                        </label>
                        <input
                          type='email'
                          placeholder='Escribir...'
                          name='email'
                          className={`${style.inputText} w-100 px-2`}
                          onChange={this.onChangeForm}
                          value={this.state.addSupports.email || ''}
                          onBlur={() => {
                            if (this.state.addSupports.email) {
                              if (!validateEmail(this.state.addSupports.email)) {
                                let { addSupports } = this.state;
                                addSupports.email = '';
                                this.setState({ addSupports });
                                return responseSwal({
                                  icon: 'error',
                                  title: 'Error',
                                  text: 'Este correo no es válido',
                                });
                              }
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className='mt-3'>
                      {/* ANCHOR Información de oficina principal*/}
                      <span>
                        <b className='mr-1'>•</b> Información de <b>Oficina Principal</b>
                        {asterisk}
                      </span>
                    </div>
                    <div className={style.containerRow}>
                      {/* country */}
                      <div className={`${style.selectGroup} ${style.colFour}`}>
                        <label htmlFor='country' className={`${style.labelFontGray}`}>
                          País{asterisk}
                        </label>
                        <select
                          name='country'
                          className={`${style.selectInput} w-100 px-2 `}
                          onChange={this.onChangeSelect}
                          value={this.state.addSupports.country}
                        >
                          <option value=''>seleccionar...</option>
                          {optionsCountries}
                        </select>
                      </div>
                      {/* province */}
                      <div className={`${style.selectGroup} ${style.colFour}`}>
                        <label htmlFor='province' className={`${style.labelFontGray}`}>
                          Departamento{asterisk}
                        </label>
                        <select
                          name='province'
                          className={`${style.selectInput} w-100 px-2 `}
                          onChange={this.onChangeSelect}
                          value={this.state.addSupports.province}
                        >
                          <option value=''>seleccionar...</option>
                          {optionsProvinces}
                        </select>
                      </div>
                      {/* city */}
                      <div className={`${style.selectGroup} ${style.colFour}`}>
                        <label htmlFor='city' className={`${style.labelFontGray}`}>
                          Ciudad{asterisk}
                        </label>
                        <select
                          name='city'
                          className={`${style.selectInput} w-100 px-2 `}
                          onChange={this.onChangeForm}
                          value={this.state.addSupports.city}
                        >
                          <option value=''>seleccionar...</option>
                          {optionsCities}
                        </select>
                      </div>
                      {/* Address */}
                      <div className={`${style.selectGroup} ${style.colSix}`}>
                        <label htmlFor='address' className={`${style.labelFontGray}`}>
                          Dirección{asterisk}
                        </label>
                        <input
                          type='text'
                          placeholder='Escribir...'
                          name='address'
                          className={`${style.selectInput} w-100 px-2 `}
                          onChange={this.onChangeForm}
                          value={this.state.addSupports.address || ''}
                        />
                      </div>
                      {/* Phone */}
                      <div className={`${style.textGroup} ${style.colThree}`}>
                        <label htmlFor='phone' className={`${style.labelFontGray}`}>
                          Teléfono{asterisk}
                        </label>
                        <input
                          type='number'
                          placeholder='Escribir...'
                          min={0}
                          name='phone'
                          onChange={this.onChangeForm}
                          className={`${style.inputText} w-100 px-2`}
                          value={this.state.addSupports.phone || ''}
                        />
                      </div>
                      {/* CellPhone */}
                      <div className={`${style.textGroup} ${style.colThree}`}>
                        <label htmlFor='cellphone' className={`${style.labelFontGray}`}>
                          Celular{asterisk}
                        </label>
                        <input
                          type='number'
                          placeholder='Escribir...'
                          min={0}
                          name='cellphone'
                          onChange={this.onChangeForm}
                          className={`${style.inputText} w-100 px-2`}
                          value={this.state.addSupports.cellphone || ''}
                        />
                      </div>
                    </div>
                    <div className='mt-3'>
                      {/* ANCHOR Información de oficina alternativa | alternative office*/}
                      <span>
                        <b className='mr-1'>•</b> Información de <b>Oficina Alternativa</b>
                      </span>
                    </div>
                    <div className={style.containerRow}>
                      {/* country */}
                      <div className={`${style.selectGroup} ${style.colFour}`}>
                        <label htmlFor='al_country' className={`${style.labelFontGray}`}>
                          País
                        </label>
                        <select
                          name='al_country'
                          className={`${style.selectInput} w-100 px-2 `}
                          onChange={this.onChangeSelect}
                          value={this.state.addSupports.al_country}
                        >
                          <option value=''>seleccionar...</option>
                          {optionsCountries}
                        </select>
                      </div>
                      {/* province */}
                      <div className={`${style.selectGroup} ${style.colFour}`}>
                        <label htmlFor='al_province' className={`${style.labelFontGray}`}>
                          Departamento
                        </label>
                        <select
                          name='al_province'
                          className={`${style.selectInput} w-100 px-2 `}
                          onChange={this.onChangeSelect}
                          value={this.state.addSupports.al_province}
                        >
                          <option value=''>seleccionar...</option>
                          {optionsAlternativeProvinces}
                        </select>
                      </div>
                      {/* city */}
                      <div className={`${style.selectGroup} ${style.colFour}`}>
                        <label htmlFor='al_city' className={`${style.labelFontGray}`}>
                          Ciudad
                        </label>
                        <select
                          name='al_city'
                          className={`${style.selectInput} w-100 px-2 `}
                          onChange={this.onChangeForm}
                          value={this.state.addSupports.al_city}
                        >
                          <option value=''>seleccionar...</option>
                          {optionsAlternativeCities}
                        </select>
                      </div>
                      {/* Address */}
                      <div className={`${style.selectGroup} ${style.colSix}`}>
                        <label htmlFor='al_address' className={`${style.labelFontGray}`}>
                          Dirección
                        </label>
                        <input
                          type='text'
                          placeholder='Escribir...'
                          name='al_address'
                          className={`${style.selectInput} w-100 px-2 `}
                          onChange={this.onChangeForm}
                          value={this.state.addSupports.al_address || ''}
                        />
                      </div>
                      {/* Phone */}
                      <div className={`${style.textGroup} ${style.colThree}`}>
                        <label htmlFor='al_phone' className={`${style.labelFontGray}`}>
                          Teléfono
                        </label>
                        <input
                          type='number'
                          placeholder='Escribir...'
                          min={0}
                          name='al_phone'
                          onChange={this.onChangeForm}
                          className={`${style.inputText} w-100 px-2`}
                          value={this.state.addSupports.al_phone || ''}
                        />
                      </div>
                      {/* CellPhone */}
                      <div className={`${style.textGroup} ${style.colThree}`}>
                        <label htmlFor='cellphone' className={`${style.labelFontGray}`}>
                          Celular
                        </label>
                        <input
                          type='number'
                          placeholder='Escribir...'
                          min={0}
                          name='al_cellphone'
                          onChange={this.onChangeForm}
                          className={`${style.inputText} w-100 px-2`}
                          value={this.state.addSupports.al_cellphone || ''}
                        />
                      </div>
                    </div>
                    <div className={`mt-3`}>
                      {/* ANCHOR Información económica | economy activity*/}
                      <span>
                        <b className='mr-1'>• </b>
                        <b>Actividad económica principal</b>
                      </span>
                    </div>
                    <div className={`${style.containerRow} `}>
                      {/* economy actity */}
                      <div className={`${style.textGroup} ${style.colFour}`}>
                        <label htmlFor='taxPayer' className={`${style.labelFontGray}`}>
                          Tipo de contribuyente{asterisk}
                        </label>
                        <select
                          name='taxPayer'
                          className={`${style.selectInput} w-100 px-2 `}
                          onChange={this.onChangeForm}
                          value={this.state.addSupports.taxPayer}
                        >
                          <option value=''>seleccionar...</option>
                          {optionsPayerTypes}
                        </select>
                      </div>
                      <div
                        className={`${style.textGroup} ${style.colThree}`}
                        style={{ marginTop: '25px', textAlign: 'center' }}
                      >
                        <input
                          className='form-check-input styleCheckInputRatio'
                          type='checkbox'
                          name='autoRetainer'
                          onChange={e => this.handleCheck(e)}
                          checked={this.state.addSupports.autoRetainer}
                        />
                        <label
                          className='form-check-label label_inputs_new3 mx-1 fs-6'
                          htmlFor='radioNiifSelect fs-'
                        >
                          Autoretenedor
                        </label>
                      </div>
                      <div
                        className={`${style.textGroup} ${style.colFour}`}
                        style={{ marginTop: '25px' }}
                      >
                        <input
                          className='form-check-input styleCheckInputRatio'
                          type='checkbox'
                          name='taxManager'
                          onChange={e => this.handleCheck(e)}
                          checked={this.state.addSupports.taxManager}
                        />
                        <label
                          className='form-check-label label_inputs_new3 mx-1 fs-6'
                          htmlFor='radioNiifSelect fs-'
                        >
                          Responsable de impuestos
                        </label>
                      </div>
                    </div>

                    {/* aqui hacer */}

                    <div
                      style={{ gridTemplateColumns: '200px 1fr', padding: '0px 30px 0px 10px' }}
                      className={`d-grid gap-4 mt-2 align-items-center`}
                    >
                      <div style={{ marginTop: '15px' }}>
                        <input
                          className='form-check-input styleCheckInputRatio'
                          type='checkbox'
                          name='checkServiceProvider'
                          onChange={e => {
                            this.handleCheck(e, 'offers_services');

                            if (!e.target.checked) {
                              this.setState({
                                ...this.state,
                                addSupports: {
                                  ...this.state.addSupports,
                                  optionSelectedLaboratories: [],
                                  optionSelectedTypeServices: [],
                                },
                              });
                            }
                          }}
                          checked={
                            this.state.addSupports.checkServiceProvider ||
                            this.state.addSupports.offers_services
                          }
                        />
                        <label
                          className='form-check-label label_inputs_new3 mx-1 fs-6'
                          htmlFor='radioNiifSelect fs-'
                        >
                          Prestador de servicios
                        </label>
                      </div>

                      {this.state.addSupports.checkServiceProvider ||
                      this.state.addSupports.offers_services ? (
                        <div
                          className='gap-4 d-grid align-items-center'
                          style={{ gridTemplateColumns: '1fr 1fr' }}
                        >
                          <div>
                            <label htmlFor='taxPayer' className={`${style.labelFontGray}`}>
                              Tipo de servicios{asterisk}
                            </label>

                            <MultiSelect
                              key='1'
                              size='small'
                              options={this?.props?.listTypeServices?.map(service => ({
                                label: service?.name,
                                value: service?.id,
                                tag: service?.tag,
                              }))}
                              isSelectAll={true}
                              value={this.state.addSupports.optionSelectedTypeServices} //xx
                              menuPlacement={'bottom'}
                              nameList={'Servicio'}
                              accentuation={'o'}
                              onChange={elements => {
                                this.setState({
                                  ...this.state,
                                  addSupports: {
                                    ...this.state.addSupports,
                                    optionSelectedTypeServices: elements,
                                  },
                                });
                              }}
                            />
                          </div>

                          <div>
                            <label htmlFor='taxPayer' className={`${style.labelFontGray}`}>
                              Laboratorios
                            </label>

                            <MultiSelect
                              key='2'
                              size='small'
                              options={this?.props?.listLaboratories?.map(lab => ({
                                label: lab?.name,
                                value: lab?.id,
                              }))}
                              isSelectAll={true}
                              value={this.state.addSupports.optionSelectedLaboratories}
                              menuPlacement={'bottom'}
                              nameList={'Laboratorio'}
                              accentuation={'o'}
                              onChange={elements => {
                                this.setState({
                                  ...this.state,
                                  addSupports: {
                                    ...this.state.addSupports,
                                    optionSelectedLaboratories: elements,
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>

                    <div className={`${style.containerRow} mt-3`}>
                      {/* economy actity */}
                      <div className={`${style.selectGroup} ${style.colEighteen}`}>
                        <label htmlFor='ciiu' className={`${style.labelFontGray}`}>
                          Código CIIU y actividad económica{asterisk}
                        </label>
                        <select
                          name='ciiu'
                          className={`${style.selectInput} w-100 px-2 `}
                          onChange={this.onChangeForm}
                          value={this.state.addSupports.ciiu}
                        >
                          <option value=''>seleccionar...</option>
                          {optionsBusinessActivities}
                        </select>
                      </div>
                      {/* occupation */}
                      <div className={`${style.selectGroup} ${style.colSix}`}>
                        <label htmlFor='classification' className={`${style.labelFontGray}`}>
                          Clasificación{asterisk}
                        </label>
                        <select
                          name='classification'
                          className={`${style.selectInput} w-100 px-2 `}
                          onChange={this.onChangeForm}
                          value={this.state.addSupports.classification}
                        >
                          <option value=''>seleccionar...</option>
                          {optionsOccupation}
                        </select>
                      </div>
                    </div>
                    <div className='d-flex my-3 container-fluid'>
                      <button onClick={this.addBusinessActivity}>
                        <img
                          src={grayAdd}
                          alt='Agregar actividad'
                          className={style.addActiviyIconWithOutMargin}
                        />
                        <span>Agregar otra actividad</span>
                      </button>
                    </div>
                    {renderEconomicActivity}
                  </Card.Body>
                </Accordion.Collapse>
                <div className='position-relative'>
                  <div
                    className={`${style.checkAccordion} ${
                      !!this.state.checkLegalRepresent && style.checkAccordionActive
                    } mx-3 align-top`}
                  >
                    {!!this.state.checkLegalRepresent && (
                      <img src={CheckMark} alt='Indicador de completado' />
                    )}
                  </div>
                </div>
                <Accordion.Toggle
                  as={Button}
                  variant=''
                  eventKey='2'
                  className={`${style.itemTextAccordion} ${style.blueBackgroundCard}`}
                  name='collapsedItemLegRepresentInformation'
                  onClick={() =>
                    this.setState({
                      collapsedItemLegRepresentInformation:
                        !this.state.collapsedItemLegRepresentInformation,
                    })
                  }
                >
                  <img
                    src={DownArrow}
                    alt='abrir submenu'
                    className={
                      this.state.collapsedItemLegRepresentInformation
                        ? style.downArrowIcon
                        : style.downArrowIconHorizontal
                    }
                  />
                  <span className={style.textDarkBlue}>Representante Legal</span>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='2'>
                  <Card.Body className={style.blueBackgroundCard}>
                    {/*ANCHOR first row */}
                    <div className={`${style.containerRow} mx-3`}>
                      <div className={`${style.selectGroup} ${style.colSix}`}>
                        <label htmlFor='firstNameLglRepresent' className={`${style.labelFontGray}`}>
                          Primer nombre{asterisk}
                        </label>
                        <input
                          type='text'
                          placeholder='Escribir...'
                          name='firstNameLglRepresent'
                          onChange={this.onChangeForm}
                          className={`${style.inputText} w-100 px-2`}
                          value={this.state.addSupports?.firstNameLglRepresent || ''}
                        />
                      </div>
                      <div className={`${style.selectGroup} ${style.colTwelve} d-flex`}>
                        <div className={`${style.selectGroup} ${style.colSix}`}>
                          <label
                            htmlFor='secondNameLglRepresent'
                            className={`${style.labelFontGray}`}
                          >
                            Segundo nombre
                          </label>
                          <input
                            name='secondNameLglRepresent'
                            placeholder='Escribir...'
                            className={`${style.inputText} w-100 px-2`}
                            onChange={this.onChangeForm}
                            value={this.state.addSupports?.secondNameLglRepresent || ''}
                          />
                        </div>
                        <div className={` ${style.colSix}`}>
                          <label
                            htmlFor='firstSurnameLglRepresent'
                            className={`${style.labelFontGray}`}
                          >
                            Primer apellido{asterisk}
                          </label>
                          <input
                            type='text'
                            placeholder='Escribir...'
                            name='firstSurnameLglRepresent'
                            onChange={this.onChangeForm}
                            className={`${style.inputText} w-100 px-2`}
                            value={this.state.addSupports?.firstSurnameLglRepresent || ''}
                          />
                        </div>
                      </div>
                      {/* second surname */}
                      <div className={`${style.selectGroup} ${style.colSix}`}>
                        <label
                          htmlFor='secondSurnameLglRepresent'
                          className={`${style.labelFontGray}`}
                        >
                          Segundo apellido
                        </label>
                        <input
                          type='text'
                          placeholder='Escribir...'
                          name='secondSurnameLglRepresent'
                          className={`${style.inputText} w-100 px-2`}
                          onChange={this.onChangeForm}
                          value={this.state.addSupports?.secondSurnameLglRepresent || ''}
                        />
                      </div>
                    </div>
                    {/*ANCHOR second row */}
                    <div className={`${style.containerRow} mt-2`}>
                      {/* doc type */}
                      <div className={`${style.selectGroup} ${style.colFour}`}>
                        <label htmlFor='docTypeLglRepresent' className={`${style.labelFontGray}`}>
                          Tipo de doc.{asterisk}
                        </label>
                        <select
                          name='docTypeLglRepresent'
                          className={`${style.selectInput} w-100 px-2 `}
                          onChange={this.onChangeSelect}
                          value={this.state.addSupports?.docTypeLglRepresent}
                        >
                          <option value=''>seleccionar...</option>
                          <option value='cc'>C.C</option>
                          <option value='ti'>T.I</option>
                          <option value='ce'>C.E</option>
                          <option value='rc'>R.C</option>
                          <option value='ms'>M.S</option>
                          <option value='as_'>A.S</option>
                          <option value='nu'>N.U</option>
                          <option value='pa'>PA</option>
                        </select>
                      </div>
                      {/* número de documento */}
                      <div className={`${style.selectGroup} ${style.colFour}`}>
                        <label htmlFor='docNumLglRepresent' className={`${style.labelFontGray}`}>
                          Número de doc.{asterisk}
                        </label>
                        <input
                          name='docNumLglRepresent'
                          placeholder='Escribir...'
                          className={`${style.inputText} w-100 px-2`}
                          value={formatteDocument(this.state.addSupports?.docNumLglRepresent || '').format}
                          onChange={e => {
                            this.setState({
                              ...this.state,
                              addSupports: {
                                ...this.state.addSupports,
                                docNumLglRepresent: formatteDocument(e.target.value).value,
                              },
                            });
                          }}
                        />
                      </div>
                      {/* Expedition place */}
                      <div className={`${style.selectGroup} ${style.colFour}`}>
                        <label
                          htmlFor='expLocationLglRepresent'
                          className={`${style.labelFontGray}`}
                        >
                          Lugar de exp.{asterisk}
                        </label>
                        <input
                          name='expLocationLglRepresent'
                          placeholder='Escribir...'
                          className={`${style.inputText} w-100 px-2`}
                          onChange={this.onChangeForm}
                          value={this.state.addSupports?.expLocationLglRepresent}
                        />
                      </div>
                      {/* Address */}
                      <div className={`${style.colSix}`}>
                        <label htmlFor='emailLglRepresent' className={`${style.labelFontGray}`}>
                          Correo electrónico{asterisk}
                        </label>
                        <input
                          type='email'
                          placeholder='Escribir...'
                          name='emailLglRepresent'
                          className={`${style.inputText} w-100 px-2`}
                          onChange={this.onChangeForm}
                          value={this.state.addSupports?.emailLglRepresent || ''}
                          onBlur={() => {
                            if (this.state.addSupports.emailLglRepresent) {
                              if (!validateEmail(this.state.addSupports.emailLglRepresent)) {
                                let { addSupports } = this.state;
                                addSupports.emailLglRepresent = '';
                                this.setState({ addSupports });
                                return responseSwal({
                                  icon: 'error',
                                  title: 'Error',
                                  text: 'Este correo no es válido',
                                });
                              }
                            }
                          }}
                        />
                      </div>
                      {/* Phone */}
                      <div className={`${style.textGroup} ${style.colThree}`}>
                        <label htmlFor='phoneLglRepresent' className={`${style.labelFontGray}`}>
                          Teléfono{asterisk}
                        </label>
                        <NumberFormat
                          name='phoneLglRepresent'
                          allowNegative={false}
                          placeholder='Escribir...'
                          className={`${style.inputText} w-100 px-2`}
                          thousandSeparator={this.localeSeparators.groupSeparator}
                          decimalSeparator={this.localeSeparators.decimalSeparator}
                          value={this.state.addSupports?.phoneLglRepresent || ''}
                          onValueChange={values => {
                            this.setState({
                              ...this.state,
                              addSupports: {
                                ...this.state.addSupports,
                                phoneLglRepresent: values.value,
                              },
                            });
                          }}
                        />
                      </div>
                      {/* CellPhone */}
                      <div className={`${style.textGroup} ${style.colThree}`}>
                        <label htmlFor='cellphoneLglRepresent' className={`${style.labelFontGray}`}>
                          Celular{asterisk}
                        </label>
                        <NumberFormat
                          name='cellphoneLglRepresent'
                          allowNegative={false}
                          placeholder='Escribir...'
                          className={`${style.inputText} w-100 px-2`}
                          thousandSeparator={this.localeSeparators.groupSeparator}
                          decimalSeparator={this.localeSeparators.decimalSeparator}
                          value={this.state.addSupports?.cellphoneLglRepresent || ''}
                          onValueChange={values => {
                            this.setState({
                              ...this.state,
                              addSupports: {
                                ...this.state.addSupports,
                                cellphoneLglRepresent: values.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                    {/* ANCHOR alternative address - legal represent */}
                    <div className='mt-3 mx-3 align-middle'>
                      <input
                        className='form-check-input styleCheckInputRatio'
                        type='checkbox'
                        name='hasAlternativeAddress'
                        onChange={e => this.handleCheck(e)}
                        checked={this.state.addSupports.hasAlternativeAddress}
                      />
                      <label
                        className='form-check-label label_inputs_new3 mx-1 fs-6'
                        htmlFor='radioNiifSelect fs-'
                      >
                        Dirección alternativa
                      </label>
                    </div>
                    <div className={style.containerRow}>
                      {/* country */}
                      <div
                        className={`${style.selectGroup} ${style.colFour} ${
                          this.state.addSupports.hasAlternativeAddress === true ? '' : 'disabled'
                        }`}
                      >
                        <label htmlFor='al_country_lgl_rpst' className={`${style.labelFontGray}`}>
                          País
                        </label>
                        <select
                          name='al_country_lgl_rpst'
                          className={`${style.selectInput} w-100 px-2 `}
                          onChange={this.onChangeSelect}
                          value={this.state.addSupports?.al_country_lgl_rpst}
                          disabled={
                            this.state.addSupports.hasAlternativeAddress === true ? '' : 'disabled'
                          }
                        >
                          <option value=''>seleccionar...</option>
                          {optionsCountries}
                        </select>
                      </div>
                      {/* province */}
                      <div
                        className={`${style.selectGroup} ${style.colFour} ${
                          this.state.addSupports.hasAlternativeAddress === true ? '' : 'disabled'
                        }`}
                      >
                        <label htmlFor='al_province_lgl_rpst' className={`${style.labelFontGray}`}>
                          Departamento
                        </label>
                        <select
                          name='al_province_lgl_rpst'
                          className={`${style.selectInput} w-100 px-2 `}
                          onChange={this.onChangeSelect}
                          value={this.state.addSupports?.al_province_lgl_rpst}
                          disabled={
                            this.state.addSupports.hasAlternativeAddress === true ? '' : 'disabled'
                          }
                        >
                          <option value=''>seleccionar...</option>
                          {optionsAllProvinces}
                        </select>
                      </div>
                      {/* city */}
                      <div
                        className={`${style.selectGroup} ${style.colFour} ${
                          this.state.addSupports.hasAlternativeAddress === true ? '' : 'disabled'
                        }`}
                      >
                        <label htmlFor='al_city_lgl_rpst' className={`${style.labelFontGray}`}>
                          Ciudad
                        </label>
                        <select
                          name='al_city_lgl_rpst'
                          className={`${style.selectInput} w-100 px-2 `}
                          onChange={this.onChangeForm}
                          value={this.state.addSupports?.al_city_lgl_rpst}
                          disabled={
                            this.state.addSupports.hasAlternativeAddress === true ? '' : 'disabled'
                          }
                        >
                          <option value=''>seleccionar...</option>
                          {optionsAllCities}
                        </select>
                      </div>
                      {/* Address */}
                      <div
                        className={`${style.selectGroup} ${style.colSix} ${
                          this.state.addSupports.hasAlternativeAddress === true ? '' : 'disabled'
                        }`}
                      >
                        <label htmlFor='al_address_lgl_rpst' className={`${style.labelFontGray}`}>
                          Dirección
                        </label>
                        <input
                          type='text'
                          placeholder='Escribir...'
                          name='al_address_lgl_rpst'
                          className={`${style.selectInput} w-100 px-2 `}
                          onChange={this.onChangeForm}
                          value={this.state.addSupports?.al_address_lgl_rpst || ''}
                          disabled={
                            this.state.addSupports.hasAlternativeAddress === true ? '' : 'disabled'
                          }
                        />
                      </div>
                      {/* Phone */}
                      <div
                        className={`${style.textGroup} ${style.colThree} ${
                          this.state.addSupports.hasAlternativeAddress === true ? '' : 'disabled'
                        }`}
                      >
                        <label htmlFor='al_phone_lgl_rpst' className={`${style.labelFontGray}`}>
                          Teléfono
                        </label>
                        <input
                          type='number'
                          placeholder='Escribir...'
                          min={0}
                          name='al_phone_lgl_rpst'
                          onChange={this.onChangeForm}
                          className={`${style.inputText} w-100 px-2`}
                          value={this.state.addSupports?.al_phone_lgl_rpst || ''}
                          disabled={
                            this.state.addSupports.hasAlternativeAddress === true ? '' : 'disabled'
                          }
                        />
                      </div>
                      {/* CellPhone */}
                      <div
                        className={`${style.textGroup} ${style.colThree} ${
                          this.state.addSupports.hasAlternativeAddress === true ? '' : 'disabled'
                        }`}
                      >
                        <label htmlFor='al_cellphone_lgl_rpst' className={`${style.labelFontGray}`}>
                          Celular
                        </label>
                        <input
                          type='number'
                          placeholder='Escribir...'
                          min={0}
                          name='al_cellphone_lgl_rpst'
                          onChange={this.onChangeForm}
                          className={`${style.inputText} w-100 px-2`}
                          value={this.state.addSupports?.al_cellphone_lgl_rpst || ''}
                          disabled={
                            this.state.addSupports.hasAlternativeAddress === true ? '' : 'disabled'
                          }
                        />
                      </div>
                    </div>
                    <div className='mt-3 mx-1'>
                      {/* ANCHOR Información de accionista*/}
                      <span>
                        <b className='mr-1'>•</b>
                        <b>
                          Información de Accionista <img src={Menos} width='16' alt='info' />
                        </b>
                      </span>
                    </div>
                    <div className={`${style.containerRow} mt-2`}>
                      {/* Person type - shareholder */}
                      <div className={`${style.selectGroup} ${style.selectPersonTypeLglRpst}`}>
                        <label
                          htmlFor='personType_shareholder_rpst'
                          className={`${style.labelFontGray} my-1`}
                        >
                          Tipo de persona
                        </label>
                        <select
                          name='personType_shareholder_rpst'
                          className={`${style.selectInput} w-100 px-2 `}
                          onChange={this.onChangeSelect}
                          value={this.state.addSupports?.personType_shareholder_rpst}
                        >
                          <option value=''>seleccionar...</option>
                          <option value='legal_person'>{ProviderType.legal_person}</option>
                          <option value='natural'>{ProviderType.natural}</option>
                        </select>
                      </div>
                    </div>
                    {this.state.addSupports?.personType_shareholder_rpst === 'natural' && (
                      <div className={`${style.containerRow} mt-2`}>
                        {/* first name shareholder */}
                        <div className={`${style.selectGroup} ${style.colSix}`}>
                          <label
                            htmlFor='fisrtNameShareholder_lgl_rpst'
                            className={`${style.labelFontGray}`}
                          >
                            Primer nombre{asterisk}
                          </label>
                          <input
                            type='text'
                            placeholder='Escribir...'
                            name='fisrtNameShareholder_lgl_rpst'
                            className={`${style.inputText} w-100 px-2`}
                            onChange={this.onChangeForm}
                            value={this.state.addSupports?.fisrtNameShareholder_lgl_rpst || ''}
                          />
                        </div>
                        {/* first surname of shareholder in legal represent  */}
                        <div className={`${style.textGroup} ${style.colSix}`}>
                          <label
                            htmlFor='fisrtSurnameShareholder_lgl_rpst'
                            className={`${style.labelFontGray}`}
                          >
                            Primer apellido{asterisk}
                          </label>
                          <input
                            type='text'
                            onChange={this.onChangeForm}
                            className={`${style.inputText} w-100 px-2`}
                            name='fisrtSurnameShareholder_lgl_rpst'
                            value={this.state.addSupports?.fisrtSurnameShareholder_lgl_rpst}
                          />
                        </div>
                        {/* doc type of shareholder in legal represent */}
                        <div className={`${style.selectGroup} ${style.colFour}`}>
                          <label
                            htmlFor='docTypeShareholder_lgl_rpst'
                            className={`${style.labelFontGray}`}
                          >
                            Tipo de doc{asterisk}
                          </label>
                          <select
                            name='docTypeShareholder_lgl_rpst'
                            className={`${style.selectInput} w-100 px-2 `}
                            onChange={this.onChangeSelect}
                            value={this.state.addSupports?.docTypeShareholder_lgl_rpst}
                          >
                            <option value=''>seleccionar...</option>
                            <option value='cc'>C.C</option>
                            <option value='ti'>T.I</option>
                            <option value='ce'>C.E</option>
                            <option value='rc'>R.C</option>
                            <option value='ms'>M.S</option>
                            <option value='as_'>A.S</option>
                            <option value='nu'>N.U</option>
                            <option value='pa'>PA</option>
                          </select>
                        </div>
                        {/* number of document from shareholder in legal represent */}
                        <div className={`${style.selectGroup} ${style.colFour}`}>
                          <label
                            htmlFor='docNumShareholder_lgl_rpst'
                            className={`${style.labelFontGray}`}
                          >
                            Número de doc.{asterisk}
                          </label>
                          <input
                            name='docNumShareholder_lgl_rpst'
                            placeholder='Escribir...'
                            className={`${style.inputText} w-100 px-2`}
                            value={formatteDocument(this.state.addSupports?.docNumShareholder_lgl_rpst || '').format}
                            onChange={e => {
                              this.setState({
                                ...this.state,
                                addSupports: {
                                  ...this.state.addSupports,
                                  docNumShareholder_lgl_rpst: formatteDocument(e.target.value).value,
                                },
                              });
                            }}
                          />
                        </div>
                        {/* expedition place*/}
                        <div className={`${style.selectGroup} ${style.colFour}`}>
                          <label
                            htmlFor='expLocationShareholder'
                            className={`${style.labelFontGray}`}
                          >
                            Lugar de exp.{asterisk}
                          </label>
                          <input
                            type='text'
                            placeholder='Escribir...'
                            name='expLocationShareholder'
                            onChange={this.onChangeForm}
                            className={`${style.inputText} w-100 px-2`}
                            value={this.state.addSupports?.expLocationShareholder || ''}
                          />
                        </div>
                      </div>
                    )}
                    {this.state.addSupports?.personType_shareholder_rpst === 'legal_person' && (
                      <div className={`${style.containerRow} mt-2`}>
                        {/* buisiness name shareholder */}
                        <div className={`${style.selectGroup} ${style.colTwelve}`}>
                          <label
                            htmlFor='businessName_lgl_rprst'
                            className={`${style.labelFontGray}`}
                          >
                            Razón social{asterisk}
                          </label>
                          <input
                            type='text'
                            placeholder='Escribir...'
                            name='businessName_lgl_rprst'
                            className={`${style.inputText} w-100 px-2`}
                            onChange={this.onChangeForm}
                            value={this.state.addSupports?.businessName_lgl_rprst || ''}
                          />
                        </div>
                        {/* number of document from shareholder in legal represent */}
                        <div className={`${style.selectGroup} ${style.colSix}`}>
                          <label
                            htmlFor='nitShareholder_lgl_rpst'
                            className={`${style.labelFontGray}`}
                          >
                            NIT{asterisk}
                          </label>
                          <NumberFormat
                            name='nitShareholder_lgl_rpst'
                            allowNegative={false}
                            placeholder='Escribir...'
                            className={`${style.inputText} w-100 px-2`}
                            thousandSeparator={this.localeSeparators.groupSeparator}
                            decimalSeparator={this.localeSeparators.decimalSeparator}
                            value={this.state.addSupports?.nitShareholder_lgl_rpst || ''}
                            onValueChange={values => {
                              this.setState({
                                ...this.state,
                                addSupports: {
                                  ...this.state.addSupports,
                                  nitShareholder_lgl_rpst: values.value,
                                },
                              });
                            }}
                          />
                        </div>
                        {/* expedition place*/}
                        <div className={`${style.selectGroup} ${style.colSix}`}>
                          <label
                            htmlFor='expLocationShareholder'
                            className={`${style.labelFontGray}`}
                          >
                            Lugar de expedición.{asterisk}
                          </label>
                          <input
                            type='text'
                            placeholder='Escribir...'
                            name='expLocationShareholder'
                            onChange={this.onChangeForm}
                            className={`${style.inputText} w-100 px-2`}
                            value={this.state.addSupports?.expLocationShareholder || ''}
                          />
                        </div>
                      </div>
                    )}
                    <div className='d-flex my-3 container-fluid'>
                      <button onClick={this.addShareholoder}>
                        <img
                          src={grayAdd}
                          alt='Agregar actividad'
                          className={style.addActiviyIconWithOutMargin}
                        />
                        <span>Agregar otro accionista</span>
                      </button>
                    </div>
                    {renderShareHolders}
                  </Card.Body>
                </Accordion.Collapse>
                <div className='position-relative'>
                  <div
                    className={`${style.checkAccordion} ${
                      !!this.state.checkFinancialInformation && style.checkAccordionActive
                    } mx-3 align-top`}
                  >
                    {!!this.state.checkFinancialInformation && (
                      <img src={CheckMark} alt='Indicador de completado' />
                    )}
                  </div>
                </div>
                <Accordion.Toggle
                  as={Button}
                  variant=''
                  eventKey='3'
                  className={`${style.itemTextAccordion} ${style.blueBackgroundCard}`}
                  name='collapsedItemFinancialInformation'
                  onClick={() =>
                    this.setState({
                      collapsedItemFinancialInformation:
                        !this.state.collapsedItemFinancialInformation,
                    })
                  }
                >
                  <img
                    src={DownArrow}
                    alt='abrir submenu'
                    className={
                      this.state.collapsedItemFinancialInformation
                        ? style.downArrowIcon
                        : style.downArrowIconHorizontal
                    }
                  />
                  <span className={style.textDarkBlue}>Información financiera</span>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='3'>
                  <Card.Body className={style.blueBackgroundCard}>
                    {/*ANCHOR first row */}
                    <div className={`${style.containerRow} mx-3`}>
                      <div className={`${style.colTwelve} d-flex`}>
                        <div className={`d-flex flex-column ${style.colSix}`}>
                          <label htmlFor='totalAssets' className={`${style.labelFontGray} mb-1`}>
                            Total activos{asterisk}
                          </label>
                          <NumberFormat
                            allowNegative={false}
                            name='totalAssets'
                            placeholder='Escribir...'
                            className={`${style.inputText} px-2`}
                            suffix={` ${CURRENCY}`}
                            thousandSeparator={this.localeSeparators.groupSeparator}
                            decimalSeparator={this.localeSeparators.decimalSeparator}
                            decimalScale={2}
                            isNumericString
                            value={String(this.state.addSupports.totalAssets ?? '')}
                            onValueChange={values => {
                              const { value } = values;
                              let { addSupports } = this.state;
                              addSupports.totalAssets = value;
                              this.setState({ addSupports });
                            }}
                          />
                        </div>
                        <div className={`d-flex flex-column ${style.colSix} ${style.ml1rem}`}>
                          <label htmlFor='totalPassives' className={`${style.labelFontGray} mb-1`}>
                            Total pasivos{asterisk}
                          </label>
                          <NumberFormat
                            allowNegative={false}
                            placeholder='Escribir...'
                            className={`${style.inputText} px-2`}
                            name='totalPassives'
                            suffix={` ${CURRENCY}`}
                            thousandSeparator={this.localeSeparators.groupSeparator}
                            decimalSeparator={this.localeSeparators.decimalSeparator}
                            decimalScale={2}
                            isNumericString
                            value={String(this.state.addSupports.totalPassives ?? '')}
                            onValueChange={values => {
                              const { value } = values;
                              let { addSupports } = this.state;
                              addSupports.totalPassives = value;
                              this.setState({ addSupports });
                            }}
                          />
                        </div>
                      </div>
                      <div className={`d-flex flex-column ${style.colSix}`}>
                        <label htmlFor='patrimony' className={`${style.labelFontGray} mb-1`}>
                          Patrimonios{asterisk}
                        </label>
                        <NumberFormat
                          allowNegative={false}
                          placeholder='Escribir...'
                          name='patrimony'
                          className={`${style.inputText} px-2`}
                          suffix={` ${CURRENCY}`}
                          thousandSeparator={this.localeSeparators.groupSeparator}
                          decimalSeparator={this.localeSeparators.decimalSeparator}
                          decimalScale={2}
                          isNumericString
                          value={String(this.state.addSupports.patrimony ?? '')}
                          onValueChange={values => {
                            const { value } = values;
                            let { addSupports } = this.state;
                            addSupports.patrimony = value;
                            this.setState({ addSupports });
                          }}
                        />
                      </div>
                    </div>
                    {/*ANCHOR second row */}
                    <div className={`${style.containerRow} mx-3 mt-3`}>
                      <div className={`${style.colTwelve} d-flex`}>
                        <div className={`d-flex flex-column ${style.colSix}`}>
                          <label htmlFor='monthlyIncome' className={`${style.labelFontGray} mb-1`}>
                            Ingresos mensuales{asterisk}
                          </label>
                          <NumberFormat
                            allowNegative={false}
                            placeholder='Escribir...'
                            className={`${style.inputText} px-2`}
                            name='monthlyIncome'
                            suffix={` ${CURRENCY}`}
                            thousandSeparator={this.localeSeparators.groupSeparator}
                            decimalSeparator={this.localeSeparators.decimalSeparator}
                            decimalScale={2}
                            isNumericString
                            value={String(this.state.addSupports.monthlyIncome ?? '')}
                            onValueChange={values => {
                              const { value } = values;
                              let { addSupports } = this.state;
                              addSupports.monthlyIncome = value;
                              this.setState({ addSupports });
                            }}
                          />
                        </div>
                        <div className={`d-flex flex-column ${style.colSix} ${style.ml1rem}`}>
                          <label
                            htmlFor='monthlyExpenses'
                            className={`${style.labelFontGray} mb-1`}
                          >
                            Egresos mensuales{asterisk}
                          </label>
                          <NumberFormat
                            allowNegative={false}
                            placeholder='Escribir...'
                            className={`${style.inputText} px-2`}
                            name='monthlyExpenses'
                            suffix={` ${CURRENCY}`}
                            thousandSeparator={this.localeSeparators.groupSeparator}
                            decimalSeparator={this.localeSeparators.decimalSeparator}
                            decimalScale={2}
                            isNumericString
                            value={String(this.state.addSupports.monthlyExpenses ?? '')}
                            onValueChange={values => {
                              const { value } = values;
                              let { addSupports } = this.state;
                              addSupports.monthlyExpenses = value;
                              this.setState({ addSupports });
                            }}
                          />
                        </div>
                      </div>
                      <div className={`d-flex flex-column ${style.colSix}`}>
                        <label htmlFor='otherIncome' className={`${style.labelFontGray} mb-1`}>
                          Otros Ingresos{asterisk}
                        </label>
                        <NumberFormat
                          allowNegative={false}
                          placeholder='Escribir...'
                          className={`${style.inputText} px-2`}
                          name='otherIncome'
                          suffix={` ${CURRENCY}`}
                          thousandSeparator={this.localeSeparators.groupSeparator}
                          decimalSeparator={this.localeSeparators.decimalSeparator}
                          decimalScale={2}
                          isNumericString
                          value={String(this.state.addSupports.otherIncome ?? '')}
                          onValueChange={values => {
                            let { addSupports } = this.state;
                            addSupports.otherIncome = values.value;
                            this.setState({ addSupports });
                          }}
                        />
                      </div>
                    </div>
                    {/*ANCHOR third row */}
                    <div className={`${style.containerRow} mx-3 mt-3`}>
                      <div className={`d-flex flex-column ${style.colTwelve}`}>
                        <label
                          htmlFor='otherIncomeConcept'
                          className={`${style.labelFontGray} mb-1`}
                        >
                          Concepto de otros ingresos{asterisk}
                        </label>
                        <input
                          type='text'
                          placeholder='Escribir...'
                          className={`${style.inputText} px-2`}
                          name='otherIncomeConcept'
                          onChange={this.onChangeForm}
                          value={this.state.addSupports.otherIncomeConcept || ''}
                        />
                      </div>
                      <div className={`d-flex flex-column ${style.colSix}`}>
                        <label htmlFor='cortDate' className={`${style.labelFontGray} mb-1`}>
                          Información a corte de{asterisk}
                        </label>
                        <input
                          type='date'
                          name='cortDate'
                          className={`${style.inputText} ${style.registerInputsBlue} px-2`}
                          onChange={this.onChangeForm}
                          value={this.state.addSupports.cortDate}
                        />
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
                {/* SECTION BANK INFORMATION */}
                <div className='position-relative'>
                  <div
                    className={`${style.checkAccordion} ${
                      !!this.state.checkBankInformation && style.checkAccordionActive
                    } mx-3 align-top`}
                  >
                    {!!this.state.checkBankInformation && (
                      <img src={CheckMark} alt='Indicador de completado' />
                    )}
                  </div>
                </div>
                <Accordion.Toggle
                  as={Button}
                  variant=''
                  eventKey='4'
                  className={`${style.itemTextAccordion} ${style.blueBackgroundCard}`}
                  name='collapsedItemBankInformation'
                  onClick={() =>
                    this.setState({
                      collapsedItemBankInformation: !this.state.collapsedItemBankInformation,
                    })
                  }
                >
                  <img
                    src={DownArrow}
                    alt='abrir submenu'
                    className={
                      this.state.collapsedItemBankInformation
                        ? style.downArrowIcon
                        : style.downArrowIconHorizontal
                    }
                  />
                  <span className={style.textDarkBlue}>Información Bancaria</span>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='4'>
                  <Card.Body className={style.blueBackgroundCard}>
                    {/*ANCHOR first row */}
                    <div className={`${style.containerRow} mx-3`}>
                      <div className={`${style.selectGroup} ${style.colSix}`}>
                        <label htmlFor='bankingEntity' className={`${style.labelFontGray} mb-1`}>
                          Entidad financiera{asterisk}
                        </label>
                        <select
                          className={`${style.selectInput} w-100 px-2 `}
                          name='bankingEntity'
                          onChange={this.onChangeForm}
                          value={this.state.addSupports.bankingEntity}
                        >
                          <option value=''>seleccionar...</option>
                          {this.props?.bankingEntities?.map(item => {
                            return (
                              <option
                                key={`${item.id}-bank`}
                                value={item.id}
                                className='text-break'
                              >
                                {item.description}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className={`${style.selectGroup} ${style.colTwelve} d-flex`}>
                        <div className={`${style.selectGroup} ${style.colSix}`}>
                          <label
                            htmlFor='bankAccountType'
                            className={`${style.labelFontGray} mb-1`}
                          >
                            Tipo de cuenta{asterisk}
                          </label>
                          <select
                            name='bankAccountType'
                            className={`${style.selectInput} w-100 px-2 `}
                            onChange={this.onChangeForm}
                            value={this.state.addSupports.bankAccountType}
                            style={{ marginRight: '0' }}
                          >
                            <option value=''>seleccionar...</option>
                            <option value='current'>Corriente</option>
                            <option value='investmentFunds'>Fondos de inversión</option>
                            <option value='savings'>Ahorros</option>
                            <option value='fiduciaries'>Fiduciarias</option>
                          </select>
                        </div>
                        <div className={` ${style.colSix}`}>
                          <label htmlFor='accountNumber' className={`${style.labelFontGray} mb-1`}>
                            No. de la cuenta{asterisk}
                          </label>
                          <NumberFormat
                            allowNegative={false}
                            placeholder='Escribir...'
                            className={`${style.inputText} w-100 px-2`}
                            name='accountNumber'
                            thousandSeparator={this.localeSeparators.groupSeparator}
                            decimalSeparator={this.localeSeparators.decimalSeparator}
                            value={this.state.addSupports.accountNumber || ''}
                            onValueChange={values => {
                              const { value } = values;
                              let { addSupports } = this.state;
                              addSupports.accountNumber = value;
                              this.setState({ addSupports });
                            }}
                          />
                        </div>
                      </div>
                      {/* titular */}
                      <div className={`${style.selectGroup} ${style.colSix}`}>
                        <label htmlFor='accountHolder' className={`${style.labelFontGray} mb-1`}>
                          Titular de la cuenta{asterisk}
                        </label>
                        <input
                          type='text'
                          placeholder='Escribir...'
                          className={`${style.inputText} w-100 px-2`}
                          name='accountHolder'
                          onChange={this.onChangeForm}
                          value={this.state.addSupports.accountHolder || ''}
                        />
                      </div>
                    </div>
                    {/*ANCHOR second row */}
                    <div className={`${style.containerRow} mt-2`}>
                      {/* country */}
                      <div className={`${style.selectGroup} ${style.colFour}`}>
                        <label htmlFor='countryBankInfo' className={`${style.labelFontGray}`}>
                          País{asterisk}
                        </label>
                        <select
                          name='countryBankInfo'
                          className={`${style.selectInput} w-100 px-2 `}
                          onChange={this.onChangeSelect}
                          value={this.state.addSupports?.countryBankInfo}
                        >
                          <option value=''>seleccionar...</option>
                          {optionsCountries}
                        </select>
                      </div>
                      {/* region */}
                      <div className={`${style.selectGroup} ${style.colFour}`}>
                        <label htmlFor='provinceBankInfo' className={`${style.labelFontGray}`}>
                          Región{asterisk}
                        </label>
                        <select
                          name='provinceBankInfo'
                          className={`${style.selectInput} w-100 px-2 `}
                          onChange={this.onChangeSelect}
                          value={this.state.addSupports?.provinceBankInfo}
                        >
                          <option value=''>seleccionar...</option>
                          {optionsAllProvinces}
                        </select>
                      </div>
                      {/* city bank */}
                      <div className={`${style.selectGroup} ${style.colFour}`}>
                        <label htmlFor='cityBankInfo' className={`${style.labelFontGray}`}>
                          Ciudad{asterisk}
                        </label>
                        <select
                          name='cityBankInfo'
                          className={`${style.selectInput} w-100 px-2 `}
                          onChange={this.onChangeForm}
                          value={this.state.addSupports?.cityBankInfo}
                        >
                          <option value=''>seleccionar...</option>
                          {this.props?.allCities?.map(city => {
                            if (
                              city.province === parseInt(this.state.addSupports.provinceBankInfo)
                            ) {
                              return (
                                <option key={city.id} value={city.id}>
                                  {city.name}
                                </option>
                              );
                            }
                            return <></>;
                          })}
                        </select>
                      </div>
                      {/* sucursal bancaria */}
                      <div className={`${style.colSix}`}>
                        <label htmlFor='bankBranch' className={`${style.labelFontGray}`}>
                          Sucursal bancaria{asterisk}
                        </label>
                        <input
                          type='text'
                          placeholder='Escribir...'
                          name='bankBranch'
                          className={`${style.selectInput} w-100 px-2 `}
                          onChange={this.onChangeForm}
                          value={this.state.addSupports?.bankBranch || ''}
                        />
                      </div>
                      <div className={`${style.textGroup} ${style.colThree}`}></div>
                      <div className={`${style.textGroup} ${style.colThree}`}></div>
                    </div>

                    <div className={`${style.colSix} ${style.addButtonOnRow} mt-2`}>
                      <button onClick={this.addAccount}>
                        <img
                          src={grayAdd}
                          alt='Agregar cuenta'
                          className={style.addActiviyIconWithOutMargin}
                        />
                        <span>Agregar otra cuenta</span>
                      </button>
                    </div>
                    {renderExtraBankAccounts}
                    {/* SECTION MONEDA EXTRANJERA INFORMACIÓN BANCARIA */}

                    <div className='mt-3'>
                      <span className='d-flex align-items-center'>
                        <b className='mr-2'>•</b>
                        ¿Tiene productos financieros en &nbsp;<b> moneda extranjera?</b>
                        <label className='d-flex align-middle'>
                          <Switch
                            className='mx-3 my-2'
                            activeBoxShadow
                            checkedIcon={false}
                            uncheckedIcon={false}
                            handleDiameter={22}
                            width={50}
                            onColor='#005DBF'
                            onChange={e => {
                              this.handleSwitchForeignCoin(e);
                            }}
                            checked={this.state.addSupports.hasForeignCurrency ? true : false}
                          />
                          <span className='text-center my-auto'>
                            {this.state.addSupports.hasForeignCurrency ? 'Si' : 'No'}
                          </span>
                        </label>
                      </span>
                    </div>
                    {/* !SECTION */}
                    {/*ANCHOR first row */}
                    <div className={`${style.containerRow} mx-3`}>
                      <div className={`${style.selectGroup} ${style.colSix}`}>
                        <label
                          htmlFor='foreignBankingEntity'
                          className={`${style.labelFontGray} mb-1`}
                        >
                          Entidad financiera
                          {this.state.addSupports.hasForeignCurrency ? asterisk : asteriskDisabled}
                        </label>
                        <input
                          className={`${style.selectInput} w-100 px-2 `}
                          placeholder='Escribir...'
                          name='foreignBankingEntity'
                          onChange={this.onChangeForm}
                          value={this.state.addSupports.foreignBankingEntity || ''}
                          disabled={this.state.addSupports.hasForeignCurrency ? '' : 'disabled'}
                        />
                      </div>
                      <div className={`${style.selectGroup} ${style.colTwelve} d-flex`}>
                        <div className={`${style.selectGroup} ${style.colSix}`}>
                          <label
                            htmlFor='foreignBankingType'
                            className={`${style.labelFontGray} mb-1`}
                          >
                            Tipo de cuenta
                            {this.state.addSupports.hasForeignCurrency
                              ? asterisk
                              : asteriskDisabled}
                          </label>
                          <select
                            name='foreignBankingType'
                            className={`${style.selectInput} w-100 px-2 `}
                            onChange={this.onChangeForm}
                            value={this.state.addSupports.foreignBankingType}
                            disabled={this.state.addSupports.hasForeignCurrency ? '' : 'disabled'}
                            style={{ marginRight: '0' }}
                          >
                            <option value=''>seleccionar...</option>
                            <option value='current'>Corriente</option>
                            <option value='investmentFunds'>Fondos de inversión</option>
                            <option value='savings'>Ahorros</option>
                            <option value='fiduciaries'>Fiduciarias</option>
                          </select>
                        </div>
                        <div className={` ${style.colSix}`}>
                          <label
                            htmlFor='foreignNumberBankAccount'
                            className={`${style.labelFontGray} mb-1`}
                          >
                            No. de producto
                            {this.state.addSupports.hasForeignCurrency
                              ? asterisk
                              : asteriskDisabled}
                          </label>
                          <NumberFormat
                            allowNegative={false}
                            placeholder='Escribir...'
                            className={`${style.inputText} w-100 px-2`}
                            name='foreignNumberBankAccount'
                            thousandSeparator={this.localeSeparators.groupSeparator}
                            decimalSeparator={this.localeSeparators.decimalSeparator}
                            value={this.state.addSupports.foreignNumberBankAccount || ''}
                            disabled={this.state.addSupports.hasForeignCurrency ? '' : 'disabled'}
                            onValueChange={values => {
                              const { value } = values;
                              let { addSupports } = this.state;
                              addSupports.foreignNumberBankAccount = value;
                              this.setState({ addSupports });
                            }}
                          />
                        </div>
                      </div>
                      {/* monto */}
                      <div className={`${style.selectGroup} ${style.colSix}`}>
                        <label
                          htmlFor='foreignBankAmount'
                          className={`${style.labelFontGray} mb-1`}
                        >
                          Monto
                          {this.state.addSupports.hasForeignCurrency ? asterisk : asteriskDisabled}
                        </label>
                        <NumberFormat
                          disabled={this.state.addSupports.hasForeignCurrency ? '' : 'disabled'}
                          allowNegative={false}
                          placeholder='Escribir...'
                          className={`${style.inputText} w-100 px-2`}
                          name='foreignBankAmount'
                          suffix={` ${CURRENCY}`}
                          thousandSeparator={this.localeSeparators.groupSeparator}
                          decimalSeparator={this.localeSeparators.decimalSeparator}
                          decimalScale={2}
                          isNumericString
                          value={String(this.state.addSupports.foreignBankAmount ?? '')}
                          onValueChange={values => {
                            const { value } = values;
                            let { addSupports } = this.state;
                            addSupports.foreignBankAmount = value;
                            this.setState({ addSupports });
                          }}
                        />
                      </div>
                    </div>
                    {/*ANCHOR second row */}
                    <div className={`${style.containerRow} mt-2`}>
                      {/* country */}
                      <div className={`${style.selectGroup} ${style.colFour}`}>
                        <label htmlFor='foreignBankCountry' className={`${style.labelFontGray}`}>
                          País
                          {this.state.addSupports.hasForeignCurrency ? asterisk : asteriskDisabled}
                        </label>
                        <select
                          name='foreignBankCountry'
                          className={`${style.selectInput} w-100 px-2 `}
                          onChange={this.onChangeSelect}
                          value={this.state.addSupports?.foreignBankCountry}
                          disabled={this.state.addSupports.hasForeignCurrency ? '' : 'disabled'}
                        >
                          <option value=''>seleccionar...</option>
                          {optionsCountries}
                        </select>
                      </div>
                      {/* region */}
                      <div className={`${style.selectGroup} ${style.colFour}`}>
                        <label htmlFor='foreignBankCity' className={`${style.labelFontGray}`}>
                          Ciudad
                          {this.state.addSupports.hasForeignCurrency ? asterisk : asteriskDisabled}
                        </label>
                        <select
                          name='foreignBankCity'
                          className={`${style.selectInput} w-100 px-2 `}
                          onChange={this.onChangeSelect}
                          value={this.state.addSupports?.foreignBankCity}
                          disabled={this.state.addSupports.hasForeignCurrency ? '' : 'disabled'}
                        >
                          <option value=''>seleccionar...</option>
                          {this.props?.allCities?.map(city => {
                            return (
                              <option key={city.id} value={city.id}>
                                {city.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className={`${style.selectGroup} ${style.colFour}`}>
                        <label htmlFor='foreignCoin' className={`${style.labelFontGray}`}>
                          Moneda
                          {this.state.addSupports.hasForeignCurrency ? asterisk : asteriskDisabled}
                        </label>
                        <select
                          name='foreignCoin'
                          className={`${style.selectInput} w-100 px-2 `}
                          onChange={this.onChangeForm}
                          value={this.state.addSupports?.foreignCoin}
                          disabled={this.state.addSupports.hasForeignCurrency ? '' : 'disabled'}
                        >
                          <option value=''>seleccionar...</option>
                          <option value='dolar'>Dólares</option>
                          <option value='peso'>Peso colombiano</option>
                        </select>
                      </div>
                      <div className={`${style.colSix}`} />
                      <div className={`${style.textGroup} ${style.colThree}`} />
                      <div className={`${style.textGroup} ${style.colThree}`} />
                    </div>

                    <div className={`${style.colSix} ${style.addButtonOnRow} my-2`}>
                      <button
                        onClick={this.addBankProduct}
                        disabled={this.state.addSupports.hasForeignCurrency ? '' : 'disabled'}
                      >
                        <img
                          src={grayAdd}
                          alt='Agregar producto'
                          className={`${style.addActiviyIconWithOutMargin} ${this.state.addSupports.hasForeignCurrency ? '' : 'disabled'}`}
                        />
                        <span>Agregar otro producto</span>
                      </button>
                    </div>
                    {renderExtraForeignBankAccounts}
                    <div className='mt-3'>
                      <span className='d-flex align-items-center'>
                        <b className='mr-2'>•</b>
                        ¿Tiene&nbsp;<b> referencias comerciales?</b>
                        <label className='d-flex align-middle'>
                          <Switch
                            className='mx-3 my-2'
                            activeBoxShadow
                            checkedIcon={false}
                            uncheckedIcon={false}
                            handleDiameter={22}
                            width={50}
                            onColor='#005DBF'
                            onChange={e => {
                              this.handleSwitchCommercialReferences(e);
                            }}
                            checked={this.state.addSupports.hasCommercialReferences ? true : false}
                          />
                          <span className='text-center my-auto'>
                            {this.state.addSupports.hasCommercialReferences ? 'Si' : 'No'}
                          </span>
                        </label>
                      </span>
                    </div>
                    {/* !SECTION */}
                    {/*ANCHOR first row */}
                    <div className={`${style.containerRow} mx-3`}>
                      <div className={`${style.selectGroup} ${style.colTwelve}`}>
                        <label
                          htmlFor='nameCommercialReference'
                          className={`${style.labelFontGray} mb-1`}
                        >
                          Nombre
                          {this.state.addSupports.hasCommercialReferences
                            ? asterisk
                            : asteriskDisabled}
                        </label>
                        <input
                          className={`${style.selectInput} w-100 px-2 `}
                          placeholder='Escribir...'
                          name='nameCommercialReference'
                          onChange={this.onChangeForm}
                          value={this.state.addSupports.nameCommercialReference || ''}
                          disabled={
                            this.state.addSupports.hasCommercialReferences ? '' : 'disabled'
                          }
                        />
                      </div>
                      <div className={`${style.selectGroup} ${style.colTwelve} d-flex`}>
                        <div className={`${style.selectGroup} ${style.colThree}`}>
                          <label
                            htmlFor='phoneCommercialReference'
                            className={`${style.labelFontGray} mb-1`}
                          >
                            Teléfono
                            {this.state.addSupports.hasCommercialReferences
                              ? asterisk
                              : asteriskDisabled}
                          </label>
                          <NumberFormat
                            allowNegative={false}
                            placeholder='Escribir...'
                            className={`${style.inputText} w-100 px-2`}
                            name='phoneCommercialReference'
                            thousandSeparator={this.localeSeparators.groupSeparator}
                            decimalSeparator={this.localeSeparators.decimalSeparator}
                            value={this.state.addSupports.phoneCommercialReference || ''}
                            disabled={
                              this.state.addSupports.hasCommercialReferences ? '' : 'disabled'
                            }
                            onValueChange={values => {
                              const { value } = values;
                              let { addSupports } = this.state;
                              addSupports.phoneCommercialReference = value;
                              this.setState({ addSupports });
                            }}
                          />
                        </div>
                        <div className={`${style.selectGroup} ${style.colThree}`}>
                          <label
                            htmlFor='cellphoneCommercialReference'
                            className={`${style.labelFontGray} mb-1`}
                          >
                            Celular
                            {this.state.addSupports.hasCommercialReferences
                              ? asterisk
                              : asteriskDisabled}
                          </label>
                          <NumberFormat
                            allowNegative={false}
                            placeholder='Escribir...'
                            className={`${style.inputText} w-100 px-2`}
                            name='cellphoneCommercialReference'
                            thousandSeparator={this.localeSeparators.groupSeparator}
                            decimalSeparator={this.localeSeparators.decimalSeparator}
                            value={this.state.addSupports.cellphoneCommercialReference}
                            disabled={
                              this.state.addSupports.hasCommercialReferences ? '' : 'disabled'
                            }
                            onValueChange={values => {
                              const { value } = values;
                              let { addSupports } = this.state;
                              addSupports.cellphoneCommercialReference = value;
                              this.setState({ addSupports });
                            }}
                          />
                        </div>
                        <div className={`${style.selectGroup} ${style.colSix}`}>
                          <label
                            htmlFor='emailCommercialReference'
                            className={`${style.labelFontGray} mb-1`}
                          >
                            Correo electrónico
                            {this.state.addSupports.hasCommercialReferences
                              ? asterisk
                              : asteriskDisabled}
                          </label>
                          <input
                            name='emailCommercialReference'
                            placeholder='Escribir...'
                            className={`${style.selectInput} w-100 px-2 `}
                            onChange={this.onChangeForm}
                            value={this.state.addSupports.emailCommercialReference || ''}
                            disabled={
                              this.state.addSupports.hasCommercialReferences ? '' : 'disabled'
                            }
                            style={{ marginRight: '0' }}
                            onBlur={() => {
                              if (this.state.addSupports.emailCommercialReference) {
                                if (
                                  !validateEmail(this.state.addSupports.emailCommercialReference)
                                ) {
                                  let { addSupports } = this.state;
                                  addSupports.emailCommercialReference = '';
                                  this.setState({ addSupports });
                                  return responseSwal({
                                    icon: 'error',
                                    title: 'Error',
                                    text: 'Este correo no es válido',
                                  });
                                }
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {/*ANCHOR second row */}
                    <div className={`${style.containerRow} mt-2`}>
                      <div className={`${style.colTwelve} d-flex`}>
                        {/* country */}
                        <div className={`${style.selectGroup} ${style.colFour}`}>
                          <label
                            htmlFor='countryCommercialReference'
                            className={`${style.labelFontGray}`}
                          >
                            País
                            {this.state.addSupports.hasCommercialReferences
                              ? asterisk
                              : asteriskDisabled}
                          </label>
                          <select
                            name='countryCommercialReference'
                            className={`${style.selectInput} w-100 px-2 `}
                            onChange={this.onChangeSelect}
                            value={this.state.addSupports?.countryCommercialReference}
                            disabled={
                              this.state.addSupports.hasCommercialReferences ? '' : 'disabled'
                            }
                          >
                            <option value=''>seleccionar...</option>
                            {optionsCountries}
                          </select>
                        </div>
                        {/* region */}
                        <div className={`${style.selectGroup} ${style.colFour}`}>
                          <label
                            htmlFor='provinceCommercialReference'
                            className={`${style.labelFontGray}`}
                          >
                            Región
                            {this.state.addSupports.hasCommercialReferences
                              ? asterisk
                              : asteriskDisabled}
                          </label>
                          <select
                            name='provinceCommercialReference'
                            className={`${style.selectInput} w-100 px-2 `}
                            onChange={this.onChangeSelect}
                            value={this.state.addSupports?.provinceCommercialReference}
                            disabled={
                              this.state.addSupports.hasCommercialReferences ? '' : 'disabled'
                            }
                          >
                            <option value=''>seleccionar...</option>
                            {optionsAllProvinces}
                          </select>
                        </div>
                        <div className={`${style.selectGroupWithOutMargin} ${style.colFour}`}>
                          <label
                            htmlFor='cityCommercialReference'
                            className={`${style.labelFontGray}`}
                          >
                            Ciudad
                            {this.state.addSupports.hasCommercialReferences
                              ? asterisk
                              : asteriskDisabled}
                          </label>
                          <select
                            name='cityCommercialReference'
                            className={`${style.selectInput} w-100 px-2 `}
                            onChange={this.onChangeForm}
                            value={this.state.addSupports?.cityCommercialReference}
                            disabled={
                              this.state.addSupports.hasCommercialReferences ? '' : 'disabled'
                            }
                          >
                            <option value=''>seleccionar...</option>
                            {this.props?.allCities?.map(city => {
                              if (
                                parseInt(this.state.addSupports.provinceCommercialReference) ===
                                city.province
                              ) {
                                return (
                                  <option value={city.id} key={`${city.id}-cityCommercial`}>
                                    {city.name}
                                  </option>
                                );
                              }
                              return <></>;
                            })}
                          </select>
                        </div>
                      </div>
                      <div className={`${style.colTwelve} d-flex`}>
                        <div className={`${style.colSix} mr-3`}>
                          <label
                            htmlFor='addressCommercialReference'
                            className={`${style.labelFontGray} mb-1`}
                          >
                            Dirección
                            {this.state.addSupports.hasCommercialReferences
                              ? asterisk
                              : asteriskDisabled}
                          </label>
                          <input
                            name='addressCommercialReference'
                            placeholder='Escribir...'
                            className={`${style.selectInput} w-100 px-2 `}
                            onChange={this.onChangeForm}
                            value={this.state.addSupports.addressCommercialReference || ''}
                            disabled={
                              this.state.addSupports.hasCommercialReferences ? '' : 'disabled'
                            }
                            style={{ marginRight: '0' }}
                          />
                        </div>
                        <div className={`${style.colSix}`}>
                          <label
                            htmlFor='supportFileCommercialReference'
                            className={`${style.labelFontGray} mb-1`}
                          >
                            Cargar soporte
                            {this.state.addSupports.hasCommercialReferences
                              ? asterisk
                              : asteriskDisabled}
                          </label>
                          <label
                            className={`${style.labelInputFileBank} ${this.state.addSupports.supportFileCommercialReference ? style.alignBetween : style.alignBetween} ${this.state.addSupports.hasCommercialReferences ? '' : style.disabled}`}
                          >
                            <span style={{ color: '#757575' }}>
                              {!this.state.addSupports.filenamesupportFileCommercialReference &&
                                'Seleccionar'}
                            </span>
                            {this.state.addSupports.filenamesupportFileCommercialReference ||
                            this.state.addSupports.supportFileCommercialReference ? (
                              <div className='algin-self-center my-auto bg-white rounded position-relative d-flex'>
                                <img
                                  src={
                                    this.state.addSupports.fileTypesupportFileCommercialReference
                                  }
                                  alt='icon file'
                                />
                                <span
                                  className={`${style.secondaryBlue} ${style.labelFilenames} text-truncate`}
                                >
                                  {String(
                                    this.state.addSupports.supportFileCommercialReference,
                                  ).includes('https://') ? (
                                    <a
                                      className='text-decoration-none text-primary text-truncate'
                                      href={this.state.addSupports.supportFileCommercialReference}
                                      target='_blank'
                                      rel='noreferrer'
                                    >
                                      {this.state.addSupports.supportFileCommercialReference}
                                    </a>
                                  ) : (
                                    this.state.addSupports.filenamesupportFileCommercialReference
                                  )}
                                  {/* {this.state.addSupports.filenamesupportFileCommercialReference} */}
                                </span>
                                <img
                                  name='supportFileCommercialReference'
                                  src={littleClose}
                                  alt='descartar'
                                  width='16px'
                                  className={style.discardFile}
                                  onClick={this.discardFile}
                                />
                              </div>
                            ) : null}

                            <input
                              name='supportFileCommercialReference'
                              placeholder='Adjuntar'
                              id='supportFileCommercialReference'
                              className={`${style.inputFile}`}
                              onChange={this.onChangeDoc}
                              disabled={
                                this.state.addSupports.hasCommercialReferences ? '' : 'disabled'
                              }
                              type='file'
                              multiple={false}
                              accept={FILE_ACCEPT_PROVIDER}
                            />
                            <img src={searchFileIcon} alt='agregar archivo' />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className={`${style.colSix} ${style.addButtonOnRow} my-2`}>
                      <button
                        onClick={this.addCommercialReferences}
                        disabled={this.state.addSupports.hasCommercialReferences ? '' : 'disabled'}
                      >
                        <img
                          src={grayAdd}
                          alt='Agregar producto'
                          className={`${style.addActiviyIconWithOutMargin} ${this.state.addSupports.hasCommercialReferences ? '' : 'disabled'}`}
                        />
                        <span>Agregar otra referencia</span>
                      </button>
                    </div>
                    {renderExtraCommercialReferences}
                  </Card.Body>
                </Accordion.Collapse>
                {/* !SECTION */}
                <div className='position-relative'>
                  <div
                    className={`${style.checkAccordion} ${
                      !!this.state.checkDeclarations && style.checkAccordionActive
                    } mx-3 align-top`}
                  >
                    {!!this.state.checkDeclarations && (
                      <img src={CheckMark} alt='Indicador de completado' />
                    )}
                  </div>
                </div>
                <Accordion.Toggle
                  as={Button}
                  variant=''
                  eventKey='5'
                  className={`${style.itemTextAccordion} ${style.blueBackgroundCard}`}
                  name='collapsedItemDeclarations'
                  onClick={() =>
                    this.setState({
                      collapsedItemDeclarations: !this.state.collapsedItemDeclarations,
                    })
                  }
                >
                  <img
                    src={DownArrow}
                    alt='abrir submenu'
                    className={
                      this.state.collapsedItemDeclarations
                        ? style.downArrowIcon
                        : style.downArrowIconHorizontal
                    }
                  />
                  <span className={style.textDarkBlue}>Declaraciones</span>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='5'>
                  <Card.Body className={style.blueBackgroundCard}>
                    {/*ANCHOR first row */}
                    <div className={`${style.containerRow} mx-3`}>
                      <span>
                        <b className='mr-1'>•</b>
                        <b> Personas expuestas política y públicamente (PEPS) </b>
                      </span>
                    </div>
                    <div className={`${style.containerRow} mx-3`}>
                      <div className={`${style.colTwelve} d-flex`}>
                        <div
                          className='mx-3'
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              modalExposePeople: !this.state.modalExposePeople,
                            });
                          }}
                        >
                          <span>
                            <img
                              src={Bill}
                              width='20'
                              alt='doc icon'
                              className={`${style.verticalSub} mr-2`}
                            />
                            <span
                              className={`${style.darkGrayColor} text-decoration-underline hoverPointer`}
                            >
                              Conocimiento mejorado de PEPS
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={`${style.containerRow} mx-3`}>
                      <div className='mx-3 w-100'>
                        <Form>
                          {['radio'].map(type => (
                            <div
                              key={`publicChargues-${type}`}
                              className='mb-3 d-flex justify-content-between'
                            >
                              <div className='d-flex flex-column mr-3'>
                                <span className={`${style.darkGrayColor} text-justify`}>
                                  ¿Administra recursos públicos?
                                </span>
                                <Form.Group
                                  onChange={this.onChangeForm}
                                  className={style.checkGroup}
                                >
                                  <Form.Check
                                    type={type}
                                    label={`Si`}
                                    name={`publicResources`}
                                    value={true}
                                    checked={this.state.addSupports.publicResources ? true : false}
                                    onChange={this.onChangeForm}
                                  />
                                  <Form.Check
                                    type={type}
                                    label={`No`}
                                    name={`publicResources`}
                                    value={false}
                                    checked={!this.state.addSupports.publicResources ? true : false}
                                    onChange={this.onChangeForm}
                                  />
                                </Form.Group>
                              </div>
                              <div className='d-flex flex-column mr-3'>
                                <span className={style.darkGrayColor}>
                                  ¿Tiene grado de poder público?
                                </span>
                                <Form.Group
                                  onChange={this.onChangeForm}
                                  className={style.checkGroup}
                                >
                                  <Form.Check
                                    type={type}
                                    label={`Si`}
                                    name='publicDegree'
                                    value={true}
                                    checked={this.state.addSupports.publicDegree ? true : false}
                                    // onChange={this.onChangeForm}
                                  />
                                  <Form.Check
                                    type={type}
                                    label={`No`}
                                    name='publicDegree'
                                    value={false}
                                    checked={!this.state.addSupports.publicDegree ? true : false}
                                    // onChange={this.onChangeForm}
                                  />
                                </Form.Group>
                              </div>
                              <div className='d-flex flex-column mr-3'>
                                <span className={style.darkGrayColor}>
                                  ¿Tiene reconocimiento público?
                                </span>
                                <Form.Group
                                  className={style.checkGroup}
                                  onChange={this.onChangeForm}
                                >
                                  <Form.Check
                                    type={type}
                                    label={`Si`}
                                    name='publicRecognition'
                                    value={true}
                                    checked={
                                      this.state.addSupports.publicRecognition ? true : false
                                    }
                                    // onChange={this.onChangeForm}
                                  />

                                  <Form.Check
                                    type={type}
                                    label={`No`}
                                    name='publicRecognition'
                                    value={false}
                                    checked={
                                      !this.state.addSupports.publicRecognition ? true : false
                                    }
                                    onChange={this.onChangeForm}
                                  />
                                </Form.Group>
                              </div>
                              <div className='d-flex flex-column'>
                                <span className={style.darkGrayColor}>
                                  ¿Tiene vínculo con una persona PEP?
                                </span>
                                <Form.Group
                                  className={style.checkGroup}
                                  onChange={this.onChangeForm}
                                >
                                  <Form.Check
                                    type={type}
                                    value={true}
                                    name='bondWithPEP'
                                    label={`Si`}
                                    checked={this.state.addSupports.bondWithPEP ? true : false}
                                    // onChange={this.onChangeForm}
                                  />
                                  <Form.Check
                                    type={type}
                                    name='bondWithPEP'
                                    value={false}
                                    label={`No`}
                                    checked={!this.state.addSupports.bondWithPEP ? true : false}
                                    // onChange={this.onChangeForm}
                                  />
                                </Form.Group>
                              </div>
                            </div>
                          ))}
                        </Form>
                      </div>
                    </div>
                    <div className={`${style.containerRow} mx-3`}>
                      <span>
                        <b className='mr-1'>•</b>
                        <b>Declaración de fondos y otros</b>
                      </span>
                    </div>
                    <div className={`${style.containerRow} mx-3`}>
                      <div className='flex-column'>
                        <p className={`${style.darkGrayColor} mx-3`}>
                          Para dar cumplimiento a la regulación aplicable en materia de prevención
                          del Lavado de Activos y Financiación del Terrorismo, expedida por la
                          Superintendecia de Salud como norma que deben adptar las entidades
                          sometidas a su vigilancia y el control, Yo {renderNameLglRpst},
                          identificado con el documento de identidad número C.C.{' '}
                          {renderDocNumLglRpst} expedido en {renderExpLocationLglRpst} obrando en
                          representación de: {renderBusinessName} declaro que:
                          <br />
                          a) los recursos provienen de las siguientes fuentes (detalle de la
                          operación, oficio, profesión, actividad, negocio):
                        </p>
                        <span className={`${style.darkGrayColor} mx-3`}>
                          ¿Ha sido sancionado o investigado por delito de lavado de activos o de
                          financiación del terrorismo?
                        </span>
                        <div className='w-25 mx-3'>
                          <Form.Group className={style.checkGroup} onChange={this.onChangeForm}>
                            <Form.Check
                              type={'radio'}
                              value={true}
                              name='moneyLaundering'
                              label={`Si`}
                              onChange={this.onChangeForm}
                              checked={this.state.addSupports.moneyLaundering ? true : false}
                            />
                            <Form.Check
                              type={'radio'}
                              name='moneyLaundering'
                              value={false}
                              label={`No`}
                              onChange={this.onChangeForm}
                              checked={!this.state.addSupports.moneyLaundering ? true : false}
                            />
                          </Form.Group>
                        </div>
                        <span className={`${style.darkGrayColor} mx-3 mt-3`}>
                          ¿La entidad o alguno de sus accionistas han sido incluidos en listas
                          inhibitorias como la lista Clinton?
                        </span>
                        <div className='w-25 mx-3'>
                          <Form.Group className={style.checkGroup} onChange={this.onChangeForm}>
                            <Form.Check
                              type={'radio'}
                              value={true}
                              name='inhibitoryList'
                              label={`Si`}
                              onChange={this.onChangeForm}
                              checked={this.state.addSupports.inhibitoryList ? true : false}
                            />
                            <Form.Check
                              type={'radio'}
                              name='inhibitoryList'
                              value={false}
                              label={`No`}
                              onChange={this.onChangeForm}
                              checked={!this.state.addSupports.inhibitoryList ? true : false}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                    <div className={`${style.containerRow} mx-3`}>
                      <span>
                        <b className='mr-1'>•</b>
                        <b>Autorización de consulta de antecedentes</b>
                      </span>
                    </div>
                    <div className={`${style.containerRow} mx-3`}>
                      <div className={`${style.colTwelve} d-flex flex-column`}>
                        <div
                          className='mx-3 d-flex'
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              modalConsultBackground: !this.state.modalConsultBackground,
                            });
                          }}
                        >
                          <span>
                            <img
                              src={Bill}
                              width='20'
                              alt='doc icon'
                              className={`${style.verticalSub} mr-2`}
                            />
                            <span
                              className={`${style.darkGrayColor} text-decoration-underline hoverPointer`}
                            >
                              Términos y condiciones de autorización
                            </span>
                          </span>
                        </div>
                        <label className='d-flex align-middle'>
                          <Switch
                            className='mx-3 my-2'
                            activeBoxShadow
                            checkedIcon={false}
                            uncheckedIcon={false}
                            handleDiameter={22}
                            width={50}
                            onColor='#005DBF'
                            onChange={e =>
                              this.setState({
                                ...this.state,
                                addSupports: {
                                  ...this.state.addSupports,
                                  authConsultBackground: e,
                                },
                              })
                            }
                            checked={this.state.addSupports.authConsultBackground ? true : false}
                          />
                          <span className='my-2'>
                            {this.state.addSupports.authConsultBackground
                              ? 'Autorizo'
                              : 'No autorizo'}
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className={`${style.containerRow} mx-3`}>
                      <span>
                        <b className='mr-1'>•</b>
                        <b>Autorización para verificación de información y documentos</b>
                      </span>
                    </div>
                    <div className={`${style.containerRow} mx-3`}>
                      <div className={`${style.colTwelve} d-flex flex-column`}>
                        <div
                          className='mx-3 d-flex'
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              modalVerifyInfoDoc: !this.state.modalVerifyInfoDoc,
                            });
                          }}
                        >
                          <span>
                            <img
                              src={Bill}
                              width='20'
                              alt='doc icon'
                              className={`${style.verticalSub} mr-2`}
                            />
                            <span
                              className={`${style.darkGrayColor} text-decoration-underline hoverPointer`}
                            >
                              Términos y condiciones de autorización
                            </span>
                          </span>
                        </div>
                        <label className='d-flex align-middle'>
                          <Switch
                            className='mx-3 my-2'
                            activeBoxShadow
                            checkedIcon={false}
                            uncheckedIcon={false}
                            handleDiameter={22}
                            width={50}
                            onColor='#005DBF'
                            onChange={e =>
                              this.setState({
                                ...this.state,
                                addSupports: { ...this.state.addSupports, authVerifyInfoDoc: e },
                              })
                            }
                            checked={this.state.addSupports.authVerifyInfoDoc ? true : false}
                          />
                          <span className='my-2'>
                            {this.state.addSupports.authVerifyInfoDoc ? 'Autorizo' : 'No autorizo'}
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className={`${style.containerRow} mx-3`}>
                      <span>
                        <b className='mr-1'>•</b>
                        <b>
                          Autorización de visita domiciliaria, registro fotográfico y de huellas
                          dactilares
                        </b>
                      </span>
                    </div>
                    <div className={`${style.containerRow} mx-3`}>
                      <div className={`${style.colTwelve} d-flex flex-column`}>
                        <div
                          className='mx-3 d-flex'
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              modalVisitRecord: !this.state.modalVisitRecord,
                            });
                          }}
                        >
                          <span>
                            <img
                              src={Bill}
                              width='20'
                              alt='doc icon'
                              className={`${style.verticalSub} mr-2`}
                            />
                            <span
                              className={`${style.darkGrayColor} text-decoration-underline hoverPointer`}
                            >
                              Términos y condiciones de autorización
                            </span>
                          </span>
                        </div>
                        <label className='d-flex align-middle'>
                          <Switch
                            className='mx-3 my-2'
                            activeBoxShadow
                            checkedIcon={false}
                            uncheckedIcon={false}
                            handleDiameter={22}
                            width={50}
                            onColor='#005DBF'
                            onChange={e =>
                              this.setState({
                                ...this.state,
                                addSupports: { ...this.state.addSupports, authVisitRecord: e },
                              })
                            }
                            checked={this.state.addSupports.authVisitRecord ? true : false}
                          />
                          <span className='my-2'>
                            {this.state.addSupports.authVisitRecord ? 'Autorizo' : 'No autorizo'}
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className={`${style.containerRow} mx-3`}>
                      <span>
                        <b className='mr-1'>•</b>
                        <b>Autorización de tratamiento de datos personales</b>
                      </span>
                    </div>
                    <div className={`${style.containerRow} mx-3`}>
                      <div className={`${style.colTwelve} d-flex flex-column`}>
                        <div
                          className='mx-3 d-flex'
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              modalProcessPersonalData: !this.state.modalProcessPersonalData,
                            });
                          }}
                        >
                          <span>
                            <img
                              src={Bill}
                              width='20'
                              alt='doc icon'
                              className={`${style.verticalSub} mr-2`}
                            />
                            <span
                              className={`${style.darkGrayColor} text-decoration-underline hoverPointer`}
                            >
                              Términos y condiciones de autorización
                            </span>
                          </span>
                        </div>
                        <label className='d-flex align-middle'>
                          <Switch
                            className='mx-3 my-2'
                            activeBoxShadow
                            checkedIcon={false}
                            uncheckedIcon={false}
                            handleDiameter={22}
                            width={50}
                            onColor='#005DBF'
                            onChange={e =>
                              this.setState({
                                ...this.state,
                                addSupports: {
                                  ...this.state.addSupports,
                                  authProcessPersonalData: e,
                                },
                              })
                            }
                            checked={this.state.addSupports.authProcessPersonalData ? true : false}
                          />
                          <span className='my-2'>
                            {this.state.addSupports.authProcessPersonalData
                              ? 'Autorizo'
                              : 'No autorizo'}
                          </span>
                        </label>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
                {/* !SECTION */}
                {/* SECTION Firma y envío */}
                <div className='position-relative'>
                  <div
                    className={`${style.checkAccordion} ${
                      !!this.state.checkSignature && style.checkAccordionActive
                    } mx-3 align-top`}
                  >
                    {!!this.state.checkSignature && (
                      <img src={CheckMark} alt='Indicador de completado' />
                    )}
                  </div>
                </div>
                <Accordion.Toggle
                  as={Button}
                  variant=''
                  eventKey='6'
                  className={`${style.itemTextAccordion} ${style.blueBackgroundCard}`}
                  name='collapsedItemSignature'
                  onClick={() =>
                    this.setState({
                      collapsedItemSignature: !this.state.collapsedItemSignature,
                    })
                  }
                >
                  <img
                    src={DownArrow}
                    alt='abrir submenu'
                    className={
                      this.state.collapsedItemSignature
                        ? style.downArrowIcon
                        : style.downArrowIconHorizontal
                    }
                  />
                  <span className={style.textDarkBlue}>Firma y envío</span>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='6'>
                  <Card.Body className={style.blueBackgroundCard}>
                    {/*ANCHOR first row */}
                    <div className='d-flex'>
                      <span>
                        <b className='mr-1'>•</b>
                        <b> Datos de la persona que diligencia el formulario </b>
                      </span>
                    </div>

                    <div className={`${style.containerRow} mx-`}>
                      <div className={`${style.selectGroup} ${style.colSix}`}>
                        <label htmlFor='completeName' className={`${style.labelFontGray}`}>
                          Nombre completo{asterisk}
                        </label>
                        <input
                          type='text'
                          placeholder='Escribir...'
                          name='completeName'
                          className={`${style.inputText} w-100 px-2`}
                          value={
                            (this.props.providerEdit?.completeName
                              ? this.props.providerEdit.completeName
                              : `${this.props.user_data.first_name} ${this.props.user_data.first_surname} ${this.props.user_data.second_surname ?? ''}`) ||
                            ''
                          }
                          readOnly
                        />
                      </div>
                      {/* doc type */}
                      <div className={`${style.selectGroup} ${style.colSix}`}>
                        <label htmlFor='docTypeSignature' className={`${style.labelFontGray}`}>
                          Tipo de doc.{asterisk}
                        </label>
                        <input
                          name='docTypeSignature'
                          className={`${style.selectInput} w-100 px-2 `}
                          readOnly
                          value={
                            this.props.providerEdit?.docTypeSignature
                              ? this.props.providerEdit.docTypeSignature
                              : this.props.user_data.doc_type
                          }
                        />
                      </div>
                      <div className={` ${style.colSix}`}>
                        <label htmlFor='docNumSignature' className={`${style.labelFontGray}`}>
                          Número de doc.{asterisk}
                        </label>
                        <input
                          name='docNumSignature'
                          placeholder='Escribir...'
                          className={`${style.inputText} w-100 px-2`}
                          value={
                            formatteDocument((this.props.providerEdit?.docNumSignature
                              ? this.props.providerEdit.docNumSignature
                              : this.props.user_data.doc_num) || '').format
                          }
                          disabled
                        />
                      </div>
                      {/* second surname */}
                      <div className={`${style.selectGroup} ${style.colSix}`}>
                        <label htmlFor='expLocationSignature' className={`${style.labelFontGray}`}>
                          Lugar de expedición{asterisk}
                        </label>
                        <input
                          name='expLocationSignature'
                          className={`${style.inputText} w-100 px-2`}
                          value={
                            this.props.providerEdit?.expLocationSignature
                              ? this.props.providerEdit?.expLocationSignature
                              : this.props.user_data.doc_city
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <div className='d-flex mt-3'>
                      <span>
                        <b className='mr-1'>•</b>
                        <b>Constancia y Firma</b>
                      </span>
                    </div>
                    <div className={`${style.containerRow}`}>
                      <div className={`${style.colTwelve} d-flex flex-column`}>
                        <label className='d-flex align-middle'>
                          <Switch
                            className='mr-1 my-2'
                            activeBoxShadow
                            checkedIcon={false}
                            uncheckedIcon={false}
                            handleDiameter={22}
                            width={50}
                            onColor='#005DBF'
                            onChange={e =>
                              this.setState({
                                ...this.state,
                                addSupports: {
                                  ...this.state.addSupports,
                                  checkConstancySignature: e,
                                },
                              })
                            }
                            checked={this.state.addSupports.checkConstancySignature ? true : false}
                          />
                          <p style={{ color: '#58595B' }} className='ml-3'>
                            Como constancia de haber leído, entendido y aceptado lo anterior,
                            declaro que la información que he suministrado es exacta en todas sus
                            partes y firmo el siguiente documento
                          </p>
                        </label>
                      </div>
                    </div>
                    <div className={style.containerRow}>
                      <div className={style.containerDropzone}>
                        <Dropzone
                          onDrop={acceptedFiles => this.validateFileSignature(acceptedFiles)}
                          onChange={acceptedFiles => this.validateFileSignature(acceptedFiles)}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section
                              className={style.dropzoneSignature}
                              style={
                                this.state.addSupports.signature
                                  ? {
                                      backgroundImage: `url(${this.state.addSupports.signature})`,
                                      border: '2px solid #005DBF',
                                      backgroundSize: 'contain',
                                      backgroundPosition: 'center',
                                      backgroundRepeat: 'no-repeat',
                                    }
                                  : null
                              }
                            >
                              <div {...getRootProps()}>
                                <input
                                  {...getInputProps()}
                                  type='file'
                                  placeholder='Adjuntar'
                                  name='signature'
                                  accept={ACCEPT_IMAGES}
                                  multiple={false}
                                />
                                {this.state.fileValid || this.state.addSupports.signature ? (
                                  <label
                                    htmlFor='signature'
                                    className={`text-primary text-center font-size-label px-5 pt-3 pb-5 hoverPointer`}
                                  ></label>
                                ) : (
                                  <img
                                    src={Imagen}
                                    alt='Imagen'
                                    key='imagen firma'
                                    className={style.imageIconDropzone}
                                  />
                                )}
                                {typeof this.state.addSupports.signature === 'string' &&
                                this.state.addSupports.signature !== '' ? (
                                  <div></div>
                                ) : (
                                  <label
                                    htmlFor='signature'
                                    className={`text-primary text-center font-size-label ${this.state.fileValid && this.state.addSupports.signature !== '' ? 'px-5 pt-3 pb-5' : 'px-5 pt-5'} hoverPointer`}
                                  >
                                    {this.state.fileValid && this.state.fileUpload
                                      ? this.state.fileUpload[0].name
                                      : 'Soltar archivo aquí'}
                                  </label>
                                )}
                              </div>
                            </section>
                          )}
                        </Dropzone>
                        <h6 className={style.signatureLabel}>Firma de Representante Legal</h6>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
                {/* !SECTION Firma y envío */}
              </Card>
            </div>
          </Accordion>
          <div className={`d-flex justify-content-between mt-3 align-bottom ${style.ml4}`}>
            <p className='align-self-end'>
              <span className={style.quaternaryColor}>•</span> Al diligenciar el formulario, tome en
              cuenta las siguientes{' '}
              <b
                className={`${style.quaternaryColor} hoverPointer`}
                onClick={this.toggleModalObservations}
              >
                Observaciones
              </b>
            </p>
            <ModalCreateRecord
              show={this.state.showObservationModal}
              size='xl'
              title='Observaciones'
              hideCancelButton={true}
              onHide={this.toggleModalObservations}
            >
              <p className={style.borderParagraph}>
                El firmante de este formulario, en su propio nombre o en nombre de la entidad que
                representa, declara que la información suministrada es verídica y da su
                consentimiento expreso e irrevocable a {this.props.currentAccountName}., o a quien
                en el futuro haga sus veces como titular del crédito o servicio solicitado, para:
                <br />
                <br />
                a) Consultar, en cualquier tiempo, en Data Crédito o en cualquier otra base de datos
                manejada por un operador de información financiera y crediticia, toda la información
                relevante para conocer su desempeño como deudor, su capacidad de pago, la viabilidad
                para entablar o mantener una relación contractual, o para cualquier otra finalidad,
                incluyendo sin limitarse la realización de campañas de mercadeo, ofrecimiento de
                productos y publicidad en general.
                <br />
                <br />
                b) Reportar a Data Crédito o a cualquier otra base de datos manejada por un operador
                de datos, tratados o sin tratar, sobre el cumplimiento o incumplimiento de sus
                obligaciones crediticias, sus deberes legales de contenido patrimonial, sus datos de
                ubicación y contacto (número de teléfono fijo, número de teléfono celular, dirección
                del domicilio, dirección laboral y correo electrónico), sus solicitudes de crédito
                así como otros atinentes a sus relaciones comerciales, financieras y en general
                socioeconómicas que haya entregado o que consten en registros públicos, bases de
                datos públicas o documentos públicos. La autorización anterior no impedirá al abajo
                firmante o su representada, ejercer el derecho a corroborar en cualquier tiempo en{' '}
                {this.props.currentAccountName}, en data crédito o en la central de información de
                riesgo a la cual se hayan suministrado los datos, que la información suministrada es
                veraz, completa, exacta y actualizada, y en caso de que no lo sea, a que se deje
                constancia de su desacuerdo, a exigir la rectificación y a ser informado sobre las
                correcciones efectuadas.
              </p>
            </ModalCreateRecord>
            <ModalCreateRecord
              show={this.state.modalExposePeople}
              size='xl'
              title='Personas expuestas política y públicamente'
              hideCancelButton={true}
              onHide={this.toggleModalExposePeople}
            >
              <div className={style.modalScroll}>
                <p className={style.borderParagraph}>
                  Personas Expuestas Públicamente (PEP)*: Son personas nacionales o extranjeras que
                  por razón de su cargo manejan o han manejado recursos públicos, o tienen poder de
                  disposición sobre estos o gozan o gozaron de reconocimiento público. Las PEP
                  extranjeras son individuos que cumplen o a quienes se les han confiado funciones
                  públicas prominentes en otro país, como por ejemplo los jefes de Estado o de
                  Gobierno, políticos de alto nivel, funcionarios gubernamentales o judiciales de
                  alto nivel o militares de alto rango, ejecutivos de alto nivel de corporaciones
                  estatales, funcionarios de partidos políticos importantes. Las PEP domésticas son
                  individuos que cumplen o a quienes se les han confiado funciones públicas
                  internamente, a los cuales les aplica los mismos ejemplos que las PEP extranjeras
                  mencionadas anteriormente. Las personas que cumplen o a quienes se les han
                  confiado funciones prominentes por una organización internacional se refiere a
                  quienes son miembros de la alta gerencia, es decir, directores, subdirectores y
                  miembros de la Junta o funciones equivalentes. La definición de PEP no pretende
                  cubrir a individuos en un rango medio o más subalterno en las categorías
                  anteriores, Persona Expuesta Públicamente (PEP): <br />
                  i) Las personas expuestas políticamente - (conforme al Decreto 1674 de 2016){' '}
                  <br />
                  ii) Los representantes legales de organizaciones internacionales y<br />
                  iii) Las personas que gozan de reconocimiento público. Se entiende por persona
                  políticamente expuesta (Decreto 1674/2016) los individuos que desempeñan o han
                  desempeñado funciones públicas destacadas como jefes de Estado, políticos de alta
                  jerarquía, funcionarios gubernamentales, judiciales o militares de alta jerarquía,
                  altos ejecutivos (directores y gerentes) de empresas sociales, industriales y
                  comerciales del estado y de sociedades de economía mixta, unidades administrativas
                  especiales, y funcionarios importantes de partidos políticos. <br />
                  Administradores (Ley 222 de 1995, art.22): Son administradores, el representante
                  legal, el liquidador, el factor, los miembros de juntas o consejos directivos y
                  quienes de acuerdo con los estatutos ejerzan o detenten esas funciones. <br />; Se
                  considera para la compañía, vinculados: Las personas que tengan sociedad conyugal,
                  de hecho, o de derecho, con las personas públicamente expuestas, los familiares
                  hasta el primer grado de consanguinidad, primero de afinidad y primero civil de
                  las personas públicamente expuestas. Nota interpretativa: Recomendación 24-GAFI:
                  Como parte del proceso para asegurar que exista una transparencia adecuada sobre
                  las personas jurídicas, los países deben contar con mecanismos que lo Identifiquen
                  y describan los diferentes tipos, formas y características básicas de las personas
                  jurídicas en el país o Identifiquen y describan los procesos para: (i) la creación
                  de esas personas jurídicas; y (ii) la obtención y registro de información básica y
                  sobre el beneficiario final; o pongan a disposición del público la anterior
                  información; y o evalúen los riesgos de lavado de Activos y Financiación del
                  Terrorismo asociados a diferentes tipos de personas jurídicas creadas en el país.
                  PEP: Significa personas expuestas políticamente, es decir, son los servidores
                  públicos de cualquier sistema de nomenclatura y clasificación de empleos de la
                  administración pública nacional y territorial, cuando en los cargos que ocupen,
                  tengan en las funciones del área a la que pertenecen o en las de la ficha del
                  empleo que ocupan, bajo su responsabilidad directa o por delegación, la dirección
                  general, de formulación de políticas institucionales y de adopción de planes,
                  programas y proyectos, el manejo directo de bienes, dineros o valores del Estado.
                  Estos pueden ser a través de ordenación de gasto, contratación pública, gerencia
                  de proyectos de inversión, pagos, liquidaciones, administración de bienes muebles
                  e inmuebles. Incluye también a las PEP Extranjeras y las PEP de Organizaciones
                  Internacionales. PEP de Organizaciones Internacionales: Son aquellas personas
                  naturales que ejercen funciones directivas en una organización internacional,
                  entre otros (vr.gr. directores, subdirectores, miembros de junta directiva o
                  cualquier persona que ejerza una función equivalente). PEP Extranjeras: Son
                  aquellas personas naturales que desempeñan funciones públicas prominentes y
                  destacadas en otro país. En especial, las siguientes personas: (i) jefes de
                  estado, jefes de gobierno, ministros, subsecretarios o secretarios de estado; (ii)
                  congresistas o parlamentarios; (iii) miembros de tribunales supremos, tribunales
                  constitucionales u otras altas instancias judiciales cuyas decisiones no admitan
                  normalmente recurso, salvo en circunstancias excepcionales; (iv) miembros de
                  tribunales o de las juntas directivas de bancos centrales; (v) embajadores; (vi)
                  encargados de negocios; (vii) altos funcionarios de las fuerzas armadas; (viii)
                  miembros de los órganos administrativos, de gestión o de supervisión de empresas
                  de propiedad estatal; (ix) miembros de familias reales reinantes; (x) dirigentes
                  destacados de partidos o movimientos políticos; y (xi) representantes legales,
                  directores, subdirectores, miembros de la alta gerencia y miembros de la Junta de
                  una organización internacional (vr.gr. jefes de estado, políticos, funcionarios
                  gubernamentales, judiciales o militares de alta jerarquía y altos ejecutivos de
                  empresas estatales).
                </p>
              </div>
            </ModalCreateRecord>
            <ModalCreateRecord
              show={this.state.modalConsultBackground}
              size='xl'
              title='Autorización de consulta de antecedentes'
              hideCancelButton={true}
              onHide={() => this.setState({ ...this.state, modalConsultBackground: false })}
            >
              <div className={style.modalScroll}>
                <p className={style.borderParagraph}>
                  Expresa e irrevocablemente autorizo a o a quien represente sus derechos:
                  <br />
                  Para que realice las consultas y controles establecidos en los términos de su
                  política de prevención de lavado de activos y financiación al terrorismo para
                  verificar en las fuentes que considere necesarias, la información suministrada en
                  el presente documento, tales como los listados de la OFAC (Office of ForeignAssets
                  Control) o de las Naciones Unidas. (ii) Para efectuar los reportes a las
                  autoridades competentes en materia de lavado de activos y financiación al
                  terrorismo, que considere necesarios realizar de conformidad con sus reglamentos y
                  manuales de prevención y/o administración del riesgo de lavado de activos y
                  financiación al terrorismo. (iii) Para que, de acuerdo con lo establecido en la
                  Ley 1581 de 2012, reglamentada por el Decreto 1377 de 2013, haga uso de mis datos
                  personales y en general de la información obtenida en virtud de la relación
                  comercial establecida con la {this.props.currentAccountName}, para fines
                  operativos, comerciales, publicitarios y estadísticos, que entre otras
                  actividades, permita la remisión de dicha información a las entidades otorgantes
                  de créditos, subsidios y demás productos financieros, así como la remisión de
                  información publicitaria y de ofertas comerciales referidas a los productos y
                  servicios ofrecidos por {this.props.currentAccountName} Declaro que me encuentro
                  facultado para conocer en todo momento, la información de la cual soy titular y
                  para solicitar la eliminación, rectificación o actualización de dicha información
                  a la dirección de notificación de {this.props.currentAccountName} De conformidad
                  con las normas legales vigentes. Consultar ante cualquier entidad de Central de
                  información o base de datos , la información y referencias que declaren o
                  necesiten, tanto de la persona natural y/o jurídica que represento, de los
                  representantes legales , revisor fiscal, miembros de la junta directiva, así como
                  de los accionistas o socios que tengan una participación superior al 5% del
                  capital social, con el fin de prevenir cualquier tipo de actividad relativa al
                  lavado de activos o financiación del terrorismo, así como la información y
                  referencias propias, en mi calidad de persona natural.
                </p>
              </div>
            </ModalCreateRecord>
            <ModalCreateRecord
              show={this.state.modalVerifyInfoDoc}
              size='md'
              title='Verificación de información'
              hideCancelButton={true}
              onHide={() => this.setState({ ...this.state, modalVerifyInfoDoc: false })}
            >
              <p className={style.borderParagraph} style={{ padding: '1rem 1rem 4rem 1rem' }}>
                Autorizo a {this.props.currentAccountName} Para que adelante la comprobación de
                todos los datos contenidos en los formularios diligenciados y anexos entregados a
                través de los medios que considere convenientes.
              </p>
            </ModalCreateRecord>
            <ModalCreateRecord
              show={this.state.modalVisitRecord}
              size='lg'
              title='Visita, registro y huellas'
              hideCancelButton={true}
              onHide={() => this.setState({ ...this.state, modalVisitRecord: false })}
            >
              <p className={style.borderParagraph} style={{ padding: '1rem 1rem 4rem 1rem' }}>
                Autorizo irrevocablemente a la {this.props.currentAccountName} o a la persona que
                ésta designe, el acceso a mi residencia (persona natural) o ubicación Comercial
                (Persona Jurídica) para realizar la visita domiciliaria con el fin de validar la
                información registrada; igualmente consiento la elaboración de registro de huellas y
                fotografías.
              </p>
            </ModalCreateRecord>
            <ModalCreateRecord
              show={this.state.modalProcessPersonalData}
              size='xl'
              title='Tratamiento de datos personales'
              hideCancelButton={true}
              onHide={() => this.setState({ ...this.state, modalProcessPersonalData: false })}
            >
              <div className={style.modalScroll}>
                <p className={style.borderParagraph}>
                  De conformidad con lo dispuesto en la Constitución Política de Colombia, la Ley
                  1581 de 2012 y el Decreto 1377 de 2013, el titular declara que entrega de forma
                  libre y voluntaria los siguientes datos personales: Nombres y apellidos, documento
                  de identificación, dirección, ciudad, departamento, teléfonos, celular, fecha de
                  nacimiento, correo electrónico, información profesional y de la empresa (si
                  aplica), entre otros, en adelante los “Datos Personales”, a nombre de{' '}
                  {this.props.currentAccountName}, en adelante {this.props.currentAccountName}. En
                  los términos de las definiciones de la Ley 1581 de 2012,{' '}
                  {this.props.currentAccountName} actúa como responsable y encargado del tratamiento
                  de los Datos Personales.
                  <br />
                  El titular autoriza expresamente que {this.props.currentAccountName} recolecte y
                  de cualquier otra manera traten los Datos Personales entre otras cosas, con alguna
                  de las siguientes finalidades: registro de datos para la creación de cuentas de
                  usuarios para la compra de productos en su plataforma www.
                  {this.props.currentAccountName}.com, comunicar campañas promocionales, realizar
                  encuestas, anunciar noticias, realizar concursos, Invitar a eventos, ejecutar
                  campañas de fidelización, realizar actualización de datos, entre otros. El titular
                  de los datos personales tiene los derechos consagrados en el artículo 8 de la ley
                  1581 de 2012 y que fueron plasmados dentro Política de Tratamiento de Datos
                  Personales adoptado por {this.props.currentAccountName}. Con el fin de ejercer los
                  derechos anteriormente mencionados, realizar consultas o reclamos relacionados con
                  los Datos Personales, el titular puede dirigirse físicamente a la dirección Calle
                  86 No 50 – 129 en la ciudad de Barranquilla, Colombia o al correo electrónico
                  protecciondatos@{this.props.currentAccountName}.com.co al teléfono 57 5-3226161.
                  Conforme a la obligación establecida en el artículo 15 de la ley 1581 de 2012. Con
                  la firma de este documento el titular autoriza a que los Datos Personales sean
                  recolectados y tratados de conformidad con la Política de Tratamiento de Datos
                  Personales el cual podrá ser visualizado en cualquier momento en la página web
                  www.{this.props.currentAccountName}.com. Yo, {renderNameLglRpst} con cedula o NIT{' '}
                  {renderDocNumLglRpst}, manifiesto de manera libre y voluntaria, que autorizo a{' '}
                  {this.props.currentAccountName} para el tratamiento de mis datos personales,
                  conforme a los derechos aquí consagrados y los demás que establece la ley 1581 de
                  2012. Que los datos suministrados a {this.props.currentAccountName}, son propios y
                  en caso de ser de terceros (sociedades, entidades públicas, hijos menores, etc),
                  cuento con las respectivas autorizaciones legales para suministrar estos.
                </p>
              </div>
            </ModalCreateRecord>
            {this.myPermission()?.create && (
              <div className='d-flex justify-content-around'>
                {/* <Redirect to="/proveedores">redirect</Redirect> */}
                <Link
                  to='/administracion/proveedores'
                  className={`${style.btnOutlineDarkBlue} btn btn-none`}
                >
                  Cancelar
                </Link>
                <button
                  className={`${
                    !this.state.allowDraft || this.state.addSupports?.status === 'enabled'
                      ? style.btnDisabled
                      : style.btnPrimary
                  } mx-3`}
                  disabled={this.state.addSupports?.status === 'enabled' || !this.state.allowDraft}
                  onClick={this.handleSubmitDraft}
                  name={'draft'}
                >
                  Guardar Borrador
                </button>
                <button
                  className={`${this.state.allowSent ? style.btnPrimaryShort : style.btnSecondaryDisabled}`}
                  disabled={!this.state.allowSent}
                  onClick={this.handleSubmitDraft}
                  name={'send'}
                >
                  Enviar
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
//SECTION PROPS
const mapStateToProps = state => ({
  currentAccount: state.loginReducer.currentAccount,
  isAuth: state.loginReducer.Authenticated,
  // ownProps: ownProps,
  loading: state.providerReducer.loading,
  ciiu: state.providerReducer.ciiu,
  countries: state.providerReducer.countries,
  provinces: state.locationReducer.provinces,
  cities: state.locationReducer.cities,
  allProvinces: state.locationReducer.allProvinces,
  allCities: state.locationReducer.allCities,
  al_provinces: state.locationReducer.provincesAlternative,
  al_cities: state.locationReducer.citiesAlternative,
  providerClassification: state.providerReducer.providerClassification,
  providerDraft: state.providerReducer.providerDraft,
  providerEdit: state.providerReducer.providerEdit,
  bankingEntities: state.providerReducer.bankingEntities,
  idAccount: state.loginReducer.currentAccount?.id,
  currentAccountName: state.loginReducer.currentAccount?.name,
  user_data: state.loginReducer.user_data,
  token: state.loginReducer.Authorization,
  taxPayerTypes: state.providerReducer.taxPayerTypes,
  listTypeServices: state.providerReducer.listTypeServices,
  listLaboratories: state.providerReducer.listLaboratories,
  initialListTypeServices: state.providerReducer.typeService,
  initialListLaboratories: state.providerReducer.laboratory,
});

const mapDispatchToProps = dispatch => ({
  createDraft: (objProvider, action) => {
    dispatch(createDraft(objProvider, action));
  },
  getCountries: params => {
    dispatch(getCountries(params));
  },
  province_get: params => {
    dispatch(province_get(params));
  },
  city_get: params => {
    dispatch(city_get(params));
  },
  province_get_all: () => {
    dispatch(province_get_all());
  },
  city_get_all: () => {
    dispatch(city_get_all());
  },
  getCIIU: () => {
    dispatch(getCIIU());
  },
  getProviderClassifications: params => {
    dispatch(getProviderClassifications(params));
  },
  getBankingEntities: () => {
    dispatch(getBankingEntities());
  },
  getTaxPayerType: () => {
    dispatch(getTaxPayerType());
  },
  getLaboratories: () => {
    dispatch(getLisLaboratories());
  },
  getListTypeServices: () => {
    dispatch(getListTypeServices());
  },
  getServiceByIdProvider: idProvider => {
    dispatch(getServiceByIdProvider(idProvider));
  },
  getLaboratoryByIdProvider: idProvider => {
    dispatch(getLaboratoryByIdProvider(idProvider));
  },
});
//!SECTION PROPS
export default connect(mapStateToProps, mapDispatchToProps)(FormLegalPerson);
