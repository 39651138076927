export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const SHOW_ACCOUNT_INFO = "SHOW_ACCOUNT_INFO";
export const SAVE_USER_INFO = "SAVE_USER_INFO";
export const DISPLAY_INFO_MESSAGE_LOGIN = "DISPLAY_INFO_MESSAGE_LOGIN";
export const HIDE_INFO_MESSAGE_LOGIN = "HIDE_INFO_MESSAGE_LOGIN";
export const GET_USERS = "GET_USERS";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const PUC_LOADING = "PUC_LOADING";
export const ACTIVE_ID = "ACTIVE_ID";
export const SET_INDEX_USER = "SET_INDEX_USER";
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const SET_LOADING_USER = "SET_LOADING_USER";
export const SET_LOADING_PROFILE = "SET_LOADING_PROFILE";
export const GET_PROFILES_FROM_ACCOUNT = "GET_PROFILES_FROM_ACCOUNT";
export const SET_INDEX_FILTER_USER = "SET_INDEX_FILTER_USER";
export const USER = "USER";
export const CREATE_USER = "CREATE_USER";
export const GET_PROFILES = "GET_PROFILES";
export const PERMISSION_MODULE_GET = "PERMISSION_MODULE_GET";
export const PERMISSION_FUNCTIONALITY_GET = "PERMISSION_FUNCTIONALITY_GET";
export const PERMISSION_GET = "PERMISSION_GET";
export const PERMISSION_PUT = "PERMISSION_PUT";
export const CUPS_GET = "CUPS_GET";
export const CUPS_EDIT = "CUPS_EDIT";
export const GET_DISTANCES_SELECT = "GET_DISTANCES_SELECT";
export const CLASSIFICATION_GET = "CLASSIFICATION_GET";
export const CLASSIFICATION_GETONE = "CLASSIFICATION_GETONE";
export const CLASSIFICATION_EDIT = "CLASSIFICATION_EDIT";
export const CLASSIFICATION_POST = "CLASSIFICATION_POST";
export const CLASSIFICATION_DELETE = "CLASSIFICATION_DELETE";
export const GET_SITES = "GET_SITES";
export const GET_SITES_SELECT = "GET_SITES_SELECT";
export const CREATE_SITE_SUCCESS = "CREATE_SITE_SUCCESS";
export const ERROR_CREATE_SITE = "ERROR_CREATE_SITE";
export const SET_INDEX_SITE = "SET_INDEX_SITE";
export const GET_CIETEN = "GET_CIETEN";
export const SET_INDEX_CIETEN = "SET_INDEX_CIETEN";
export const CHANGE_PAGE_CIETEN = "CHANGE_PAGE_CIETEN";
export const COUNT_USERS = "COUNT_USERS";
export const GET_NIIF_ACCOUNTS = "GET_NIIF_ACCOUNTS";
export const GET_LOCAL_ACCOUNTS = "GET_LOCAL_ACCOUNTS";
export const SET_INDEX_NIIF = "SET_INDEX_NIIF";
export const SET_INDEX_LOCAL_ACCOUNTS = "SET_INDEX_LOCAL_ACCOUNTS";
export const GET_ALL_ACCOUNTS = "GET_ALL_ACCOUNTS";
export const SET_INDEX_PUC = "SET_INDEX_PUC";
export const SET_TOTAL_INDEX_PUC = "SET_TOTAL_INDEX_PUC";
export const GET_PUC_ACCOUNTS = "GET_PUC_ACCOUNTS";
export const RENDER_ACCOUNTS = "RENDER_ACCOUNTS";
export const CREATE_PUC_ACCOUNT_SUCCESS = "CREATE_PUC_ACCOUNT_SUCCESS";
export const ERROR_CREATE_PUC_ACCOUNT = "ERROR_CREATE_PUC_ACCOUNT";
export const CUENTAS_GET = "CUENTAS_GET";
export const CUENTAS_GETONE = "CUENTAS_GETONE";
export const CUENTAS_EDIT = "CUENTAS_EDIT";
export const CUENTAS_POST = "CUENTAS_POST";
export const CITY_GET = "CITY_GET";
export const PROVINCE_GET = "PROVINCE_GET";
export const COUNTRY_GET = "COUNTRY_GET";
export const FUNCTIONAL_GET = "FUNCTIONAL_GET";
export const FUNCTIONAL_GETONE = "FUNCTIONAL_GETONE";
export const FUNCTIONAL_EDIT = "FUNCTIONAL_EDIT";
export const FUNCTIONAL_POST = "FUNCTIONAL_POST";
export const FUNCTIONAL_GET_NIIF = "FUNCTIONAL_GET_NIIF";
export const FUNCTIONAL_ACTIVES = "FUNCTIONAL_ACTIVES";
export const FUNCTIONAL_LOADING = "FUNCTIONAL_LOADING";
export const COSTCENTER_LOAD = "COSTCENTER_LOAD";
export const COSTCENTER_GET = "COSTCENTER_GET";
export const COSTCENTER_GETONE = "COSTCENTER_GETONE";
export const COSTCENTER_EDIT = "COSTCENTER_EDIT";
export const COSTCENTER_POST = "COSTCENTER_POST";
export const GET_ALL_COST_CENTER = "GET_ALL_COST_CENTER";
export const COLLECTION_GET = "COLLECTION_GET";
export const COLLECTION_GETONE = "COLLECTION_GETONE";
export const COLLECTION_EDIT = "COLLECTION_EDIT";
export const COLLECTION_POST = "COLLECTION_POST";
export const GET_ALL_COLLECTION = "GET_ALL_COLLECTION";
export const COLLECTION_LOAD = "COLLECTION_LOAD";
export const GET_LIST_NIIF = "GET_LIST_NIIF";
export const GET_LIST_LOAD = "GET_LIST_LOAD";
export const GET_MU = "GET_MU";
export const SET_INDEX_MU = "SET_INDEX_MU";
export const GET_LIST_INVENTORY_FAMILIES_TO_SELECT =
  "GET_LIST_INVENTORY_FAMILIES_TO_SELECT";
export const GET_LIST_MANUFACTURERS_TO_SELECT =
  "GET_LIST_MANUFACTURERS_TO_SELECT";
export const CREATE_ARTICLE = "CREATE_ARTICLE";
export const GET_ALL_ARTICLES = "GET_ALL_ARTICLES";
export const SET_INDEX_ARTICLES = "SET_INDEX_ARTICLES";
export const SET_TOTAL_INDEX_ARTICLES = "SET_TOTAL_INDEX_ARTICLES";
export const SUCCESS_UPLOAD = "SUCCESS_UPLOAD";
export const LOADING = "LOADING";
export const GET_ALL_MU = "GET_ALL_MU";
export const GET_NIIF_AUX = "GET_NIIF_AUX";
export const GET_ADD_INFO = "GET_ADD_INFO";
export const INFO_ID = "INFO_ID";
export const GET_INVENTORY_FAMILY = "GET_INVENTORY_FAMILY";
export const SET_INDEX_INVENTORY_FAMILY = "SET_INDEX_INVENTORY_FAMILY";
export const GET_INVENTORY_ACTIVE = "GET_INVENTORY_ACTIVE";
export const GET_CON_CENTER = "GET_CON_CENTER";
export const GET_CON_CENTER_ONE = "GET_CON_CENTER_ONE";
export const SET_INDEX_CON_CENTER = "SET_INDEX_CON_CENTER";
export const GET_AUX_NIIF = "GET_AUX_NIIF";
export const STORAGE_SUPPLIER = "STORAGE_SUPPLIER";
export const STORAGE_GET = "STORAGE_GET";
export const STORAGE_EDIT = "STORAGE_EDIT";
export const STORAGE_POST = "STORAGE_POST";
export const ARTICLE_GET = "ARTICLE_GET";
export const DELETE_WAREHOUSE_ARTICLE = "DELETE_WAREHOUSE_ARTICLE";
export const ACCOUNT_SELECT_GET = "ACCOUNT_SELECT_GET";
export const ACCOUNT_SELECT_SAVE = "ACCOUNT_SELECT_SAVE";
export const ACCOUNT_LOADING = "ACCOUNT_LOADING";
export const CUPS_FILTER = "CUPS_FILTER";
export const REQUISITION_FILTER_LOADING = "REQUISITION_FILTER_LOADING,";
export const REQUISITION_FILTER = "REQUISITION_FILTER";
export const REQUISITION_POST = "REQUISITION_POST";
export const REQUISITION_GET = "REQUISITION_GET";
export const REQUISITION_ARTICLE = "REQUISITION_ARTICLE";
export const REQUISITION_LOADING = "REQUISITION_LOADING";
export const REQUISITION_ARTICLES_FROM_LIST = "REQUISITION_ARTICLES_FROM_LIST";
export const REQUISITION_LOADING2 = "REQUISITION_LOADING2";
export const REQUISITION_LOADING3 = "REQUISITION_LOADING3";
export const INDIVIDUALAUCTION_GET_ONE = "INDIVIDUALAUCTION_GET_ONE";
export const INDIVIDUALAUCTION_GET_ONE_LOADING =
  "INDIVIDUALAUCTION_GET_ONE_LOADING";
export const INDIVIDUALAUCTION_FILTER = "INDIVIDUALAUCTION_FILTER";
export const INDIVIDUALAUCTION_FILTER_LOADING =
  "INDIVIDUALAUCTION_FILTER_LOADING";
export const QUOTATION_FILTER = "QUOTATION_FILTER";
export const QUOTATION_FILTER_LOADING = "QUOTATION_FILTER_LOADING";
export const GET_SPECIALITY = "GET_SPECIALITY";
export const GET_SUBSPECIALITY = "GET_SUBSPECIALITY";
export const GET_ALL_SPECIALITY = "GET_ALL_SPECIALITY";
export const SET_INDEX_SPECIALITY = "SET_INDEX_SPECIALITY";
export const SET_INDEX_SUBSPECIALITY = "SET_INDEX_SUBSPECIALITY";
export const INDIVIDUALAUCTION_POST = "INDIVIDUALAUCTION_POST";
export const INDIVIDUALAUCTION_ARTICLE = "INDIVIDUALAUCTION_ARTICLE";
export const CURRENTAUCTION = "CURRENTAUCTION";
export const AUTHORIZE_PURCHASE_ORDER = "AUTHORIZE_PURCHASE_ORDER";
export const PURCHASEORDER_GET_ONE = "PURCHASEORDER_GET_ONE";
export const PURCHASEORDER_GET_ONE_LOADING = "PURCHASEORDER_GET_ONE_LOADING";
export const GET_PROVIDERS = "GET_PROVIDERS";
export const PROVINCE_GET_ALTERNATIVE = "PROVINCE_GET_ALTERNATIVE";
export const CITY_GET_ALTERNATIVE = "CITY_GET_ALTERNATIVE";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_CIIU = "GET_CIIU";
export const GET_PROVIDER_CLASSIFICATION = "GET_PROVIDER_CLASSIFICATION";
export const CREATE_DRAFT_PROVIDER = "CREATE_DRAFT_PROVIDER";
export const PROVINCE_GET_ALL = "PROVINCE_GET_ALL";
export const GET_EDIT_PROVIDER = "GET_EDIT_PROVIDER";
export const CITY_GET_ALL = "CITY_GET_ALL";
export const GET_CORPORATECLIENTS = "GET_CORPORATECLIENTS";
export const SET_CORPORATECLIENTS = "SET_CORPORATECLIENTS";
export const GET_BANKING_ENTITIES = "GET_BANKING_ENTITIES";
export const COMPANYTYPE = "COMPANYTYPE";
export const GET_PAY_CONDITIONS = "GET_PAY_CONDITIONS";
export const GET_RECEIPT_OF_INVOICES = "GET_RECEIPT_OF_INVOICES";
export const GET_STATUS = "GET_STATUS";
export const GET_CLIENT = "GET_CLIENT";
export const GET_DETAIL_RECORD = "GET_DETAIL_RECORD";
export const GET_HISTORY = "GET_HISTORY";
export const GET_ONE_MOVEMENT = "GET_ONE_MOVEMENT";
export const GET_CONTRACTS = "GET_CONTRACTS";
export const GET_ACCOUNTABLES = "GET_ACCOUNTABLES";
export const GET_BALANCE_SUM = "GET_BALANCE_SUM";
export const GET_ONE_BALANCE = "GET_ONE_BALANCE";
export const GET_PROVISION = "GET_PROVISION";
export const GET_DETAIL_DETERIORATE = "GET_DETAIL_DETERIORATE";
export const SEND_INVOICE = "SEND_INVOICE";
export const GET_CALENDAR = "GET_CALENDAR";
export const PAYLEGAL_COLLECTION_LOADING = "PAYLEGAL_COLLECTION_LOADING";
export const REQUISITION_CONSECUTIVE = "REQUISITION_CONSECUTIVE";
export const INDIVIDUALAUCTION_CONSECUTIVE = "INDIVIDUALAUCTION_CONSECUTIVE";
/* ----------------------------- EntryByPurchase ---------------------------- */
export const GET_ENTRY_BY_PURCHASE = "GET_ENTRY_BY_PURCHASE";
export const GET_ENTRY_BY_CONSIGN = "GET_ENTRY_BY_CONSIGN";
export const GET_ENTRY_BY_DONATION = "GET_ENTRY_BY_DONATION";
export const GET_MOVEMENTS_INVENTORY = "GET_MOVEMENTS_INVENTORY";
export const GET_WAREHOUSE_LIST = "GET_WAREHOUSE_LIST";
export const GET_WAREHOUSE_ENTRY_DETAIL = "GET_WAREHOUSE_ENTRY_DETAIL";
export const GET_MOVEMENTS_TYPES = "GET_MOVEMENTS_TYPES";
export const GET_ARTICLES_IN_WAREHOUSE = "GET_ARTICLES_IN_WAREHOUSE";
export const GET_ARTICLES_IN_TRANSFER_WAREHOUSE =
  "GET_ARTICLES_IN_TRANSFER_WAREHOUSE";
export const GET_ARTICLES_FOR_EACH_WAREHOUSE =
  "GET_ARTICLES_FOR_EACH_WAREHOUSE";
export const GET_MOVEMENT_FOR_EACH_ARTICLE = "GET_MOVEMENT_FOR_EACH_ARTICLE";
export const GET_WAREHOUSE_DETAILS = "GET_WAREHOUSE_DETAILS";
export const PURCHASEORDER__AUTHORIZATION_POST =
  "PURCHASEORDER__AUTHORIZATION_POST";
export const PURCHASEORDER_MODAL3 = "PURCHASEORDER_MODAL3";
export const PURCHASE_ORDER_SUMMARY_GET_ONE = "PURCHASE_ORDER_SUMMARY_GET_ONE";
export const PURCHASE_ORDER_SUMMARY_GET_ONE_LOADING =
  "PURCHASE_ORDER_SUMMARY_GET_ONE_LOADING";
//////// BILLING MODULE ///////////////////////////////////
export const GET_GENERATE_ZIP = "GET_GENERATE_ZIP";
export const GET_IVA = "GET_IVA";
export const GET_PAYMENT_TYPES = "GET_PAYMENT_TYPES";
export const GET_PENDING_VENDOR_INVOICES = "GET_PENDING_VENDOR_INVOICES";
export const GET_PURCHASEORDERSTATUS = "GET_PURCHASEORDERSTATUS";
export const GET_ARTICLES_IN_PURCHASE_ORDERS =
  "GET_ARTICLES_IN_PURCHASE_ORDERS";
export const GET_INFO_PROVIDER_IN_PURCHASE_ORDERS =
  "GET_INFO_PROVIDER_IN_PURCHASE_ORDERS";
export const GET_PROGRAMMING_IN_PURCHASE_ORDERS =
  "GET_PROGRAMMING_IN_PURCHASE_ORDERS";
export const STATUSPO_MODAL_JUSTIFY = "STATUSPO_MODAL_JUSTIFY";
export const GET_PURCHASEORDER_INWAREHOUSE = "GET_PURCHASEORDER_INWAREHOUSE";
export const GET_PROGRAMMING_INWAREHOUSE = "GET_PROGRAMMING_INWAREHOUSE";
export const GET_PROVIDRES_WITHPO = "GET_PROVIDRES_WITHPO";
export const GET_REPORT_DETAILS = "GET_REPORT_DETAILS";
export const GET_PROVIDERS_NOTES = "GET_PROVIDERS_NOTES";
export const GET_PROVIDERS_LIST = "GET_PROVIDERS_LIST";
export const GET_BILLS_AND_VALUES = "GET_BILLS_AND_VALUES";
export const GET_NOTE_TYPES_LIST = "GET_NOTE_TYPES_LIST";
export const GET_RECORD_NOTES_LIST = "GET_RECORD_NOTES_LIST";
export const GET_BILLING_HISTORY_DETAILS = "GET_BILLING_HISTORY_DETAILS";
export const GET_BILLING_ACTS = "GET_BILLING_ACTS";
export const GET_BILLING_ACTS_DETAILS = "GET_BILLING_ACTS_DETAILS";
export const GET_LOTS = "GET_LOTS";
export const GET_OUTPUT_ARTICLES = "GET_OUTPUT_ARTICLES";
export const GET_ARTICLES_INWAREHOUSE = "GET_ARTICLES_INWAREHOUSE";
export const GET_WE_PROBLEMS = "GET_WE_PROBLEMS";
export const GET_AUTH_STATUS = "GET_AUTH_STATUS";
export const POST_ENDOWNMENT_DELIVERIES = "POST_ENDOWNMENT_DELIVERIES";
export const GET_AGREEMENTS = "GET_AGREEMENTS";
export const GET_AGREEMENTS_PROVIDERS = "GET_AGREEMENTS_PROVIDERS";
export const GET_AGREEMENT_DETAILS = "GET_AGREEMENT_DETAILS";
export const GET_PURCHASE_ORDERS = "GET_PURCHASE_ORDERS";
export const GET_WAREHOUSE_ENTRIES = "GET_WAREHOUSE_ENTRIES";
export const CATEGORY_SELECT_SAVE = "CATEGORY_SELECT_SAVE";
export const GET_CONTRACT_TYPES_LIST = "GET_CONTRACT_TYPES_LIST";
export const PAYROLL_STATUS = "PAYROLL_STATUS";
export const GO_TO_SALIDA_CONSUMO = "GO_TO_SALIDA_CONSUMO";
export const LOTS_FROM_WAREHOUSE = "LOTS_FROM_WAREHOUSE";
export const LOTS_FROM_WAREHOUSE_LOADING = "LOTS_FROM_WAREHOUSE_LOADING";
export const GET_DEDUCTION_ACCRUALS = "GET_DEDUCTION_ACCRUALS";
export const GET_DOCUMENT_TYPES = "GET_DOCUMENT_TYPES";
export const GET_RECIPIENT_TYPES = "GET_RECIPIENT_TYPES";
export const GET_PETTY_CASH = "GET_PETTY_CASH";
export const GET_THIRD_PARTIES = "GET_THIRD_PARTIES";
export const GET_LIST_USERS = "GET_LIST_USERS";
export const GET_LIST_WORK_ENV_POLL = "GET_LIST_WORK_ENV_POLL";
export const GET_DAY_CALENDAR = "GET_DAY_CALENDAR";
export const GET_DETAIL_CALENDAR = "GET_DETAIL_CALENDAR";
export const GET_TYPE_ACTIVITY = "GET_TYPE_ACTIVITY";
export const GET_RADICATIONS = "GET_RADICATIONS";
export const GET_UNFILED_INVOICES = "GET_UNFILED_INVOICES";
export const GET_BUSSINESS_WALLET = "GET_BUSSINESS_WALLET";
export const GET_CONSUM = "GET_CONSUM";
export const GET_TOTAL_ACC = "GET_TOTAL_ACC";
export const GET_DEBT_TOTAL_ACC = "GET_DEBT_TOTAL_ACC";
export const GET_PROVISIONED_VS_COLLECTED = "GET_PROVISIONED_VS_COLLECTED";
export const GET_PROVISIONED_VS_COLLECTED_BY_CLIENT =
  "GET_PROVISIONED_VS_COLLECTED_BY_CLIENT";
export const WALLET_BY_AGES = "WALLET_BY_AGES";
export const GET_INVENTORY_ROTATION = "GET_INVENTORY_ROTATION";
export const GET_TOP_CLIENTS = "GET_TOP_CLIENTS";
export const GET_BALANCE_IN_ACCOUNT = "GET_BALANCE_IN_ACCOUNT";
export const GET_INCOME_VS_OUTCOME = "GET_INCOME_VS_OUTCOME";
export const GET_TOTAL_ACC_TO_PAY = "GET_TOTAL_ACC_TO_PAY";
export const GET_TOP_PROVIDERS = "GET_TOP_PROVIDERS";
export const GET_CURRICULUM_ADVANCES = "GET_CURRICULUM_ADVANCES";
export const GET_ABSENTEEISM = "GET_ABSENTEEISM";
export const GET_TOTAL_INVESTMENT = "GET_TOTAL_INVESTMENT";
export const GET_ACC_TO_PAY_BY_PROV = "GET_ACC_TO_PAY_BY_PROV";
export const GET_PROBLEMS_IN_ENTRY_WH = "GET_PROBLEMS_IN_ENTRY_WH";
export const ACCOUNTS_RECEIVABLE = "ACCOUNTS_RECEIVABLE";
export const GET_DETAIL_OF_BALANCES = "GET_DETAIL_OF_BALANCES";
export const GET_FREE_AND_CAPITA = "GET_FREE_AND_CAPITA";
export const GET_GLOSSES_DETAIL = "GET_GLOSSES_DETAIL";
export const GET_BILLING_BY_TYPE = "GET_BILLING_BY_TYPE";
export const GET_PROFIT_BALANCE = "GET_PROFIT_BALANCE";
export const DISTRIBUTION_BANK_BALANCE = "DISTRIBUTION_BANK_BALANCE";
export const EXPIRED_SUPPLIES = "EXPIRED_SUPPLIES";
export const CPX_ROTATION = "CPX_ROTATION";
export const PERIOD_EXPENSES = "PERIOD_EXPENSES";
export const GET_LIST_POPULATION = "GET_LIST_POPULATION";
export const GET_ONE_POPULATION = "GET_ONE_POPULATION";
export const GET_COLLECTION_LETTER = "GET_COLLECTION_LETTER";
export const GET_TAX_PAYER_TYPE = "GET_TAX_PAYER_TYPE";
export const GET_TYPE_SERVICES = "GET_TYPE_SERVICES";
export const GET_TYPE_SERVICE_BY_ID_PROVIDER = "GET_TYPE_SERVICE_BY_ID_PROVIDER";
export const GET_LABORATORIES = "GET_LABORATORIES";
export const GET_LABORATORY_BY_ID_PROVIDER = "GET_LABORATORY_BY_ID_PROVIDER";
export const OPTIONS_IVA = "OPTIONS_IVA";
export const GET_CONFIGURATION_PARAMS = "GET_CONFIGURATION_PARAMS";
export const SEND_SURVAY_LOADING = "SEND_SURVAY_LOADING";
export const UPDATE_INDAUCTION_LOADING = "UPDATE_INDAUCTION_LOADING";
export const CREATE_INDAUCTION_LOADING = "CREATE_INDAUCTION_LOADING";
export const PURCHASEORDER_MODAL2 = "PURCHASEORDER_MODAL2";
export const PURCHASEORDER_PRINCIPAL = "PURCHASEORDER_PRINCIPAL";
export const INVENTORY_ADJUSTMENT_TYPE = "INVENTORY_ADJUSTMENT_TYPE";
export const OUTPUT_ADJS_LOADING = "OUTPUT_ADJS_LOADING";
export const AUTH_LOADING_CHANGE = "AUTH_LOADING_CHANGE";
export const GET_RESOLUTIONS = "GET_RESOLUTIONS";
export const GET_PAYROLL_PARAMS = "GET_PAYROLL_PARAMS";
export const SITE_RED_LOADING = "SITE_RED_LOADING";
export const GET_BUSINESS_TYPE = "GET_BUSINESS_TYPE";
export const GET_SOCIETY_TYPE = "GET_SOCIETY_TYPE";
export const GET_SYMPTON = "GET_SYMPTON";
export const GET_SPECIALTIES = "GET_SPECIALTIES";
export const GET_SERVICES = "GET_SERVICES";
export const FORMULAS_DATA = "FORMULAS_DATA";
export const GET_APPOINTMENTS = "GET_APPOINTMENTS";
export const GET_STATE_APPOINTMENTS = "GET_STATE_APPOINTMENTS";
export const GET_DOCTORS = "GET_DOCTORS";
export const GET_COMPANY = "GET_COMPANY";
export const UPDATE_ALL_LOGIN_DATA = "UPDATE_ALL_LOGIN_DATA";
export const SITE_SELECTED = "SITE_SELECTED";
export const USERS_GET = "USERS_GET";
export const SET_ROW_POPULATION = "SET_ROW_POPULATION";
export const COLLECTION_GET_ONE = "COLLECTION_GET_ONE";
export const COLLECTION_LOAD_ONE = "COLLECTION_LOAD_ONE";
export const LOAD_GET_CURRENCY_ALL = "LOAD_GET_CURRENCY_ALL";
export const GET_ALL_FRANCHISES = "GET_ALL_FRANCHISES";
export const GET_ALL_USERS_NEW = "GET_ALL_USERS_NEW";
export const GET_STATUS_PAYMENT = "GET_STATUS_PAYMENT";
