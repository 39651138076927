import {
    GET_THIRD_PARTIES,
  } from "../actions/actionTypes";

  const initialState = {
    thirdParties:[],
    replaces:[],
    replaces_loading : false,
    loadingRegisterVoucher : false
  };
  export const movementsReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_THIRD_PARTIES:
        return {
          ...state,
          thirdParties: action.payload,
        };

      default:
        return state;
    }
  };

