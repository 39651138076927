import { Component } from 'react';
import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import 'reactjs-popup/dist/index.css';
import Autorizacion from '../../assets/img/icons/Autorizacion.svg';
import Filtrar from '../../assets/img/icons/Filtrar.svg';
import iconPrint from '../../assets/img/icons/iconPrint.svg';
import SearchIcon from '../../assets/img/icons/lupa.svg';
import { PAGE, PERPAGE } from '../../helpers/constants';
import { convertDateToLatinFormat } from '../../helpers/convertDateFormat';
import GenericTableNew from '../Layouts/GenericTableNew';
import paginationStyles from '../Layouts/pagination.module.scss';
import ownStyles from './tableWarehouseEntry.module.scss';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import tableStyles from '../Layouts/tableStyle.module.scss';
import deepcopy from 'deepcopy';
import ReactSelect from 'react-select';
import { getPdfTest } from '../../actions/IndividualAuctionActions';
import { storage_get } from '../../actions/storageActions';
import {
  getWarehouseEntries,
  getWarehouseEntryDetail,
  getWEProblems,
} from '../../actions/warehouseActions';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';
import { message } from '../../helpers/helpers';
import ModalNew from '../Layouts/ModalNew';
import { PdfViewer } from '../Layouts/PdfViewer';
import { customSelectNewDark } from '../Layouts/react-select-custom';

class TableWarehouseEntry extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  componentDidMount() {
    this.props.getWEProblems();
    this.props.getWarehouseEntries({
      page: PAGE,
      perpage: PERPAGE,
      eaccount: this.props.currentAccount.id,
    });
    this.props.storage_get({ id_account: this.props.currentAccount.id });
    this.setState(this.initialState);
    // this.getPdf()
  }

  componentWillUnmount() {
    this.setState(this.initialState);
  }

  getPdf = async id => {
    const result = await getPdfTest(
      {
        id: id,
        doc: 11,
      },
      this.props.loginReducer.Authorization,
    );
    if (result?.success) {
      let { base64 } = this.state;
      base64 = result?.pdf[0]?.key?.split("'")[1];
      return this.setState({ base64 });
    } else {
      message('error', 'Ha ocurrido un error', result?.message);
    }
  };

  get initialState() {
    return {
      purchaseOrder: {},
      modalPurchaseOrder: false,
      redirect: null,
      modal: false,
      showPdf: false,
      base64: '',
      editRecord: false,
      addWarehouseEntry: {},
      paginationModal: {
        page: 1,
        perpage: 10,
        totalIndex: 0,
      },
      filters: {
        search: '',
        status: '',
        due_date: '',
        // current_date:CURDATE,
        eaccount: this.props.currentAccount.id,
        perpage: PERPAGE,
        page: PAGE,
      },
      headerState: [
        'Entrada',
        'Almacén',
        'Fecha de Entrada',
        'Almacenista',
        'Proveedor',
        'Autorización',
        'Estado',
        '',
      ],
    };
  }

  myPermission = () =>
    this.props.currentAccount?.profile?.permission?.find(
      x => x.functionality?.prefix === 'historyWE',
    );

  handlePageChange(pageNumber) {
    let { filters } = this.state;
    filters.page = pageNumber;
    this.props.getWarehouseEntries(filters);
    this.setState({ filters });
  }

  //Method to search when click in magnify lens
  handleSearch = () => {
    let { filters } = this.state;
    filters.page = 1;
    this.props.getWarehouseEntries(filters);
    this.setState({ filters });
  };

  onKeyDownNumber = e => {
    let { errorInputs } = this.state;
    if (e.keyCode === 69 || e.keyCode === 107 || e.keyCode === 109) {
      e.preventDefault();
      errorInputs = true;
      return customSwaltAlert({
        icon: 'warning',
        text: 'Este campo solo acepta números',
        title: 'Intenta de nuevo',
        showCancelButton: false,
      });
    }
    errorInputs = false;
    this.setState({ errorInputs });
  };

  getStatusAuth = (status, reqAuth) => {
    if (status === 'reqAuth' && reqAuth === false) {
      return {
        class: tableStyles.circleYellowAuto,
        text: 'En espera',
        auth: tableStyles.warningIcon,
      };
    }
    if (status === 'reqAuth' && reqAuth === true) {
      return {
        class: tableStyles.circleYellowAuto,
        text: 'En espera',
        auth: tableStyles.warningIcon,
      };
    }
    if (status === 'pending' && reqAuth === false) {
      return {
        class: tableStyles.circleYellowAuto,
        text: 'En espera',
        auth: null,
      };
    }
    if (status === 'rejected') {
      return {
        class: tableStyles.circleRedAuto,
        text: 'Rechazado',
        auth: tableStyles.deniedCross,
      };
    }
    if (status === 'received' && reqAuth === true) {
      return {
        class: tableStyles.circleGreenAuto,
        text: 'Recibido',
        auth: tableStyles.checkMark,
      };
    }
    if (status === 'received' && reqAuth === false) {
      return {
        class: tableStyles.circleGreenAuto,
        text: 'Recibido',
        auth: null,
      };
    }
    if (status === 'solved' && reqAuth === true) {
      return {
        class: tableStyles.circleGreenAuto,
        text: 'Recibido',
        auth: tableStyles.checkMark,
      };
    }
    if (status === 'solved' && reqAuth === false) {
      return {
        class: tableStyles.circleGreenAuto,
        text: 'Recibido',
        auth: null,
      };
    }
    if (status === 'authorized' && reqAuth === false) {
      return {
        class: tableStyles.circleGreenAuto,
        text: 'Recibido',
        auth: null,
      };
    }
  };

  // Method to get css style for circle in status
  getStatusClass = status => {
    switch (status) {
      case 'rejected':
        return { class: tableStyles.circleRedAuto, text: 'Denegado' };

      case 'vigent':
        return { class: tableStyles.circleGreenAuto, text: 'vigente' };

      case 'solved':
        return { class: tableStyles.circleGreenAuto, text: 'Recibido' };

      case 'reqAuth':
        return { class: tableStyles.circleYellowAuto, text: 'En espera' };

      case 'denied':
        return { class: tableStyles.deniedCross, text: 'Denegado' };

      case 'authorized':
        return { class: tableStyles.checkMark, text: 'Autorizado' };

      default:
        return { class: tableStyles.circleRedAuto, text: 'Vencido' };
    }
  };

  getPaymentCondition = pay => {
    switch (pay) {
      case 'prepayment':
        return 'Pago anticipado';
      case 'delivery':
        return 'Pago contraentrega';
      case 'pay20':
        return 'Pago a 20 días';
      case 'pay30':
        return 'Pago a 30 días';
      case 'pay60':
        return 'Pago a 60 días';
      case 'pay90':
        return 'Pago a 90 días';
      case null:
        return '-';
      default:
        return pay;
    }
  };

  onChangeForm = event => {
    const { name, value } = event.target;
    let { addWarehouseEntry } = this.state;
    addWarehouseEntry[name] = value;
    this.setState({ addWarehouseEntry });
  };

  onChangeFilter = event => {
    const { name, value } = event?.target ?? event;
    let { filters } = this.state;
    if (name === 'date_until' || name === 'date_from') {
      if (name === 'date_until') {
        if (filters.date_from) {
          if (value >= filters.date_from) {
            filters[name] = value;
          } else {
            customSwaltAlert({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: 'La fecha final debe ser mayor a la inicial ',
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          }
        } else {
          filters[name] = value;
        }
      } else if (name === 'date_from') {
        if (filters.date_until) {
          if (value <= filters.date_until) {
            filters[name] = value;
          } else {
            customSwaltAlert({
              icon: 'Error',
              text: 'La fecha inicial debe ser menor a la final ',
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          }
        } else {
          filters[name] = value;
        }
      }
    } else {
      filters[name] = value;
    }
    this.props.getWarehouseEntries(filters);
    this.setState({ filters });
  };

  handleOnHide = () => {
    let { modal, addWarehouseEntry } = this.state;
    modal = !modal;
    addWarehouseEntry = {};
    this.setState({ modal, addWarehouseEntry });
  };

  onKeyUpSearch = e => {
    const value = e.target.value;
    let { filters } = this.state;
    filters.search = value;
    if (e.keyCode === 13) {
      filters.page = 1;
      this.setState({ filters });
      this.props.getWarehouseEntries(filters);
    } else {
      e.preventDefault();
    }
    this.setState({ filters });
  };

  handleEditRecord = entry => {
    this.getPdf(entry.id);
    let { modal, addWarehouseEntry } = this.state;
    this.props.getWarehouseEntryDetail(entry.id, () => {
      modal = !modal;
      addWarehouseEntry = deepcopy(this.props.wEntry);
      this.setState({ modal, addWarehouseEntry });
    });
  };

  problemOptions = () => {
    let arr = [{ label: 'Seleccionar...', value: '' }];
    this.props.problems?.forEach(pro => {
      arr.push({ value: pro.id, label: pro.value });
    });
    return arr;
  };
  wareHouseOptions = () => {
    let arr = [{ label: 'Seleccionar...', value: '', name: 'we_warehouse_id' }];
    this.props.listWarehouses?.forEach(storage => {
      arr.push({ value: storage.id, label: storage.description, name: 'we_warehouse_id' });
    });
    return arr;
  };
  // ANCHOR RENDER METHOD
  render() {
    if (!this.props.isAuth) {
      return <Redirect to={'/login'} />;
    }

    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              ...this.state.addWarehouseEntry,
              ...this.state.purchaseOrder,
            },
          }}
        />
      );
    }

    let renderHeaders = this.state.headerState
      ? this.state.headerState.map(header => {
          return (
            <th key={`header-${header}`} className='text-center'>
              {header}
            </th>
          );
        })
      : '';

    var renderHeadersModal = [
      'Código',
      'Descripción del artículo',
      'Lote',
      'Cantidad ordenada',
      'Cantidad recibida',
    ].map(header => {
      return (
        <th key={`hdModal-${header}`} className='text-center'>
          {header}
        </th>
      );
    });

    // ANCHOR render entries
    let renderWarehouseEntries = this.props?.warehouseEntries?.map(item => {
      const renderTooltip = props => (
        <Tooltip {...props}>{this.getStatusClass(item?.we_status)?.text}</Tooltip>
      );

      return (
        <tr key={item.id} className='text-center p-2'>
          <td>{item.we_number}</td>
          <td className='text-start'>{item?.warehouse_description}</td>
          <td> {convertDateToLatinFormat(item.entry_date)} </td>
          <td className='text-start'>{item.we_storer}</td>
          <td className={item?.provider_name ? 'text-start' : 'text-center'}>
            {item?.provider_name ? item?.provider_name : '-'}
          </td>
          <td>
            {this.getStatusAuth(item.we_status, item.we_req_auth)?.auth ? (
              <div className={this.getStatusAuth(item.we_status, item.we_req_auth).auth}></div>
            ) : (
              '-'
            )}
          </td>
          <td>
            {/* <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip placement="top-end" id="button-tooltip" >
           {this.getStatusClass(item?.we_status)?.text}
                </Tooltip>
                // renderTooltip(elem.active)
              }
            >
              <div className={circleClass}></div>

            </OverlayTrigger> */}
            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <div
                className='rounded-pill p-1 mx-auto'
                style={{
                  width: 200,
                  backgroundColor:
                    item?.we_status === 'vigent' || item?.we_status === 'solved'
                      ? '#FAFDF6'
                      : item?.we_status === 'reqAuth'
                        ? 'rgb(254, 250, 238)'
                        : '#FEF7F5',
                }}
              >
                <b
                  style={{
                    color:
                      item?.we_status === 'vigent' || item?.we_status === 'solved'
                        ? '#83C036'
                        : item?.we_status === 'reqAuth'
                          ? 'rgb(233, 180, 25)'
                          : '#F39682',
                  }}
                >
                  {this.getStatusClass(item?.we_status)?.text}
                </b>
              </div>
              {/* <div className={circleClass}></div> */}
            </OverlayTrigger>
          </td>
          <td>
            <img
              className='hoverPointer'
              src={Filtrar}
              alt='Ver detalle'
              width={12}
              onClick={() => this.handleEditRecord(item)}
            />
          </td>
        </tr>
      );
    });

    return (
      <div className='w-100 mt-3'>
        {this.props.loading ? (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        ) : null}
        {this.myPermission()?.read ? (
          <div className={`w-100 ${this.props.loading ? 'disabled' : ''}`}>
            <div className='pb-5 mx-5 px-5'>
              <div className='row'>
                <h1 className='top-h1 text-primary'>Historial de entradas a almacén</h1>
              </div>
              <div className='row'>
                <div className='col-md-16 align-middle '>
                  <div className='row'>
                    <div className=''>
                      <div className={`${ownStyles.selectGroup}`} style={{ minWidth: '12rem' }}>
                        <label htmlFor='we_warehouse_id' className={`${ownStyles.labelFont}`}>
                          Almacén
                        </label>
                        <ReactSelect
                          styles={customSelectNewDark}
                          onChange={this.onChangeFilter}
                          placeholder={'Seleccionar...'}
                          key={'area'}
                          options={this.wareHouseOptions()}
                        />
                      </div>
                      <div
                        className={`${ownStyles.selectGroupWithOutMargin}`}
                        style={{ maxWidth: '12rem' }}
                      >
                        <label htmlFor='date_from' className={`${ownStyles.labelFont}`}>
                          Fecha desde
                        </label>
                        <input
                          type='date'
                          name='date_from'
                          className={`register-inputs`}
                          onChange={this.onChangeFilter}
                          max={this.state.filters.date_until}
                        />
                      </div>
                      <div className={ownStyles.lineBetween} />
                      <div className={`${ownStyles.selectGroup}`} style={{ maxWidth: '12rem' }}>
                        <label htmlFor='date_until' className={`${ownStyles.labelFont}`}>
                          Hasta
                        </label>
                        <input
                          type='date'
                          name='date_until'
                          className={`register-inputs`}
                          onChange={this.onChangeFilter}
                          min={this.state.filters.date_from}
                        />
                      </div>

                      <input
                        className={`${tableStyles.SearchNew}`}
                        style={{ width: '26rem' }}
                        type='text'
                        name='search'
                        onKeyUp={e => this.onKeyUpSearch(e)}
                        placeholder='Buscar...'
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            this.handleSearch();
                          }
                        }}
                      />
                      <img
                        src={SearchIcon}
                        alt='buscar'
                        className='icons-svg-top-table float-left hoverPointer'
                        onClick={this.handleSearch}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <GenericTableNew headers={renderHeaders} dark={true} lowerCase={true}>
                {renderWarehouseEntries}
              </GenericTableNew>

              {this.props.row_total > 10 && (
                <div className={`${paginationStyles.wrapper} justify-content-between`}>
                  <p style={{ display: 'inline' }}>
                    Pag. {this.state.filters.page} de{' '}
                    {Math.ceil(this.props.row_total / this.state.filters.perpage)
                      ? Math.ceil(this.props.row_total / this.state.filters.perpage)
                      : ''}{' '}
                    ({this.props.row_total} encontrados)
                  </p>

                  <Pagination
                    activePage={this.state.filters.page}
                    itemsCountPerPage={10}
                    totalItemsCount={this.props.row_total ? this.props.row_total : 10}
                    pageRangeDisplayed={10}
                    activeClass={paginationStyles.activeClass}
                    onChange={this.handlePageChange.bind(this)}
                    itemClassPrev={paginationStyles.itemClassPrev}
                    itemClassNext={paginationStyles.itemClassNext}
                    itemClassFirst={paginationStyles.itemClassFirst}
                    itemClassLast={paginationStyles.itemClassLast}
                    itemClass={paginationStyles.itemClass}
                  />
                </div>
              )}
              {/* <div className="d-flex juistify-content-start">
            <button className="btn" style={{backgroundColor:"#CECECE", color:"#fff", padding:"0.5rem 1.5rem"}}>Crear cotización</button>
          </div> */}
            </div>
          </div>
        ) : null}
        <ModalNew
          onHide={this.handleOnHide}
          show={this.state.modal}
          size='940'
          title={`Entrada ${this.state.addWarehouseEntry?.we_number}`}
          subtitle={this.state.addWarehouseEntry?.status}
        >
          {/* ---------------------INPUTS readOnly--------------------- */}
          <div className='d-flex justify-content-around'>
            <div className='col p-2'>
              <label htmlFor='purchaseOrder' className={ownStyles.labelFont}>
                Orden de compra
              </label>
              <input
                type='text'
                className={'register-inputs'}
                readOnly
                value={this.state.addWarehouseEntry?.purchaseOrder_number}
              />
            </div>
            <div className='col p-2'>
              <label htmlFor='purchaseOrder' className={ownStyles.labelFont}>
                Almacén
              </label>
              <input
                type='text'
                className={'register-inputs'}
                readOnly
                value={this.state.addWarehouseEntry?.warehouse_description}
              />
            </div>
            <div className='col p-2'>
              <label htmlFor='purchaseOrder' className={ownStyles.labelFont}>
                No. de Factura o remisión
              </label>
              <input
                type='text'
                className={'register-inputs'}
                readOnly
                value={
                  this.state.addWarehouseEntry?.we_billNumber
                    ? this.state.addWarehouseEntry?.we_billNumber
                    : '-'
                }
              />
            </div>
            <div className='col p-2'>
              <label htmlFor='purchaseOrder' className={ownStyles.labelFont}>
                Fecha
              </label>
              <input
                type='date'
                className={'register-inputs'}
                readOnly
                value={this.state.addWarehouseEntry?.entry_date}
              />
            </div>
          </div>
          <Row className='d-flex'>
            {/* /* -------------------------------- PDF Viwer ------------------------------- */}

            <ModalNew
              title='Detalle de entrada de almacén'
              show={this.state.showPdf}
              btnNoName={'Cancelar'}
              size='700'
              btnYesDisabled={false}
              onHide={() => this.setState({ ...this.state, showPdf: false })}
              btnNoEvent={() => this.setState({ ...this.state, showPdf: false })}
              btnNoDisabled={false}
            >
              <PdfViewer
                downloadable
                file={`data:application/pdf;base64,${this.state.base64}`}
              ></PdfViewer>
            </ModalNew>
            <Col xs={12} className='d-flex justify-content-end mt-2 cursorPointer'>
              <Tooltip title='Imprimir'>
                <img
                  // className={`${detailStyles.titleIcons}`}
                  src={iconPrint}
                  width='25px'
                  alt='imprimir'
                  onClick={() => this.setState({ ...this.state, showPdf: true })}
                />
              </Tooltip>
            </Col>
          </Row>
          {/* ---------------------END INPUTS--------------------- */}

          <GenericTableNew headers={renderHeadersModal} oneHeadDarkBlue>
            {this.state.addWarehouseEntry?.articles?.map((article, ind) => {
              return (
                <tr key={ind}>
                  <td>{article?.id_article}</td>
                  <td>{article.description}</td>
                  <td
                    className='hoverPointer text-primary fw-bold text-decoration-underline'
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        modalLots: true,
                        addArticle: article,
                      })
                    }
                  >
                    {article.lots.length}
                  </td>
                  <td>{article?.qty_ordened}</td>
                  <td>{article?.qty_received}</td>
                </tr>
              );
            })}
          </GenericTableNew>
          {this.state.addWarehouseEntry?.purchase_order_ref?.purchaseOrderItems.length > 10 && (
            <div className='d-flex justify-content-end'>
              {/* <Pagination
                activePage={this.state.paginationModal.page}
                itemsCountPerPage={this.state.paginationModal.perpage}
                totalItemsCount={
                  this.state.addWarehouseEntry?.purchase_order_ref?.purchaseOrderItems ?
                  this.state.addWarehouseEntry?.purchase_order_ref?.purchaseOrderItems.length : 10
                }
                pageRangeDisplayed={10}
                activeClass={paginationStyles.activeClass}
                onChange={this.handlePageChangeModalArticle.bind(this)}
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              /> */}
            </div>
          )}

          {/* -------------------------------------observations */}
          <div className='d-flex'>
            <div className='col'>
              <label htmlFor='observations' className={ownStyles.labelFont}>
                Observaciones
              </label>
              <textarea
                name='observations'
                readOnly
                style={{ border: '1px solid #7FADDE' }}
                value={this.state.addWarehouseEntry?.observations}
                className={ownStyles.textareaObservationsAuth}
              />
            </div>
          </div>
          {/* ------------------------------------- */}

          {/* ------------ REQ AUTH ----------- */}
          <div className='d-flex'>
            <div className={ownStyles.containerObservations}>
              <div className='col'>
                <div className='d-flex my-2'>
                  <img src={Autorizacion} alt='Requiere autorización' width={20} />
                  <h6 className='text-light-blue fw-bold mb-0 mx-1'>Entrada autorizada</h6>
                </div>
                <div className='d-flex justify-content-between'>
                  <div className='col-5'>
                    <label htmlFor='we_problem' className={ownStyles.labelFont}>
                      ¿Cuál era el problema?
                    </label>
                    <ReactSelect
                      isDisabled
                      styles={customSelectNewDark}
                      placeholder={'seleccionar...'}
                      key={'area'}
                      value={this.problemOptions().find(
                        x => x.value === this.state.addWarehouseEntry?.we_problem,
                      )}
                      options={this.problemOptions()}
                    />
                  </div>
                  <div className='col ml-3'>
                    <label htmlFor='we_additional_info' className={ownStyles.labelFont}>
                      Información adicional
                    </label>
                    <input
                      name='we_additional_info'
                      type='text'
                      readOnly
                      style={{ border: '1px solid #7FADDE' }}
                      value={this.state.addWarehouseEntry?.we_additional_info}
                      className={`w-100 ${ownStyles.selectInput} darkGray`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ------------ END AUTH ----------- */}
        </ModalNew>

        {/* modal lots */}

        <ModalNew
          title={'Lotes'}
          show={this.state.modalLots}
          size='440'
          onHide={() => this.setState({ ...this.state, modalLots: false })}
        >
          <GenericTableNew
            headers={[
              <th key={1} className='text-center'>
                Vencimiento
              </th>,
              <th key={2} className='text-center'>
                Lote
              </th>,
              <th key={3} className='text-center'>
                Cantidad
              </th>,
            ]}
          >
            {this.state.addArticle &&
              this.state.addArticle.lots &&
              this.state.addArticle.lots.map((lot, ind) => {
                return (
                  <tr key={ind} className='text-center'>
                    <td>
                      {lot.lot_date_expiration
                        ? convertDateToLatinFormat(lot.lot_date_expiration)
                        : ''}
                    </td>
                    <td>{lot.id}</td>
                    <td>{lot.lot_qty_received}</td>
                  </tr>
                );
              })}
          </GenericTableNew>
        </ModalNew>

        {/* end modal lots */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuth: state.loginReducer.Authenticated,
  agreementReducer: state.agreementReducer,
  currentAccount: state.loginReducer.currentAccount,
  loginReducer: state.loginReducer,
  warehouseEntries: state.entryWarehouseReducer.warehouseEntries,
  wEntry: state.entryWarehouseReducer.wEntry,
  row_total: state.entryWarehouseReducer.row_total,
  problems: state.entryWarehouseReducer.problems,
  listWarehouses: state.storageReducer.list,
  loading: state.entryWarehouseReducer.loading,
});

const mapDispatchToProps = dispatch => ({
  getWarehouseEntries: params => {
    dispatch(getWarehouseEntries(params));
  },
  getWarehouseEntryDetail: (we_id, doAfter) => {
    dispatch(getWarehouseEntryDetail(we_id, doAfter));
  },
  storage_get: params => {
    dispatch(storage_get(params));
  },
  getWEProblems: params => {
    dispatch(getWEProblems(params));
  },
  setLoading: payload => {
    dispatch({
      type: 'LOADING',
      payload: payload,
    });
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(TableWarehouseEntry);
