import Pagination from 'react-js-pagination';
import paginationStyles from '../components/Layouts/pagination.module.scss';

export const TablePagination = ({ activePage = 1, perpage = 10, rowTotal = 0, onChangePage, displayedPages = 5 }) => {
  const maxPages = Math.ceil(rowTotal / perpage) || 1;

  return (
    <div className={paginationStyles.wrapper}>
      <p className={paginationStyles.paginationText}>
        Pág.&nbsp;{activePage}&nbsp;de&nbsp;{maxPages} ({rowTotal} encontrados)
      </p>

      <Pagination
        activePage={activePage}
        itemsCountPerPage={perpage}
        totalItemsCount={rowTotal}
        pageRangeDisplayed={displayedPages}
        onChange={onChangePage}
        itemClassPrev={paginationStyles.itemClassPrev}
        itemClassNext={paginationStyles.itemClassNext}
        itemClassFirst={paginationStyles.itemClassFirst}
        itemClassLast={paginationStyles.itemClassLast}
        itemClass={paginationStyles.itemClass}
      />
    </div>
  )
}
